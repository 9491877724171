import React, { useEffect, useRef, useState } from "react";
import {
  getFacebookLoginStatus,
  initFacebookSdk,
  fbLogin,
} from "./FacebookSDK";
import { useDispatch, useSelector } from "react-redux";
import { postChannel } from "../../store/feature/channels/channelSlice";
import "./home.css";
import HomeHeader from "./HomeHeader";
import FacebookLogin from "./FacebookLogin";
import ActivationPage from "./ActivationPage";
import Spinner from "../../page loader/Spinner";
import Activation3rdstep from "./Activation3rdstep";

const Home = ({ currentUser, hasChannel, channels }) => {
  const fbData = useRef({ phone_number_id: null, waba_id: null });
  const activateLoading = useSelector((state) => state.channel.updateLoading);
  const channelLoading = useSelector((state) => state.channel.channelsLoading);
  const [idToActivate, setIdToActivate] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!channelLoading && channels?.length !== 0 && channels[0]?.status === "pending") {
      setIdToActivate(channels[0].id);
    }
  }, [channels]);

  useEffect(() => {
    if (!channelLoading && idToActivate === null && channels.length === 0) {
      setStep(1);
    }

    if (
      !channelLoading &&
      idToActivate !== null &&
      channels.length !== 0 &&
      channels[0].status === "pending"
    ) {
      setStep(2);
    }

    if (
      !channelLoading &&
      idToActivate === null &&
      channels.length !== 0 &&
      channels[0].status !== "pending"
    ) {
      setStep(0);
    }
  }, [idToActivate, channels,channelLoading]);

  useEffect(() => {
    // Initialize Facebook SDK
    initFacebookSdk()
      .then(() => getFacebookLoginStatus())
      .then((response) => {
        // if (response.status === "connected") {
        //   console.log("User is already logged in:", response);
        // } else {
        //   console.log("User is not logged in:", response);
        // }
      })
      .catch((error) => {
        console.error("Error initializing Facebook SDK:", error);
      });

    // Event listener for messages from Facebook
    const fbSessionInfoListener = (event) => {
      if (event.origin == null || !event.origin.endsWith("facebook.com")) {
        return;
      }
      try {
        const data = JSON.parse(event.data);
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          if (data.event === "FINISH") {
            const { phone_number_id, waba_id } = data.data;
            // console.log(
            //   "Phone number ID:",
            //   phone_number_id,
            //   "WhatsApp business account ID:",
            //   waba_id
            // );

            fbData.current = {
              ...fbData.current,
              phone_number_id: phone_number_id,
              waba_id: waba_id,
            };
          } else if (data.event === "ERROR") {
            const { error_message } = data.data;
            console.error("Error:", error_message);
          } else {
            const { current_step } = data.data;
            console.warn("Cancelled at:", current_step);
          }
        }
      } catch {
        console.log("Non JSON Response:", event.data);
      }
    };

    window.addEventListener("message", fbSessionInfoListener);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("message", fbSessionInfoListener);
    };
  }, []);

  const launchWhatsAppSignup = () => {
    setClicked(true);
    const fbLoginConfig = {
      config_id: "2214588405574986", // configuration ID goes here
      response_type: "code", // must be set to 'code' for System User access token
      override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
      extras: {
        feature: "whatsapp_embedded_signup",
        sessionInfoVersion: 3, // Receive Session Logging Info
      },
    };

    fbLogin(fbLoginConfig)
      .then((response) => {
        if (response.authResponse) {
          const accessToken = response.authResponse.code;
          // console.log("Facebook login successful and auth code: ", accessToken);
          // console.log("whatsapp details: ", JSON.stringify(fbData));
          if (
            fbData.current.phone_number_id !== null &&
            fbData.current.waba_id !== null
          ) {
            const body = {
              platform: "whatsapp",
              config: {
                meta_code: accessToken,
                waba_id: fbData.current.waba_id,
                phone_number_id: fbData.current.phone_number_id,
              },
            };
            // console.log("final request body: ", JSON.stringify(body));
            fbData.current = {
              ...fbData.current,
              phone_number_id: null,
              waba_id: null,
            };
            dispatch(postChannel(body));
          } else {
            console.log(
              "phone_number_id and waba_id is null something wrong with facebook login"
            );
          }
          // // Conversion tracking code
          // if (window.fbq) {
          //   window.fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: '1001056741640607', feature: 'whatsapp_embedded_signup' });
          // }

          // // Launch the embedded WhatsApp signup flow
          // const whatsappSetupUrl = `https://www.facebook.com/business_extension/wa_embed?app_id=1001056741640607&access_token=${accessToken}`;
          // window.open(whatsappSetupUrl, '_blank');
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      })
      .catch((error) => {
        setClicked(false);
        console.error("Error during Facebook login:", error);
      });
  };

  return (
    <div className={`home-page-body${step == 0 ? " step3" : ""}`}>
      {/* {activateLoading && <LoadingScreen position={"fixed"}/>} */}
      <HomeHeader step={step} userType={currentUser?.role_details?.role_name} loading={channelLoading}/>
      <div className="home-main-container">
        {channelLoading && (
          <div className="loading-div">
            {/* <Spinner /> */}
          </div>
        )}
        {!channelLoading &&
          hasChannel === true &&
          channels.length !== 0 &&
          channels[0]?.status !== "pending" && (
            <Activation3rdstep channel={channels[0]}/>
            // <div>
            //   Existing channels:
            //   <br />
            //   {channels.map((channel) => (
            //     <div key={channel.id}>
            //       {channel.label} - {channel.platform}
            //     </div>
            //   ))}
            // </div>
          )}

        {!channelLoading &&
          (hasChannel === false || (hasChannel === true && step !== 0)) &&
          currentUser?.role_details?.role_name === "Administrator" && (
            <>
              {step === 1 && (
                <FacebookLogin
                  clicked={clicked}
                  launchWhatsAppSignup={launchWhatsAppSignup}
                />
              )}
              {step === 2 && (
                <ActivationPage
                  id={channels[0]?.id}
                  setIdToActivate={setIdToActivate}
                />
              )}
            </>
          )}

        {!channelLoading &&
          (hasChannel === false || (hasChannel === true && step !== 0)) &&
          currentUser?.role_details?.role_name !== "Administrator" && (
            <p>
              This account is not configured properly. Please contact your
              administrator.
            </p>
          )}
      </div>
      {activateLoading && <div className="activate-loading">...Loading</div>}
    </div>
  );
};

export default Home;
