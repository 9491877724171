import React from "react";

const LocationRequestChat = ({ content }) => {
  return (
    <div className="location-request-container">
      <button className="request-button" disabled={true}>
        Requesting Location
      </button>
      {content.location_request.caption && (
        <div className="request-caption">
          {content.location_request.caption}
        </div>
      )}
    </div>
  );
};

export default LocationRequestChat;
