import React, { useState, useEffect } from "react";
import Spinner from "../../../page loader/Spinner";
import downloadIcon from "../../../assets/chat assets/download.svg";
import TextChat from "./TextChat";

const ImageChat = ({ content, blob, handlePreview, loading }) => {
  const [imgContent, setImgContent] = useState(null);
  useEffect(() => {
    if (blob) {
      const objectURL = URL.createObjectURL(blob);
      const fileType = blob.type;

      if (fileType.startsWith("image/")) {
        setImgContent(
          <img
            src={objectURL}
            alt="preview"
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        );
      }
    }
  }, [blob]);

  const handleClick = () => {
    if (blob === null) {
      handlePreview(false, null);
    } else {
      handlePreview(true, blob);
    }
  };

  return (
    <>
      <div className="chat-image-container" onClick={() => handleClick()}>
        
        <div className="chat-image-cover-div">
        {!loading && !blob && (
              <div className="image-file-download-icon">
                <img src={downloadIcon} />
              </div>
            )}
          {loading && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Spinner size="30px" />
            </div>
          )}
          {!loading && imgContent}
        </div>
      </div>
    </>
  );
};

export default ImageChat;
