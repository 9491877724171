import React from "react";
import { formatTimestamp } from "../../utils/utils";

const Contact = ({ currentChat, setCurrentChat, contact }) => {
  function handleSelect(e) {
    e.preventDefault();
    setCurrentChat(contact);
  }

  const className =
    currentChat !== undefined &&
    currentChat !== null &&
    currentChat.id === contact.id
      ? "contact selected"
      : "contact";
  const profile_image =
    "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500";
  const { name, mobile } = contact.contact;
  const firstLetter = name?.charAt(0).toUpperCase() || "F";
  const time = formatTimestamp(contact?.modified_time);
  return (
    <div className={className} onClick={handleSelect}>
      <div className="profile">
        <div className="profile-image">
          {/* <img
            src={profile_image}
            alt="profile"
          /> */}

          <div className="profile-image-letter-div">
            <div className="profile-image-letter">{firstLetter}</div>
          </div>
        </div>
        <div className="profile-details">
          <p className="user-name">{name}</p>
          <p className="phone-number">{mobile}</p>
        </div>
      </div>
      <div className="notifications">
        {parseInt(contact.unread_count) !== 0 && (
          <div className="badge">
            <span>{contact.unread_count}</span>
          </div>
        )}
        <div className="time-stamp">
          <p>{time}</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
