import React, { useEffect, useState, useCallback, useMemo } from "react";
import back from "../../assets/chat assets/back_arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchFilesInChat } from "../../store/feature/chat/chatSlice";
import Spinner from "../../page loader/Spinner";
import FilePageChat from "./FilePageChat";

const FilesPage = ({ handleBackToDetails }) => {
  const dispatch = useDispatch();
  const currentChat = useSelector((state) => state.chat.currentChat);
  const filesInChat = useSelector((state) => state.chat.filesInChat);
  const [files, setFiles] = useState([]);
  const { fetchFilesInChatLoading } = useSelector((state) => state.chat);

  const filesInCurrentChat = useMemo(() => filesInChat[currentChat?.id] || [], [filesInChat, currentChat?.id]);

  const handleFilesChatClick = useCallback(() => {
    dispatch(fetchFilesInChat(currentChat.id))
      .unwrap()
      .then(() => {
        setFiles(filesInCurrentChat);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, currentChat.id, filesInCurrentChat]);

  useEffect(() => {
    handleFilesChatClick();
  }, [handleFilesChatClick]);

  const groupedMessages = useMemo(() => {
    return files.reduce((groups, message) => {
      const date = new Date(message.time).toLocaleDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  }, [files]);

  Object.keys(groupedMessages).forEach((date) => {
    groupedMessages[date].sort((a, b) => new Date(b.time) - new Date(a.time));
  });

  const sortedDates = useMemo(() => {
    return Object.keys(groupedMessages).sort((a, b) => {
      const dateA = new Date(a.split("/").reverse().join("-"));
      const dateB = new Date(b.split("/").reverse().join("-"));
      return dateB - dateA;
    });
  }, [groupedMessages]);

  return (
    <div className="files-page">
      <div className="files-header">
        <img
          src={back}
          className="files-back-arrow"
          alt="back"
          onClick={handleBackToDetails}
        />
        <div className="files-header-head">
          <p>Files</p>
        </div>
      </div>
      {fetchFilesInChatLoading ? (
        <div className="files-body">
          <Spinner />
        </div>
      ) : (
        <div className="files-body">
          {sortedDates.length === 0 ? (
            <div className="files-no-message">No Files in Messages</div>
          ) : (
            sortedDates.map((date) => (
              <div key={date} className="file-all-msg-container">
                <div className="file-date">
                  <div className="file-date-span">{date}</div>
                </div>
                {groupedMessages[date].map((message) => (
                  <FilePageChat key={message.id} message={message} />
                ))}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default FilesPage;
