import React from "react";

import editBtn from '../../assets/contacts assets/edit.svg'
import deleteBtn from '../../assets/contacts assets/delete.svg'

const ContactTableRow = ({ data,popupHandle,handleEdit,handleDelete }) => {

  return (
    <tr className={"table-row"} onClick={()=>popupHandle(data.id)}>
      <td className="table-contact-checkbox">
        <div className="contact-name-data">
          <div className="row-contact-checkbox">&nbsp;</div>
        </div>
      </td>
      <td className="table-contact-name-box">
        <div className="contact-name-data">
          <div className="row-contact-name">{data.name}</div>
        </div>
      </td>
      <td>{data.mobile}</td>
      <td className="table-country-name-box">
        <div className="country-name">{data.email}</div>
        <div className={"edit-del-box"}>
          <div
            className="edit-icon"
            onClick={(e) => {
              handleEdit(data,e);
            }}
          >
            <img src={editBtn} alt="" />
          </div>
          <div
            className="contact-table-delete-icon"
            onClick={(e) => {
              handleDelete(data.id,e);
            }}
          >
            <img src={deleteBtn} alt="" />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default ContactTableRow;
