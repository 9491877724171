import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFilteredChats,
  setCurrentChat,
} from "../../store/feature/chat/chatSlice";
import search from "../../assets/contactlist assets/search.svg";
import downArrow from "../../assets/contactlist assets/down_arrow_icon.svg";
import tick from "../../assets/contactlist assets/mdi_tick.svg";

import selectedFilterIcon from "../../assets/contactlist assets/filter3.svg";
import filterUnreadIcon from "../../assets/contactlist assets/filter2.svg";
import filterIcon from "../../assets/contactlist assets/filter1.svg";
import Contact from "./Contact";

const ContactsList = ({ contactzindex, setContactzindex }) => {
  const filterMap = {
    "All Chats": "all_chats",
    "All Open Chats": "all_open_chats",
    "My Chats": "my_chats",
    "My Open Chats": "my_open_chats",
    "All Closed Chats": "all_closed_chats",
  };

  const dispatch = useDispatch();
  const { chatList, currentChat, fetchChatsLoading, views } = useSelector(
    (state) => state.chat
  );
  const [selectedFilter, setSelectedFilter] = useState("All Chats");
  const [dropDown, setDropDown] = useState(false);
  const [clickedFilter, setClickedFilter] = useState(false);
  const [chats, setChats] = useState(chatList || []);
  const [unreadChats, setUnreadChats] = useState([]);

  const arrowStyle = dropDown ? "up" : "down";

  useEffect(() => {
    if (selectedFilter) {
      if (clickedFilter) {
        dispatch(
          fetchFilteredChats({ query: filterMap[selectedFilter], unread: true })
        );
      } else {
        dispatch(
          fetchFilteredChats({
            query: filterMap[selectedFilter],
            unread: false,
          })
        );
      }
    }
  }, [dispatch, selectedFilter, clickedFilter]);

  useEffect(() => {
    const chatsToDisplay =
      views && views.length > 0
        ? chatList.filter((chat) =>
            views.some((filteredChat) => filteredChat.id === chat.id)
          )
        : [];

    setChats(chatsToDisplay);
  }, [views, chatList]);

  useEffect(() => {
    if (chats.length !== 0) {
      const unread = chats.filter((chat) => parseInt(chat.unread_count) !== 0);
      setUnreadChats(unread);
    }
  }, [chats]);

  function handleFilterSelect(filter) {
    setSelectedFilter(filter);
    setDropDown(false);
  }

  function handleFilterToggle() {
    if (unreadChats.length !== 0) {
      setClickedFilter((prev) => !prev);
    }
  }

  const sortedChatList = [...chats].sort(
    (a, b) => new Date(b.modified_time) - new Date(a.modified_time)
  );

  const handleDropDownclose = () => {
    setDropDown(false);
    setContactzindex((prvestate) => ({
      ...prvestate,
      contactdown: false,
    }));
  };

  function handleDropDown(e) {
    e.stopPropagation();
    setDropDown(!dropDown);
    setContactzindex((prvestate) => ({
      ...prvestate,
      contactdown: true,
    }));
  }

  return (
    <div className="contacts-container">
      {dropDown && (
        <div
          className="chats-drop-down-close-div"
          onClick={handleDropDownclose}
        ></div>
      )}

      {fetchChatsLoading && <div className="loading">Loading...</div>}
      <div
        className="head-container"
        onClick={handleDropDownclose}
        style={contactzindex.headerpdown ? { zIndex: 0 } : {}}
      >
        <div className="parent-dd">
          <div className="filter" onClick={(e) => handleDropDown(e)}>
            <div className="text">
              <p>{selectedFilter}</p>
            </div>
            <div className="arrow" id="arrow">
              <img
                src={downArrow}
                alt="arrow"
                id="arrow-img"
                className={arrowStyle}
              />
            </div>
          </div>

          {dropDown && (
            <div className="contact-dropdown-content" id="dropdown-content">
              {[
                "All Chats",
                "All Open Chats",
                "My Chats",
                "My Open Chats",
                "All Closed Chats",
              ].map((filter, index) => (
                <div
                  key={index}
                  className="contact-dropdown-item"
                  onClick={() => handleFilterSelect(filter)}
                >
                  {filter}{" "}
                  {selectedFilter === filter && <img src={tick} alt="tick" />}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="header-nav-options">
          <div className="read-filter" onClick={handleFilterToggle}>
            {clickedFilter && (
              <img
                src={selectedFilterIcon}
                className="filterfillicon"
                alt="filter"
              />
            )}
            {!clickedFilter && unreadChats.length !== 0 && (
              <img
                src={filterUnreadIcon}
                className="filterdoticon"
                alt="filter"
              />
            )}
            {!clickedFilter && unreadChats.length === 0 && (
              <img src={filterIcon} className="filternoneicon" alt="filter" />
            )}
          </div>
          <div className="chatlist-search-box ">
            <img src={search} className="chatlist-search-icon" alt="search" />
          </div>
        </div>
      </div>
      <div className="contacts-list">
        {sortedChatList.length === 0 && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            No chats
          </div>
        )}
        {sortedChatList.map((chatContact) => (
          <Contact
            currentChat={currentChat}
            setCurrentChat={(chat) => dispatch(setCurrentChat(chat))}
            key={chatContact.id}
            contact={chatContact}
          />
        ))}
      </div>
    </div>
  );
};

export default ContactsList;
