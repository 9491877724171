import React, { useEffect } from "react";
import downloadIcon from "../../assets/chat assets/material-symbols_download.svg";
import VideoPlayer from "./chats components/VideoPlayer";

const Preview = ({ blob, closePreview }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closePreview();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [closePreview]);

  const download = () => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "downloaded_file"; // Change this to a dynamic filename if needed
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url); // Clean up
  };

  const getMediaType = () => {
    if (blob.type.startsWith("image/")) return "image";
    if (blob.type.startsWith("video/")) return "video";
    if (blob.type === "application/pdf") return "pdf";
    return "unsupported";
  };

  const mediaType = getMediaType();

  return (
    <div className="chat-preview-container">
      <div onClick={closePreview} className="chat-preview-close-btn">
        &times;
      </div>

      {mediaType === "image" || mediaType === "video" ? (
        <>
          <div className="chat-preview-show-div">
            {mediaType === "image" && (
              <img
                src={URL.createObjectURL(blob)}
                alt="Preview"
                className="chat-preview-img-content"
              />
            )}
            {mediaType === "video" && (
              <VideoPlayer src={URL.createObjectURL(blob)} />
            )}
          </div>
          <div className="chat-preview-download-div">
            <button onClick={download} className="chat-preview-download-btn">
              <img
                src={downloadIcon}
                title="download"
                className="chat-preview-download-btn-img"
              />
            </button>
          </div>
        </>
      ) : mediaType === "pdf" ? (
        <iframe
          src={URL.createObjectURL(blob)}
          title="Preview"
          className="chat-preview-pdf-content"
        />
      ) : (
        <p className="chat-preview-warring-text">
          This file type is not supported for preview.
        </p>
      )}
    </div>
  );
};

export default Preview;
