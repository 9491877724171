import validator from 'validator';

export  function processText(input) {
    let variableCounter = 1;
    const currentVariables = {};
    const variablePattern = /\{\{(.*?)\}\}/g;
    let text = input;
    let match;

    while ((match = variablePattern.exec(input)) !== null) {
        const originalVariable = match[0];
        const placeholderVariable = `variable${variableCounter}`;

        text = text.replace(originalVariable, placeholderVariable);

        currentVariables[placeholderVariable] = originalVariable;

        variableCounter++;
    }

    return {
        text,
        currentVariables
    };
}

export  function revertText(processedText, currentVariables) 
{
    let revertedText = processedText;
    Object.entries(currentVariables).forEach(([key,value])=>
    {
        revertedText = revertedText.replace(key, value);
    })
    return revertedText;
}

export  function extractNumber(variableString) 
{
    const numberPattern = /\d+$/;
    const match = variableString.match(numberPattern);
    const extractedNum= match ? parseInt(match[0], 10) : null;
    return extractedNum
}

export  function getNextChar(arr, number) 
{
    const variablePattern = new RegExp(`^var${number}[a-z]*$`);
    const filteredArr=arr.filter(item => variablePattern.test(item))
    const sortedArr=filteredArr.sort()
    const lastVariable = sortedArr[sortedArr.length - 1];
    
    if(lastVariable)
    {
        const nextChar = lastVariable.charAt(lastVariable.length - 1);
        
        const nextExpectedChar = String.fromCharCode(nextChar.charCodeAt(0) + 1);
        return nextExpectedChar;
    }
    else
    {
        return 'a'
    }
}

export  function autoFillVariable(keys)  
{
    const newVariable=`var2${getNextChar(keys,2)}`
    return newVariable
};
    

export  function extractVariables (text, type,setNewVariable)
{
    const regex = /\{\{(.*?)\}\}/g;
    let matches = [];
    let match;
    let filteredText = text;
    
    if (type === "header") 
    {
        if (text.includes("{{") && !text.includes("}}")) 
        {
            filteredText = text.replace(/\{\{$/, "{{var1}}");
            setNewVariable("{{var1}}")
        }
    
        let tempText = filteredText.replace(regex, "variable");
    
        if (tempText.includes("{{") && !tempText.includes("}}")) 
        {
        tempText = tempText.slice(0, tempText.indexOf("{{"));
        }
    
        let variableMatches = [];
        
        while ((match = regex.exec(filteredText)) !== null) 
        {
        variableMatches.push(match[0]);
        }
    
        let variableIndex = 0;
        filteredText = tempText.replace(/variable/g, () => 
        {
        return variableMatches[variableIndex++] || "";
        });
    
        matches = variableMatches.map(variable => variable.slice(2, -2));
        if(matches.length>1)
        {
        matches.forEach((variable,index)=>
        {
            if(index!==0)
            {
                filteredText=filteredText.replace(`{{${variable}}}`,'')
            }
        })
        }
        return { variables: matches, text: filteredText };
    } 
    else if(type==="button")
    {
        if (text.includes("{{") && !text.includes("}}")) 
        {
            filteredText = text.replace(/\{\{$/, "{{var3}}");
            setNewVariable("{{var3}}")
        }
    
        let tempText = filteredText.replace(regex, "variable");
    
        if (tempText.includes("{{") && !tempText.includes("}}")) 
        {
        tempText = tempText.slice(0, tempText.indexOf("{{"));
        }
    
        let variableMatches = [];
        
        while ((match = regex.exec(filteredText)) !== null) 
        {
        variableMatches.push(match[0]);
        }
    
        let variableIndex = 0;
        filteredText = tempText.replace(/variable/g, () => 
        {
        return variableMatches[variableIndex++] || "";
        });
    
        matches = variableMatches.map(variable => variable.slice(2, -2));
        if(matches.length>1)
        {
        matches.forEach((variable,index)=>
        {
            if(index!==0)
            {
                filteredText=filteredText.replace(`{{${variable}}}`,'')
            }
        })
        }
        return { variables: matches, text: filteredText };
    }
    else if (type === "body") 
    {

        if (text.includes("{{}}")) 
        {
            filteredText = text.replace("{{}}","");
        }

        let obj=processText(filteredText)
        let tempText=obj.text
        const currentVariables=obj.currentVariables
        const totalVariables=Object.entries(currentVariables).length

        // Step 2: Check for incomplete variables and slice them from text
        if (tempText.includes("{{") && tempText.includes("}")) 
        {
            tempText = tempText.slice(0, tempText.indexOf("{{"));
        }

        if (totalVariables>=10 && tempText.includes("{{") && !tempText.includes("}}")) 
        {
            tempText = tempText.slice(0, tempText.indexOf("{{"));
        }

        // Step 3: Replace all occurrences of incomplete brackets with sequential variables
        const incompleteRegex = /\{\{/g;
        while (incompleteRegex.test(tempText)) 
        {
        const keys=Object.keys(currentVariables)
        const values=Object.values(currentVariables).map(item=>item.slice(2,-2))
        const newVariable=autoFillVariable(values)
        const lastKey=keys.length!==0 ? extractNumber(keys[keys.length-1]) : 0
        currentVariables[`variable${lastKey+1}`]=`{{${newVariable}}}`
        tempText = tempText.replace("{{", `variable${lastKey+1}`);
        setNewVariable(`{{${newVariable}}}`)
        
        }
        
        filteredText=revertText(tempText,currentVariables)

        Object.entries(currentVariables).forEach(([key, value]) => 
        {
        matches.push(value.slice(2,-2));
        });
        
        return { variables: matches, text: filteredText };
    }
    
    while ((match = regex.exec(text)) !== null) 
    {
        matches.push(match[1]);
    }
    
    return { variables: matches, text };
};

export function replaceVariables(type, text, params) 
{
    let newText = text;
    if (params.length !== 0) {
        params.forEach(param => {
            const regex = new RegExp(`\\{\\{${param.name}\\}\\}`, 'g');
            newText = newText.replace(regex, param.example);
        });
        return newText;
    }
    else
    {
        return newText;
    }
}


export function validateName(name)
{
    const regex = /^[a-z0-9_]+$/;
    return regex.test(name);
};

export function validateVariable(variable)
{
    const regex=/^[a-zA-Z0-9][a-zA-Z0-9_]*$/;
    return regex.test(variable)

}


export function hasDuplicates(array) {
    const seen = new Set();
    for (const item of array) {
        if (seen.has(item)) {
            return true;
        }
        seen.add(item);
    }
    return false; 
}


export function isValidVariables(variables)
{
    let isAllValid=true
    variables.forEach(variable=>
    {
        if(!validateVariable(variable))
        {
            isAllValid=false
        }
    })

    return isAllValid
}

export const validateCopyCode = (code) => /^[a-zA-Z0-9]+$/.test(code);

export const validatePhoneNumber = (phone) => /^\+?[0-9\s\-()]+$/.test(phone);

export  const formatFileSize = (size) => {
    if (size < 1024) return `${size} B`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
    return `${(size / (1024 * 1024)).toFixed(1)} MB`;
  };

export const isFormValid = (formState,setError,file) => {
    const { name, content } = formState;
    const { header, body, footer, buttons } = content;
  
    let inputError = null;
  
    // Check name
    if (!name.trim()) {
      inputError = { nameError: "Please fill this field." };
      setError(prev => ({ ...prev, ...inputError }));
      return false;
    }
  
    // Check header text if format is text
    if (header.format === "text" && header.text!==undefined && !header.text.trim()) {
      inputError = { headerError: "Please fill this field." };
      setError(prev => ({ ...prev, ...inputError }));
      return false;
    }
  
    // Check body text
    if (body.text!==undefined && !body.text.trim()) {
      inputError = { bodyError: "Please fill this field." };
      setError(prev => ({ ...prev, ...inputError }));
      return false;
    }
  
    // Check footer text
    if (footer.text!==undefined && !footer.text.trim()) {
      inputError = { footerError: "Please fill this field." };
      setError(prev => ({ ...prev, ...inputError }));
      return false;
    }

  
    // Check header params
    if (header.params?.length > 0 && header.params?.some(param => !param.example.trim())) {
      inputError = { headerParamError: "Please fill this parameter field." };
      setError(prev => ({ ...prev, ...inputError }));
      return false;
    }
  
    // Check body params
    if (body.params?.length > 0) {
      const missingParamIndex = body.params.findIndex(param => !param.example.trim());
      if (missingParamIndex !== -1) {
        inputError = { error: "Please fill parameter field.", missingField: missingParamIndex };
        setError(prev => ({ ...prev, bodyParamError:inputError }));
        return false;
      }
    }
  
    // Check buttons
    if (buttons?.length > 0) {
      const missingLabelIndex = buttons.findIndex(button => (button.text!==undefined && !button.text.trim()));

      const missingValueIndex = buttons.findIndex((button, index) => {
        if (button.type === "phone_number" || button.type === "url") {
          return !button[button.type]?.trim();
        } else if (button.type === "copy_code") {
          return !button.example_code.trim();
        }
        return false;
      });

      const missingParamIndex=buttons.findIndex((button,index)=>
        {
            if(button.type==="url" && button.params && button.params.length!==0)
            {
                return !button.params[0]?.example.trim();
            }
            return false
        })

    const invalidParamIndex=buttons.findIndex((button,index)=>
        {
            if(button.type==="url" && button.params && button.params.length!==0)
            {
                return !validateName(button.params[0]?.example);
            }
            return false
        })

    const invalidUrlIndex=buttons.findIndex(button=>
    {
        if(button.type==="url" && button.actual_text?.trim())
        {
            return !validator.isURL(button.actual_text)
        }
        return false
    })

    const invalidCopyCodeIndex=buttons.findIndex(button=>
    {
        if(button.type==="copy_code")
        {
            return !validateCopyCode(button.example_code)
        }
        return false
    })

    const invalidPhoneNumber=buttons.findIndex(button=>
    {
        if(button.type==="phone_number")
        {
            return !validatePhoneNumber(button[button.type])
        }
        return false
    })

      if (missingLabelIndex !== -1) {
        inputError = { error: "Please fill labels.", missingLabel: missingLabelIndex };
        setError(prev => ({ ...prev, buttonLabelError:inputError }));
        return false;
      }
      else if (missingValueIndex !== -1) {
        inputError = { error: "Please fill this field.", missingField: missingValueIndex };
        setError(prev => ({ ...prev, buttonValueError:inputError }));
        return false;
      }
      else
      {
        if(missingParamIndex!==-1)
        {
            inputError = { error: "Please fill this field.", missingField: missingParamIndex };
            setError(prev => ({ ...prev, buttonParamError:inputError }));
            return false;
        }
        
        if(invalidParamIndex!==-1)
        {
            inputError = { error: "Enter valid parameter.", missingField: invalidParamIndex };
            setError(prev => ({ ...prev, buttonParamError:inputError }));
            return false;
        }

        if(invalidUrlIndex!==-1)
        {
            inputError = { error: "Enter valid url.", fieldIndex: invalidUrlIndex };
            setError(prev => ({ ...prev, buttonUrlError:inputError }));
            return false;
        }

        if(invalidCopyCodeIndex!==-1)
        {
            inputError = { error: "Enter valid copy code.", missingField: invalidCopyCodeIndex };
            setError(prev => ({ ...prev, buttonValueError:inputError }));
            return false;
        }

        if(invalidPhoneNumber!==-1)
        {
            inputError = { error: "Enter valid Phone number.", missingField: invalidPhoneNumber };
            setError(prev => ({ ...prev, buttonValueError:inputError }));
            return false;
        }
      }
      
    }

    // check for files
    if(header.format!=="text" && !file)
    {
        setError(prev=>({...prev,fileUploadError:"File is mandatory."}))
        return false
    }

    // No errors found
    return inputError===null;
  };

  function generateUniqueId() {
    const timestamp = Date.now().toString(); // Get current timestamp in milliseconds
    const randomDigits = Math.floor(Math.random() * 1e10).toString().padStart(7, '0'); // Get a random number and ensure it has 7 digits
    return timestamp + randomDigits; // Combine timestamp and random digits
  }

  export function createTemplateRequestBody(templateObj,file) {
    const reqBody = {};
    reqBody.name = templateObj.name;
    reqBody.language = templateObj.language;
    reqBody.category = templateObj.category;
    reqBody.purpose=templateObj.purpose
    reqBody.content = {};
    reqBody.content.header=
    {
        format: templateObj.content.header.format,
        text:"sample text for header."
    }
  
    if (templateObj.content.header.format === "text") 
    {
        reqBody.content.header.text= templateObj.content.header.text;
        reqBody.content.header.params= [];
  
      if(templateObj.content.header.params && templateObj.content.header.params.length!==0)
      {
        templateObj.content.header.params.forEach(param=>
        {
          const paramObj=
          {
            name:param.name,
            example:param.example
          }
          if(param.field)
          {
            paramObj.field=param.field
          }
  
          reqBody.content.header.params.push(paramObj)
        })
      }
      else
      {
        delete reqBody.content.header.params
      }
    }
  
    reqBody.content.body = {
      text: templateObj.content.body.text,
      params: [],
    };
  
    if(templateObj.content.body.params  && templateObj.content.body.params.length!==0)
    {
      templateObj.content.body.params.forEach(param=>
      {
        const paramObj=
        {
          name:param.name,
          example:param.example
        }
        if(param.field)
        {
          paramObj.field=param.field
        }
  
        reqBody.content.body.params.push(paramObj)
      })
    }
    else
    {
      delete reqBody.content.body.params
    }
  
    reqBody.content.footer = {
      text: templateObj.content.footer.text,
    };
  
    if (templateObj.content.buttons && templateObj.content.buttons?.length !== 0) {
      reqBody.content.buttons = [];
  
      templateObj.content.buttons.forEach((button) => {
        if (button.type === "url") {
          const buttonObj = {
            type: button.type,
            text: button.text,
            [button.type]: button[button.type],
          };
  
          if(button.params && button.params.length!==0)
            {
              buttonObj.params=[]
              button.params.forEach(param=>
              {
                const paramObj=
                {
                  name:param.name,
                  example:param.example
                }
                if(param.field)
                {
                  paramObj.field=param.field
                }
          
                buttonObj.params.push(paramObj)
              })
            }
          reqBody.content.buttons.push(buttonObj);
        } else if (button.type === "phone_number") {
          const buttonObj = {
            type: button.type,
            text: button.text,
            [button.type]: button[button.type],
          };
          reqBody.content.buttons.push(buttonObj);
        } else if (button.type === "copy_code") {
          const buttonObj = {
            type: button.type,
            text:button.text,
            example_code: button.example_code,
          };
          reqBody.content.buttons.push(buttonObj);
        } else {
          const buttonObj = {
            type: button.type,
            text: button.text,
          };
          reqBody.content.buttons.push(buttonObj);
        }
      });
    }

    const body=
    {
        headerType:templateObj.content?.header?.format,
        reqBody:reqBody,
    }

    if(body.headerType!=="text" && file!==null)
    {
        const formData=new FormData()
        formData.append("file",file)

        body.formData=formData
    }
    
    return body;
  }

  function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  
  export function templateBodyToFormState(templateBody) {
    const newTemplateBody = deepClone(templateBody);
  
    if (newTemplateBody.content.header && newTemplateBody.content.header.format === "text") 
    {
      if(newTemplateBody.content.header.params)
      {
        newTemplateBody.content.header.params=[]
      }
      newTemplateBody.content.header.actual_text = replaceVariables("header", newTemplateBody.content.header.text, newTemplateBody.content.header.params || []);
    }
  
    if (newTemplateBody.content.body) 
    {
      if(newTemplateBody.content.body.params)
      {
        newTemplateBody.content.body.params=[]
      }
      newTemplateBody.content.body.actual_text = replaceVariables("body", newTemplateBody.content.body.text, newTemplateBody.content.body.params || []);
    }

    if(!newTemplateBody.content.buttons)
    {
      newTemplateBody.content.buttons=[]
    }
  
    return newTemplateBody;
  }
  
  