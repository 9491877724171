import React from 'react';
import pdfIcon from '../../assets/chat assets/file_pdf.svg';
import txtIcon from '../../assets/chat assets/file_text.svg';
import xlsIcon from '../../assets/chat assets/file_xls.svg';
import xlsxIcon from '../../assets/chat assets/file_xlsx.svg';
import docxIcon from '../../assets/chat assets/file_docx.svg';
import docIcon from '../../assets/chat assets/file_doc.svg';
import pptIcon from '../../assets/chat assets/file_ppt.svg';
import pptxIcon from '../../assets/chat assets/file_pptx.svg';
import cancel from "../../assets/chat assets/material-symbols_close.svg";

const mimeTypeToIcon = {
  'application/pdf': pdfIcon,
  'application/vnd.ms-excel': xlsIcon,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': xlsxIcon,
  'application/msword': docIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': docxIcon,
  'application/vnd.ms-powerpoint': pptIcon,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': pptxIcon,
  'text/plain': txtIcon
};

const Media = ({ file, onRemove }) => {

  const getIcon = (type, name) => {
    const extension = name ? name.split('.').pop().toLowerCase() : "";
    
    // Check if the type is in the mimeTypeToIcon mapping
    if (mimeTypeToIcon[type]) {
      return mimeTypeToIcon[type];
    }
  
    // Check if the extension is in the mimeTypeToIcon mapping
    if (extension === 'pdf') {
      return mimeTypeToIcon['application/pdf'];
    } else if (extension === 'xls' || extension === 'xlsx') {
      return mimeTypeToIcon['application/vnd.ms-excel'];
    } else if (extension === 'doc' || extension === 'docx') {
      return mimeTypeToIcon['application/msword'];
    } else if (extension === 'ppt' || extension === 'pptx') {
      return mimeTypeToIcon['application/vnd.ms-powerpoint'];
    } else if (extension === 'txt') {
      return mimeTypeToIcon['text/plain'];
    } else if (extension === 'jpeg' || extension === 'jpg') {
      return mimeTypeToIcon['image/jpeg'];
    } else if (extension === 'png') {
      return mimeTypeToIcon['image/png'];
    }
  
    // Default icon for unsupported types
    return pdfIcon; 
  };
  

  const renderPreview = (file) => {
    if (!file) return null;

    const fileURL = URL.createObjectURL(file);
    const fileIcon = getIcon(file.type, file.name);

    if (file.type.startsWith('image/')) {
      return <img src={fileURL} alt="preview" className="preview" />;
    } else if (file.type.startsWith('video/')) {
      return <video src={fileURL} className="preview" />;
    } else {
      const fileName = file.name ? file.name.substring(0, 10) + (file.name.length > 10 ? "..." : "") : "untitled";
      const fileExtension =file.name ? file.name.split('.').pop().toLowerCase() : "";
      const displayName = `${fileName}.${fileExtension}`;

      return (
        <div className="preview">
          {fileIcon && <img src={fileIcon} width="20px" height="20px" alt={file.name} />}
          <p>{displayName}</p>
        </div>
      );
    }
  };

  return (
    <div className="media-container">
      <div className="uploaded-media">
        <div className="remove-button" onClick={onRemove}>
         <img src={cancel} className='icon'/>
        </div>
        <div className="preview-container">
          {renderPreview(file)}
        </div>
      </div>
    </div>
  );
};

export default Media;
