import React, { useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import pdfIcon from "../../../assets/chat assets/file_pdf.svg";
import txtIcon from "../../../assets/chat assets/file_text.svg";
import xlsIcon from "../../../assets/chat assets/file_xls.svg";
import xlsxIcon from "../../../assets/chat assets/file_xlsx.svg";
import docxIcon from "../../../assets/chat assets/file_docx.svg";
import docIcon from "../../../assets/chat assets/file_doc.svg";
import pptIcon from "../../../assets/chat assets/file_ppt.svg";
import pptxIcon from "../../../assets/chat assets/file_pptx.svg";
import jpgIcon from "../../../assets/chat assets/file_jpg.svg";
import Spinner from "../../../page loader/Spinner";
import downloadIcon from "../../../assets/chat assets/download.svg";
import TextChat from "./TextChat";
import "pdfjs-dist/build/pdf.worker.mjs";

const mimeTypeToIcon = {
  "application/pdf": pdfIcon,
  "application/vnd.ms-excel": xlsIcon,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": xlsxIcon,
  "application/msword": docIcon,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    docxIcon,
  "application/vnd.ms-powerpoint": pptIcon,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    pptxIcon,
  "text/plain": txtIcon,
  "image/jpeg": jpgIcon,
};

const extensionToMimeType = {
  pdf: "application/pdf",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  txt: "text/plain",
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
};

const FileChat = ({ content, blob, handlePreview, loading }) => {
  const [thumbnail, setThumbnail] = useState(null);
  const { file } = content;

  let icon;

  const getFileExtension = (filename) => {
    if (!filename) {
      return "";
    }
    const parts = filename.split(".");
    return parts.length > 1 ? parts.pop().toLowerCase() : "";
  };

  if (file.mime_type) {
    icon = mimeTypeToIcon[file.mime_type];
  } else if (getFileExtension(file.name) !== "") {
    const extension = getFileExtension(file.name);
    const mimeType = extensionToMimeType[extension];
    icon = mimeTypeToIcon[mimeType];
  } else {
    icon = null;
  }

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} B`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
    return `${(size / (1024 * 1024)).toFixed(1)} MB`;
  };

  useEffect(() => {
    const generateThumbnail = () => {
      if (blob && file.mime_type === "application/pdf") {
        const loadingTask = pdfjs.getDocument(URL.createObjectURL(blob));

        loadingTask.promise
          .then((pdf) => {
            pdf.getPage(1).then((page) => {
              const viewport = page.getViewport({ scale: 1 });
              const canvas = document.createElement("canvas");
              const context = canvas.getContext("2d");
              canvas.height = viewport.height / 2;
              canvas.width = viewport.width;

              const renderContext = {
                canvasContext: context,
                viewport: viewport,
              };

              page.render(renderContext).promise.then(() => {
                setThumbnail(canvas.toDataURL());
              });
            });
          })
          .catch((error) => {
            console.error("Error generating thumbnail:", error);
          });
      }
    };

    generateThumbnail();
  }, [blob, file.mime_type]);

  return (
    <>
      <div
        className="file-chat-container"
        onClick={() => handlePreview(true, null)}
      >
          
        {thumbnail && (
          <img
            src={thumbnail}
            className="pdf-thumbnail"
            onClick={() => handlePreview(true, null)}
            alt="PDF Thumbnail"
          />
        )}
        <div className="files-chat-container">
          {icon !== null && (
            <div className="file-icon-box">
              <img className="file-icon" src={icon} alt={file.mime_type} />
            </div>
          )}

          <div className="file-content-box">
            <div className="file-content-details">
              <p className="file-content-title">{file.name || "Untitled"}</p>
              {file.size && (
                <p className="file-content-size">{formatFileSize(file.size)}</p>
              )}
            </div>
            {loading && (
              <div className="file-content-symbol">
                <Spinner size="20px" />
              </div>
            )}
              {!loading && !blob && (
              <div className="file-download-icon">
                <img src={downloadIcon} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FileChat;
