// import { useState, useEffect, useRef } from "react";
// import rightArrow from "../../assets/icons/details-left-arrow.svg";
// import StarredMessagePage from "./StarredMessagePage";
// import FilesPage from "./FilesPage";
// import { useDispatch, useSelector } from "react-redux";
// import { updateAssignee, updateChatStatus } from "../../store/feature/chat/chatSlice";
// import editpencil from "../../assets/icons/editpencil.svg";

// const ChatDetails = ({ setHighLightedMessage }) => {
//   const [state, setState] = useState({
//     chatUserInfo: false,
//     starredMessagePage: false,
//     filesPage: false,
//     selectedAssignee: null,
//     status:"open",
//     assigneeDropDown: false,
//     statusDropDown:false
//   });
//   const assigneeDropDownRef = useRef(null);
//   const statusDropDownRef=useRef(null)
//   const {currentChat,chatList,updateChatStatusLoading,updateAssigneeLoading} = useSelector((state) => state.chat);
//   const chatData=chatList.find(item=>item.id===currentChat.id)
//   const users = useSelector((state) => state.currentuser.users);
//   const currentUserInfo = useSelector((state) => state.currentuser.currentUser);
//   const currentUser = currentUserInfo?.role_details.role_name;
//   const currentUserId = currentUserInfo.user_id;
//   const assigneeInfo = users.find(
//     (user) => user.user_id === currentChat.owner_id
//   );
//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (assigneeInfo) {
//       setState((prevState) => ({
//         ...prevState,
//         selectedAssignee: assigneeInfo.first_name,
//       }));
//     } else {
//       setState((prevState) => ({ ...prevState, selectedAssignee: null }));
//     }

//   }, [assigneeInfo, currentChat]);

//   useEffect(()=>
//   {
//     if(chatData && chatData.state)
//     {
//       setState(prev=>({...prev,status:chatData.state}))
//     }
//   },[chatData])

//   const toggleDropdown = (type) => {
//     if(type==="assignee")
//     {
//       setState((prevState) => ({
//         ...prevState,
//         assigneeDropDown: !prevState.assigneeDropDown,
//       }));
//     }

//     if(type==="status")
//     {
//       setState((prevState) => ({
//         ...prevState,
//         statusDropDown: !prevState.statusDropDown,
//       }));
//     }
//   };

//   const handleAssigneeClick = (user) => {
//     setState((prevState) => ({
//       ...prevState,
//       // selectedAssignee: user.first_name,
//       assigneeDropDown: false,
//     }));
//     dispatch(
//       updateAssignee({ chatId: currentChat.id, assigneeId: user.user_id })
//     );
//   };

//   const handlestatusClick = (status) => {
//     setState((prevState) => ({
//       ...prevState,
//       statusDropDown: false,
//     }));
//     dispatch(
//       updateChatStatus({ chatId: currentChat.id, status: status })
//     );
//   };

//   const handleAssignButtonClick = () => {
//     setState((prevState) => ({ ...prevState, assigneeDropDown: true }));
//   };

//   const handleStarredMessagesClick = () => {
//     setState((prevState) => ({ ...prevState, starredMessagePage: true }));
//   };

//   const handleFilesClick = () => {
//     setState((prevState) => ({ ...prevState, filesPage: true }));
//   };

//   const handleBackToDetails = () => {
//     setState((prevState) => ({
//       ...prevState,
//       chatUserInfo: false,
//       starredMessagePage: false,
//       filesPage: false,
//       assigneeDropDown: false,
//     }));
//   };

//   if (state.starredMessagePage) {
//     return (
//       <div className="chat-details">
//         <StarredMessagePage
//           handleBackToDetails={handleBackToDetails}
//           setHighLightedMessage={setHighLightedMessage}
//         />
//       </div>
//     );
//   }

//   if (state.filesPage) {
//     return (
//       <div className="chat-details">
//         <FilesPage
//           handleBackToDetails={handleBackToDetails}
//           setHighLightedMessage={setHighLightedMessage}
//         />
//       </div>
//     );
//   }

//   return (
//     <div className="chat-details">
//       <div className="details-header">
//         <div className="details-header-head">
//           <p>Chat Details</p>
//         </div>

//         <div className="header-details">
//           {/* New code add by tamil */}
//           <div className="assignee-container">
//             <div className="assignee-body">
//               <div className="assigne-heading">Assignee</div>
//               <div className="assigne-name-box">
//                 <div className="assigne-name">{state.selectedAssignee || "unassigned"}</div>
//                 {(currentUser === "Administrator" || currentChat.owner_id === currentUserId) && !updateAssigneeLoading &&
//                   <div className="assigne-name-edit">
//                     <img src={editpencil} alt="edit" onClick={()=>toggleDropdown("assignee")}/>
//                   </div>
//                 }
//               </div>
//             </div>
//             {state.assigneeDropDown && (
//                   <div className="assignee-dropdown-menu" ref={assigneeDropDownRef}>
//                     {users.map((user, index) => (
//                       <div
//                         key={index}
//                         className={`assignee-dropdown-item ${
//                           state.selectedAssignee === user.first_name
//                             ? "selected-drop-down"
//                             : ""
//                         }`}
//                         onClick={() => handleAssigneeClick(user)}
//                       >
//                         {user.first_name}
//                       </div>
//                     ))}
//                   </div>
//             )}
//           </div>
//           <div className="status-container">
//               <div className="status-body">
//                 <div className="status-heading">Status</div>
//                 <div className="status-name-box">
//                   <div className="status-name">{state.status}</div>
//                   {(currentUser === "Administrator" || currentChat.owner_id === currentUserId) && !updateChatStatusLoading &&
//                     <div className="status-name-edit">
//                       <img src={editpencil} alt="edit" onClick={()=>toggleDropdown("status")}/>
//                     </div>
//                   }
//                 </div>
//               </div>
//               {state.statusDropDown && (
//                       <div className="dropdown-menu" ref={statusDropDownRef}>
//                         {["open","closed"].map((user, index) => (
//                           <div
//                             key={index}
//                             className={`status-dropdown-item ${
//                               state.status === user
//                                 ? "selected-drop-down"
//                                 : ""
//                             }`}
//                             onClick={() => handlestatusClick(user)}
//                           >
//                             {user}
//                           </div>
//                         ))}
//                       </div>
//               )}
//           </div>

//         </div>
//       </div>

//       <div className="details-body">
//         <div
//           className="starred-messages details-drop-down"
//           onClick={handleStarredMessagesClick}
//         >
//           <p>Starred Messages</p>
//           <img src={rightArrow} className="right-arrow" alt="right arrow" />
//         </div>

//         <div className="files details-drop-down" onClick={handleFilesClick}>
//           <p>Files</p>
//           <img src={rightArrow} className="right-arrow" alt="right arrow" />
//         </div>

//         <div
//           className="contact-details"
//           onClick={(e) => {
//             e.preventDefault();
//             setState((prevState) => ({
//               ...prevState,
//               chatUserInfo: !prevState.chatUserInfo,
//             }));
//           }}
//         >
//           <div className="contact-details-sub-box">
//             <p>Contact Details</p>

//             <img
//               src={rightArrow}
//               className={state.chatUserInfo ? "top-arrow" : "down-arrow"}
//               alt="right arrow"
//             />
//           </div>

//           {state.chatUserInfo && (
//             <div className="contact-details-main-box">
//               <div className="detail-content-box detail-name-box">
//                 <div className="detail-content-title detail-name-title">
//                   Name
//                 </div>
//                 <div className="detail-content detail-name-content">
//                   {currentChat.contact.name}
//                 </div>
//               </div>

//               <div className="detail-content-box detail-company-box">
//                 <div className="detail-content-title detail-company-title">
//                   Company
//                 </div>
//                 <div className="detail-content detail-company-content">
//                   Chakraa Universal Tech Labs
//                 </div>
//               </div>

//               <div className="detail-content-box detail-email-box">
//                 <div className="detail-content-title detail-email-title">
//                   Email ID
//                 </div>
//                 <div className="detail-content detail-email-content">
//                   krishnavijay92@gmail.com
//                 </div>
//               </div>

//               <div className="detail-content-box detail-number-box">
//                 <div className="detail-content-title detail-number-title">
//                   Number
//                 </div>
//                 <div className="detail-content detail-number-content">
//                   919500402305
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChatDetails;

import { useState, useEffect, useRef } from "react";
import rightArrow from "../../assets/icons/details-left-arrow.svg";
import StarredMessagePage from "./StarredMessagePage";
import FilesPage from "./FilesPage";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAssignee,
  updateChatStatus,
} from "../../store/feature/chat/chatSlice";
import editpencil from "../../assets/icons/editpencil.svg";
import dropdownicon from "../../assets/icons/icon.svg";
import dropdowncancelicon from "../../assets/icons/chatdetailsdd-cancel.svg";
import dropdowntediticon from "../../assets/icons/chatdetailsddok.svg";

const ChatDetails = ({ setHighLightedMessage }) => {
  const [state, setState] = useState({
    chatUserInfo: false,
    starredMessagePage: false,
    filesPage: false,
    selectedAssignee: null,
    dummyselectedAssignee: null,
    status: "open",
    dummystatus: "open",
    assigneeDropDown: false,
    statusDropDown: false,
    assigneeDropDownItem: false,
    statusDropDownitem: false,
  });
  const assigneeDropDownRef = useRef(null);
  const statusDropDownRef = useRef(null);
  const { currentChat, chatList } = useSelector((state) => state.chat);
  const chatData = chatList.find((item) => item.id === currentChat.id);
  const userList = useSelector((state) => state.currentuser.users);
  const [users, setUsers] = useState([]);
  const currentUserInfo = useSelector((state) => state.currentuser.currentUser);
  const currentUser = currentUserInfo?.role_details.role_name;
  const currentUserId = currentUserInfo.user_id;
  const assigneeInfo = users.find(
    (user) => user.user_id === currentChat.owner_id
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (assigneeInfo) {
      setState((prevState) => ({
        ...prevState,
        selectedAssignee: assigneeInfo,
        dummyselectedAssignee: assigneeInfo,
      }));
    } else {
      setState((prevState) => ({ ...prevState, selectedAssignee: null }));
    }
  }, [assigneeInfo, currentChat]);

  useEffect(() => {
    if (chatData && chatData.state) {
      setState((prev) => ({ ...prev, status: chatData.state }));
    }
  }, [chatData]);

  useEffect(() => {
    if (userList) {
      const newUsers = [{ first_name: "None", user_id: null }, ...userList];
      setUsers(newUsers);
    }
  }, [userList]);

  const viewassignedropdown = () => {
    setState((prevState) => ({
      ...prevState,
      assigneeDropDown: !prevState.assigneeDropDown,
      assigneeDropDownItem: false,
      dummyselectedAssignee: prevState.selectedAssignee,
    }));
  };

  const showassigneeDropDownItemfn = () => {
    setState((prevState) => ({
      ...prevState,
      assigneeDropDownItem: !prevState.assigneeDropDownItem,
      statusDropDownitem: false,
    }));
  };

  const handleAssigneeSelection = (assignee) => {
    setState((prevState) => ({
      ...prevState,
      assigneeDropDownItem: false,
      dummyselectedAssignee: assignee,
    }));
  };

  const handleAssigneeconformselection = () => {
    setState((prevState) => ({
      ...prevState,
      assigneeDropDown: false,
    }));

    setState((prevState) => {
      if (
        prevState.selectedAssignee.user_id !==
        prevState.dummyselectedAssignee.user_id
      ) {
        setState((newState) => ({
          ...newState,
          selectedAssignee: newState.dummyselectedAssignee,
        }));
        dispatch(
          updateAssignee({
            chatId: currentChat.id,
            assigneeId: prevState.dummyselectedAssignee.user_id,
          })
        )
          .unwrap()
          .then((res) => {
            setState((newState) => ({
              ...newState,
              selectedAssignee: newState.dummyselectedAssignee,
            }));
          })
          .catch((err) => {
            console.log(err);
          });
      }

      return prevState;
    });
  };

  const viewstatusdropdown = () => {
    setState((prevState) => ({
      ...prevState,
      statusDropDown: !prevState.statusDropDown,
      statusDropDownitem: false,
      dummystatus: prevState.status,
    }));
  };

  const showsatusDropDownItemfn = () => {
    setState((prevState) => ({
      ...prevState,
      statusDropDownitem: !prevState.statusDropDownitem,
      assigneeDropDownItem: false,
    }));
  };

  const handlestatusconformselection = () => {
    setState((prevState) => ({
      ...prevState,
      statusDropDown: false,
    }));

    setState((prevState) => {
      if (prevState.status !== prevState.dummystatus) {
        setState((newState) => ({
          ...newState,
          status: newState.dummystatus,
        }));

        dispatch(
          updateChatStatus({
            chatId: currentChat.id,
            status: prevState.dummystatus,
          })
        )
          .then((res) => {
            setState((newState) => ({
              ...newState,
              status: newState.dummystatus,
            }));
          })
          .catch((err) => {
            console.log(err);
          });
      }

      return prevState;
    });
  };

  const handleAssigneeClick = () => {
    dispatch(
      updateAssignee({
        chatId: currentChat.id,
        assigneeId: state.selectedAssignee.user_id,
      })
    );
  };

  const handlestatusClick = (status) => {
    setState((prevState) => ({
      ...prevState,
      dummystatus: status,
      statusDropDownitem: false,
    }));
  };

  const handleAssignButtonClick = () => {
    setState((prevState) => ({ ...prevState, assigneeDropDown: true }));
  };

  const handleStarredMessagesClick = () => {
    setState((prevState) => ({ ...prevState, starredMessagePage: true }));
  };

  const handleFilesClick = () => {
    setState((prevState) => ({ ...prevState, filesPage: true }));
  };

  const handleBackToDetails = () => {
    setState((prevState) => ({
      ...prevState,
      chatUserInfo: false,
      starredMessagePage: false,
      filesPage: false,
      assigneeDropDown: false,
      statusDropDown: false,
    }));
  };

  if (state.starredMessagePage) {
    return (
      <div className="chat-details">
        <StarredMessagePage
          handleBackToDetails={handleBackToDetails}
          setHighLightedMessage={setHighLightedMessage}
        />
      </div>
    );
  }

  if (state.filesPage) {
    return (
      <div className="chat-details">
        <FilesPage
          handleBackToDetails={handleBackToDetails}
          setHighLightedMessage={setHighLightedMessage}
        />
      </div>
    );
  }

  return (
    <div className="chat-details">
      {state.assigneeDropDownItem && (
        <div
          className="chatassignee-drop-down-close-div"
          onClick={showassigneeDropDownItemfn}
        ></div>
      )}
      {state.statusDropDownitem && (
        <div
          className="chatstatus-drop-down-close-div"
          onClick={showsatusDropDownItemfn}
        ></div>
      )}

      <div className="details-header">
        <div className="details-header-head">
          <p>Chat Details</p>
        </div>

        <div className="header-details">
          {/* New code add by tamil */}

          <div className="assignee-container">
            <div className="assignee-body">
              <div className="assigne-heading">Assignee</div>

              {!state.assigneeDropDown ? (
                <div className="assigne-name-box">
                  <div className="assigne-name">
                    {state.selectedAssignee?.first_name
                      ? state.selectedAssignee?.first_name
                          .charAt(0)
                          .toUpperCase() +
                        state.selectedAssignee?.first_name.slice(1)
                      : "Unassigned"}
                  </div>
                  {(currentUser === "Administrator" ||
                    currentChat.owner_id === currentUserId) && (
                    <div className="assigne-name-edit">
                      <img
                        src={editpencil}
                        alt="edit"
                        className="icon"
                        // onClick={() => toggleDropdown("assignee")}
                        onClick={viewassignedropdown}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="assigne-name-drop-down-box">
                  <div
                    className="assigne-name-drop-down-label"
                    onClick={showassigneeDropDownItemfn}
                  >
                    <div className="assigne-name-drop-down-label-name icon">
                      {state.dummyselectedAssignee?.first_name}
                    </div>
                    <div className="assigne-name-drop-down-label-icon">
                      <img src={dropdownicon} className="icon" />
                    </div>
                  </div>
                  <div className="assigne-name-drop-down-edit-box">
                    <div
                      className="assigne-name-drop-down-edit-icon-box"
                      onClick={handleAssigneeconformselection}
                    >
                      <img src={dropdowntediticon} className="icon" />
                    </div>

                    <div
                      className="assigne-name-drop-down-delete-icon-box"
                      onClick={viewassignedropdown}
                    >
                      <img src={dropdowncancelicon} className="icon" />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {state.assigneeDropDownItem && (
              <div className="assignee-dropdown-menu" ref={assigneeDropDownRef}>
                {users.map((user, index) => (
                  <div
                    key={index}
                    className={
                      "assignee-dropdown-item"
                      //  ${
                      //   state.selectedAssignee === user.first_name
                      //     ? "selected-drop-down"
                      //     : ""
                      // }`
                    }
                    onClick={() => handleAssigneeSelection(user)}
                  >
                    {user.first_name.charAt(0).toUpperCase() +
                      user.first_name.slice(1)}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="status-container">
            <div className="status-body">
              <div className="status-heading">Status</div>

              {!state.statusDropDown ? (
                <div className="status-name-box">
                  <div className="status-name">
                    {state.status?.charAt(0).toUpperCase() +
                      state.status?.slice(1)}
                  </div>
                  {(currentUser === "Administrator" ||
                    currentChat.owner_id === currentUserId) && (
                    <div className="status-name-edit">
                      <img
                        src={editpencil}
                        alt="edit"
                        className="icon"
                        // onClick={() => toggleDropdown("status")}
                        onClick={viewstatusdropdown}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="status-name-drop-down-box">
                  <div
                    className="status-name-drop-down-label icon"
                    onClick={showsatusDropDownItemfn}
                  >
                    <div className="status-name-drop-down-label-name">
                      {state.dummystatus?.charAt(0).toUpperCase() +
                        state.dummystatus?.slice(1)}
                    </div>
                    <div className="status-name-drop-down-label-icon">
                      <img src={dropdownicon} className="icon" />
                    </div>
                  </div>
                  <div className="status-name-drop-down-edit-box">
                    <div
                      className="status-name-drop-down-edit-icon-box"
                      onClick={handlestatusconformselection}
                    >
                      <img src={dropdowntediticon} className="icon" />
                    </div>

                    <div
                      className="status-name-drop-down-delete-icon-box"
                      onClick={viewstatusdropdown}
                    >
                      <img src={dropdowncancelicon} className="icon" />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {state.statusDropDownitem && (
              <div className="status-dropdown-menu" ref={statusDropDownRef}>
                {["open", "closed"].map((user, index) => (
                  <div
                    key={index}
                    className={
                      "status-dropdown-item "
                      // ${
                      //   state.status === user ? "selected-drop-down" : ""
                      // }`
                    }
                    onClick={() => handlestatusClick(user)}
                  >
                    {user.charAt(0).toUpperCase() + user.slice(1)}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="details-body">
        <div
          className="starred-messages details-drop-down"
          onClick={handleStarredMessagesClick}
        >
          <p>Starred Messages</p>
          <img src={rightArrow} className="right-arrow" alt="right arrow" />
        </div>

        <div className="files details-drop-down" onClick={handleFilesClick}>
          <p>Files</p>
          <img src={rightArrow} className="right-arrow" alt="right arrow" />
        </div>

        <div
          className="contact-details"
          onClick={(e) => {
            e.preventDefault();
            setState((prevState) => ({
              ...prevState,
              chatUserInfo: !prevState.chatUserInfo,
            }));
          }}
        >
          <div className="contact-details-sub-box">
            <p>Contact Details</p>

            <img
              src={rightArrow}
              className={state.chatUserInfo ? "top-arrow" : "down-arrow"}
              alt="right arrow"
            />
          </div>

          {state.chatUserInfo && (
            <div className="contact-details-main-box">
              <div className="detail-content-box detail-name-box">
                <div className="detail-content-title detail-name-title">
                  Name
                </div>
                <div className="detail-content detail-name-content">
                  {currentChat.contact.name}
                </div>
              </div>

              <div className="detail-content-box detail-company-box">
                <div className="detail-content-title detail-company-title">
                  Company
                </div>
                <div className="detail-content detail-company-content">
                  Chakraa Universal Tech Labs
                </div>
              </div>

              <div className="detail-content-box detail-email-box">
                <div className="detail-content-title detail-email-title">
                  Email ID
                </div>
                <div className="detail-content detail-email-content">
                  krishnavijay92@gmail.com
                </div>
              </div>

              <div className="detail-content-box detail-number-box">
                <div className="detail-content-title detail-number-title">
                  Number
                </div>
                <div className="detail-content detail-number-content">
                  919500402305
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatDetails;
