import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChatContainer from "./ChatContainer";
import ContactsList from "./ContactsList";
import { fetchChats } from "../../store/feature/chat/chatSlice";
import {
  subscribeNotification,
  unSubscribeNotification,
} from "../../store/feature/notification/notificationSlice";
import { useNavigate } from "react-router-dom";

const Chats = ({ contactzindex, setContactzindex }) => 
{
  const navigate=useNavigate()
  const hasChannel = useSelector((state) => state.channel.channels.length > 0);
  const channelStatus = useSelector((state) => state.channel.channels[0]?.status);
  const channelLoading = useSelector((state) => state.channel.channelsLoading);

  const [highlightedMessage, setHighLightedMessage] = useState(null);
  const subscriptions = useSelector(
    (state) => state.notification.subscriptions
  );
  const { currentChat, fetchChatsLoading } = useSelector((state) => state.chat);
  const chatList = useSelector((state) => state.chat.chatList);
  const dispatch = useDispatch();

  useEffect(() => 
  {
    if(channelLoading)
    {
      return 
    }

    if(!channelLoading && hasChannel && channelStatus)
    {
      if (subscriptions && subscriptions["chats"]) {
        const currentTime = Date.now();
        const expiryTime = subscriptions.chats;
  
        if (currentTime >= expiryTime) {
          dispatch(subscribeNotification({ topic: "chats" }));
        }
      } else {
        dispatch(subscribeNotification({ topic: "chats" }));
      }
  
      dispatch(fetchChats());
  
      return () => {
        dispatch(unSubscribeNotification({ topic: "chats" }));
      };
    }
    else
    {
      navigate("/")
    }
  }, [dispatch,,channelStatus,hasChannel,channelLoading]);

  return (
    <div className="main">
      {fetchChatsLoading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          {!fetchChatsLoading && chatList && chatList.length === 0 ? (
            <div
              style={{
                width: "100%",
                height: "90vh",
                display: "grid",
                placeItems: "center",
              }}
            >
              <p>No chats found</p>
            </div>
          ) : (
            <>
              <ContactsList
                contactzindex={contactzindex}
                setContactzindex={setContactzindex}
              />
              {!currentChat ? (
                <div className="initial">
                  Click on any chat in the left panel to open it here.
                </div>
              ) : (
                <>
                  <ChatContainer
                    contactzindex={contactzindex}
                    highlightedMessage={highlightedMessage}
                    setHighlightedMessage={setHighLightedMessage}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Chats;
