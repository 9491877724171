import React, { useCallback, useEffect, useState } from "react";
import back from "../../assets/chat assets/back_arrow.svg";
import { fetchStarredMessages } from "../../store/feature/chat/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../page loader/Spinner";
import Chat from "../chats page/Chat";

const StarredMessagePage = ({ handleBackToDetails, setHighLightedMessage }) => {
  const dispatch = useDispatch();
  const currentChat = useSelector((state) => state.chat.currentChat);
  const chatsStarredMessages = useSelector((state) => state.chat.starredMessages);
  const [starredMessages, setStarredMessages] = useState([]);
  const { fetchStarredMessagesLoading } = useSelector((state) => state.chat);
  const user = currentChat?.contact?.name;

  const handleMessageClick = useCallback((e, messageId) => {
    const messageElement = document.getElementById(`message-${messageId}`);
    if (messageElement) {
      setHighLightedMessage(messageId);
      messageElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [setHighLightedMessage]);

  const handleStarredMessagesClick = useCallback(() => {
    dispatch(fetchStarredMessages(currentChat.id))
      .unwrap()
      .then(() => {
        const chatData = chatsStarredMessages[currentChat?.id];
        if (chatData) {
          setStarredMessages(chatData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, currentChat.id, chatsStarredMessages]);


  useEffect(() => {
    handleStarredMessagesClick();
  }, [handleStarredMessagesClick]);

  // Group messages by time-span
  const groupedMessages = starredMessages.reduce((groups, message) => {
    const date = new Date(message.time).toLocaleDateString(); // Group by date
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
    return groups;
  }, {});

  Object.keys(groupedMessages).forEach((date) => {
    groupedMessages[date].sort((a, b) => new Date(b.time) - new Date(a.time));
  });
  // Sort dates
  const sortedDates = Object.keys(groupedMessages).sort((a, b) => {
    const dateA = new Date(a.split("/").reverse().join("-"));
    const dateB = new Date(b.split("/").reverse().join("-"));
    return dateB - dateA;
  });

  return (
    <div className="starred-messages-page">
      <div className="starred-header">
        <img
          src={back}
          className="back"
          alt="back"
          onClick={handleBackToDetails}
        />
        <div className="starred-header-head">
          <p>Starred messages</p>
        </div>
      </div>

      {fetchStarredMessagesLoading ? (
        <div className="starred-messages-container">
          <div className="loading">
            <Spinner />
          </div>
        </div>
      ) : (
        <div className="starred-messages-container" style={{}}>
          {sortedDates.length === 0 ? (
            <div className="no-message">No Starred Messages</div>
          ) : (
            sortedDates.map((date) => (
              <div key={date}>
                <div className="starred-date">
                  <div className="starred-date-span">{date}</div>
                </div>
                {groupedMessages[date].map((message) => (
                  <div
                    className="started-msg-outbox"
                    key={message.id}
                    onClick={(e) => handleMessageClick(e, message.id)}
                  >
                    <Chat chat={message} user={user} />
                    <div
                      className="started-msg-inbox"
                      onClick={(e) => handleMessageClick(e, message.id)}
                    ></div>
                  </div>
                ))}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default StarredMessagePage;
