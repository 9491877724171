import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Login thunk
export const userLogin = createAsyncThunk('currentuser/details', (_, { dispatch }) => {


  const userManagement = window.catalyst.userManagement;

  return userManagement.getCurrentProjectUser()
    .then(response => {
      if (response.code === 700) {
        window.location.href = "/__catalyst/auth/login";
      } else {
        if (response instanceof Response) {
          return response.json()
            .then(user => {
              dispatch(setCurrentUser(user));
              return user;
            });
        } else {
          // If response is already a JSON object
          dispatch(setCurrentUser(response.content));
          return response.content;
        }
      }
    })
    .catch(error => {
      console.log(error);
      window.location.href = "/__catalyst/auth/login";
      throw error;
    });
});

// Logout thunk
export const logout = createAsyncThunk('currentuser/logout', () => {
  const redirectURL = "/__catalyst/auth/login";
  const auth = window.catalyst.auth;

  return auth.signOut(redirectURL)
    .then(() => {
      console.log("logged out");
    });
});

// Get all users thunk
export const getAllUsers = createAsyncThunk('currentuser/getAllUsers', (_, { getState }) => {
  const { users } = getState().currentuser;

  if (users && users.length !== 0) {
    return Promise.resolve(users);
  }

  return fetch('/server/users')
    .then(response => {
      if (response.status === 204) {
        throw new Error("No users found");
      }
      return response.json();
    })
    .then(data => {
      return data.users;
    })
    .catch(error => {
      throw error;
    });
});

const currentUserSlice = createSlice({
  name: "currentuser",
  initialState: {
    currentUser: null,
    users: [],
    currentUserLoading: true,
    error: null
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.currentUserLoading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.currentUserLoading = false;
        state.currentUser = action.payload;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.currentUserLoading = false;
        state.currentUser = null;
        state.error = action.error.message;
      })
      // Fetch all users
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.error = action.error.message;
      });
  }
});

export const { setCurrentUser } = currentUserSlice.actions;
export default currentUserSlice.reducer;
