import React from 'react';

const PageLoader = () => 
{
  return (
    <div className='progress' id='PreLoaderBar'>
      <div className='indeterminate'></div>
    </div>
  );
};

PageLoader.start = () => {
  document.getElementById('PreLoaderBar').style.display = 'block';
};

PageLoader.stop = () => {
  document.getElementById('PreLoaderBar').style.display = 'none';
};

export default PageLoader;
