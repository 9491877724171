import React, { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import 'pdfjs-dist/build/pdf.worker.mjs';

const TemplatePreviewThumbnail = ({ blob, type,playBtn }) => {
  const [thumbnail, setThumbnail] = useState(null);
  const [videoContent, setVideoContent] = useState(null);

  const generatePdfThumbnail = () => {
    if (blob && blob.type === 'application/pdf') {
      const loadingTask = pdfjs.getDocument(URL.createObjectURL(blob));
      loadingTask.promise.then(pdf => {
        pdf.getPage(1).then(page => {
          const viewport = page.getViewport({ scale: 1 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height/2;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport
          };

          page.render(renderContext).promise.then(() => {
            setThumbnail(canvas.toDataURL());
          });
        });
      }).catch(error => {
        console.error('Error generating thumbnail:', error);
      });
    }
  };

  const generateVideoThumbnail = () => {
    const video = document.createElement('video');
    video.src = URL.createObjectURL(blob);
    video.muted = true;
    video.playsInline = true;

    video.addEventListener('loadeddata', () => {
      video.currentTime = 1;
    });

    video.addEventListener('seeked', () => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const thumbnailUrl = canvas.toDataURL('image/png');
      setThumbnail(thumbnailUrl);
      setVideoContent(
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <img src={thumbnailUrl} alt="thumbnail" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          {playBtn && <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'rgba(0, 0, 0, 0.6)',
            borderRadius: '50%',
            padding: '10px',
            cursor: 'pointer'
          }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 5V19L19 12L8 5Z" fill="white" />
            </svg>
          </div>}
        </div>
      );
    });

    video.addEventListener('error', () => {
      console.error('Error loading video');
    });
  };

  useEffect(() => {
    if (blob && type) {
      if (type === 'video') {
        generateVideoThumbnail();
      } else if (type === 'pdf') {
        generatePdfThumbnail();
      }
    }
  }, [blob, type]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {type === 'video' && videoContent}
      {type === 'pdf' && thumbnail && <img src={thumbnail} alt="pdf thumbnail" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />}
    </div>
  );
};

export default TemplatePreviewThumbnail;
