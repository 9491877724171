import React, { useEffect, useState } from 'react'
import back from '../../assets/contacts assets/back_arrow.svg'
import { useDispatch, useSelector } from 'react-redux'
import { postContact, updateContact } from '../../store/feature/contacts/contactSlice'
import validator from 'validator'
import LoadingScreen from '../../page loader/LoadingScreen'
import { updateContactInChat } from '../../store/feature/chat/chatSlice'

const initialState = 
{
    name: "",
    mobile: "",
    email:""
}

const ContactForm = ({ reset, isEdit, id }) => {
    const [formState, setFormState] = useState(initialState)
    const contacts=useSelector(state=>state.contact.contacts)
    const { createContactLoading,updateContactLoading }=useSelector(state=>state.contact)
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()

    useEffect(()=>{
        if(id)
        {
            const contactToEdit=contacts.find(item=>item.id===id)
            if(contactToEdit)
            {
                // if(contactToEdit.mobile.length===12)
                // {
                    setFormState(prev=>({...prev,name:contactToEdit.name,mobile:"+"+contactToEdit.mobile,email:(contactToEdit.email || "")}))
                // }
                // else
                // {
                //     setFormState(prev=>({...prev,name:contactToEdit.name,mobile:"+91"+contactToEdit.mobile,email:(contactToEdit.email || "")}))
                // }
                
            }
        }
    },[])

    const validateForm = () => {
        let formErrors = {}
        if (!formState.name.trim()) {
            formErrors.name = "Name is required"
            setErrors(formErrors)
            return false
        }
        
        if (!formState.mobile.slice(1).trim()) {
            formErrors.mobile = "Mobile number is required"
            setErrors(formErrors)
            return false
        }

        // const mobileRegex = /^[0-9]{10}$/;
        // if (!formState.mobile.slice(3).match(mobileRegex)) 
        // {
        //     formErrors.mobile = "Mobile number must be of 10 digits.";
        //     setErrors(formErrors);
        //     return false;
        // }

        if(formState.email.trim() && !validator.isEmail(formState.email))
        {
            formErrors.email = "Enter a valid email"
            setErrors(formErrors)
            return false
        }

        
        return Object.keys(formErrors).length === 0
    }

    const handleCreate = () => {
        if (validateForm()) 
        {
            const body=
            {
                name:formState.name,
                mobile:formState.mobile.slice(1),
            }

            if(formState.email.trim())
            {
                body.email=formState.email.trim()
            }

            if (isEdit) 
            {
               
                dispatch(updateContact({ id, contact: body }))
                .unwrap()
                .then(response=>
                {
                    dispatch(updateContactInChat({id:id,contact:body}))
                    reset()
                })
                .catch(err=>
                {
                    if(err.code==="INVALID_DATA")
                    {
                        setErrors(prev=>({...prev,mobile:"Invalid mobile number."}))
                    }
                    
                })
            } 
            else 
            {
                dispatch(postContact(body))
                .unwrap()
                .then(response=>
                {

                    reset()
                })
                .catch(err=>
                {
                    if(err.code==="INVALID_DATA")
                    {
                        setErrors(prev=>({...prev,mobile:"Invalid mobile number."}))
                    }
                    
                })
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        if (name === "mobile") {
            // Ensure the input always starts with '+91'
            let digits = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    
            // Handle backspace: if the length of digits is less than 2, reset to '+91'
            // if (digits.length <= 2) {
            //     digits = "91";
            // } else {
                // digits = digits.slice(0, 12); // Limit the input to 12 characters including '+91'
            // }
    
            const validValue = "+" + digits; // Maintain '+91' prefix
    
            setFormState(prevState => ({
                ...prevState,
                [name]: validValue
            }));
        } 
        else if (name === "email") 
        {
            const emailValue = value.trim(); // Trim whitespace from email
    
            setFormState(prevState => ({
                ...prevState,
                [name]: emailValue
            }));
        } 
        else {
            setFormState(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };
    
    
    

    return (
        <div className="contact-form">
            {(updateContactLoading || createContactLoading ) && <LoadingScreen/>}
            <div className="contact-form-header-section">
                <div className='contact-header-title-section'>
                    <div className='contact-header-title-content'>
                        <img src={back} alt="back" width="18px" height="18px" className='icon' onClick={reset} />
                    </div>
                    <div className='contact-header-title-content'>
                        <p className="title">{isEdit ? "Edit Contact" : "Create New Contact"}</p>
                    </div>
                </div>
                <div className="button-container">
                    <button className="create-button" onClick={handleCreate}>Save</button>
                    <button className="close-button" onClick={reset}>Cancel</button>
                </div>
            </div>

            
            <form className="contact-form-body">
            
                <div className='form-container'>
                <div className='form-title'>
                    <p>Basic Details</p>
                </div>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formState.name}
                        autoComplete={"off"}
                        onChange={handleChange}
                        className={errors.name ? 'input-error' : ''}
                    />
                   <p className="error-text">{errors.name && "*" + errors.name}</p>

                </div>
                <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                        type="text"
                        name="mobile"
                        autoComplete={"off"}
                        value={formState.mobile || "+"}
                        onChange={handleChange}
                        className={errors.mobile ? 'input-error' : ''}
                    />
                    <p className="error-text">{errors.mobile && "*" + errors.mobile}</p>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        autoComplete={"off"}
                        value={formState.email}
                        onChange={handleChange}
                        className={errors.email ? 'input-error' : ''}
                    />
                    <p className="error-text">{errors.email && "*" + errors.email}</p>
                </div>
            </div>
            </form>
        </div>
    )
}

export default ContactForm
