import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTemplates, publishTemplate } from '../../store/feature/templates/templateSlice';
import TemplateForm from './TemplateForm';
import Spinner from '../../page loader/Spinner'
import './templatePage.css';
import { templateBodyToFormState } from './templateFormUtils';
import LoadingScreen from '../../page loader/LoadingScreen'
import { useNavigate } from 'react-router-dom';
// import Toast from '../../page loader/Toast';

const languageOptions = {
  en: "English",
  en_GB: "English (UK)",
  en_US: "English (US)",
  hi: "Hindi",
  ta: "Tamil"
};


const TemplatesPage = () => 
{
  const navigate=useNavigate()
  const hasChannel = useSelector((state) => state.channel.channels.length > 0);
  const channelStatus = useSelector((state) => state.channel.channels[0]?.status);
  const channelLoading = useSelector((state) => state.channel.channelsLoading);

  const [isCreateForm, setIsCreateForm] = useState(false);
  const templates = useSelector(state => state.template.templates);
  const currentUser=useSelector(state=>state.currentuser.currentUser)
  // const [publish,setPublish]=useState("PUBLISH")
  // const [toast, setToast] = useState({ show: true, message: '', type: '' });

  const {templatesLoading , createTemplateLoading,editTemplateLoading,publishTemplateLoading} = useSelector(state => state.template);
  const [editTemplate,setEditTemplate]=useState({id:null,template:null,templateState:null})
  const loading=(createTemplateLoading || templatesLoading || editTemplateLoading || publishTemplateLoading)


  const dispatch = useDispatch();

  useEffect(() => 
    {
      if(channelLoading)
      {
        return 
      }
      
      if(!channelLoading && hasChannel && channelStatus)
      {
        dispatch(fetchTemplates());
      }
      else
      {
        navigate("/")
      }
    }, [dispatch,channelStatus,hasChannel,channelLoading]);

  const handleCreate = () => {
    setIsCreateForm(true);
  };

  const handleEdit=(template)=>{
    setEditTemplate(prev=>({...prev,id:template.id,template:templateBodyToFormState(template),templateState:template}))
    setIsCreateForm(true)

  }

  const handlePublish=(template)=>
  {
    dispatch(publishTemplate({id:template.id}))
    .unwrap()
    .then(response=>{
      // setPublish("PUBLISHED")
      // setToast({ show: true, message: 'Template published successfully!', type: 'toast-success' });
      alert('Template published successfully!')
    })
    .catch(err=>{
      const message=err.message || 'Failed to publish template.'
      // setToast({ show: true, message:message , type: 'toast-error' });
      alert(message)
    })
  }

  const closeForm=()=>
  {
    setIsCreateForm(false)
    if(editTemplate.id!==null)
    {
      setEditTemplate(prev=>({...prev,id:null,template:null,templateState:null}))
    }
  }

  return (
    <>
    {/* {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ show: false, message: '', type: '' })}
        />
      )} */}
    {/* <div className='under-dev'>This feature is not fully functional yet and is currently under development.</div> */}
    <div className="page-container">
    {/* {loading &&  <LoadingScreen/>} */}
      {!isCreateForm && (
        <>
          <div className="header-container">
            <button className="create-button" disabled={loading} onClick={handleCreate}>
              New Template
            </button>
          </div>
          <div className='template-table-container'>
              <table className="template-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Language</th>
                    <th>Category</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className='templates-list'>
                {templatesLoading && (
                      <tr>
                        <td colSpan="5" className="centered-spinner">
                          <Spinner />
                        </td>
                      </tr>
                    )}
                  {!templatesLoading && templates?.map(template => (

                  <tr key={template.id} className={"template-table-row"}>
                  <td className="table-contact-checkbox">
                    <div className="contact-name-data">
                      <div className="row-contact-checkbox">&nbsp;</div>
                    </div>
                  </td>
                  <td className="table-contact-name-box">
                    <div className="contact-name-data">
                      <div className="row-contact-name">{template.name}</div>
                    </div>
                  </td>
                  <td>{languageOptions[template.language]}</td>
                  <td>{template.category.charAt(0).toUpperCase()+template.category.slice(1)}</td>
                  <td className="table-country-name-box template-last-column">
                    <div className="country-name">{template.status.charAt(0).toUpperCase()+template.status.slice(1)}</div>
                    <div className={"template-action-box"}>
                      <button
                        className="template-action-edit-btn"
                        disabled={loading}
                        onClick={()=>handleEdit(template)}
                      >
                        EDIT
                      </button>
                      {currentUser?.role_details.role_name === "Administrator" && ["draft","active","rejected","paused"].includes(template.status) && (
                          <button
                          className="template-action-publish-btn"
                          disabled={loading}
                          onClick={() => handlePublish(template)}
                        >
                          PUBLISH
                        </button>
                      )}

                    </div>
                  </td>
                  </tr>
                  ))}
                </tbody>
              </table>
          </div>
        </>
      )}
      
      {((isCreateForm && editTemplate.id!==null) || isCreateForm) && <TemplateForm closeForm={closeForm} isEdit={editTemplate.id!==null ? true : false} initialFormState={editTemplate.template || null} id={editTemplate.id} templateState={editTemplate.templateState}/>}
    </div>
    </>
  );
};

export default TemplatesPage;
