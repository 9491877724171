// FacebookSDK.js
export const initFacebookSdk = () => {
    return new Promise((resolve, reject) => {
      if (window.FB) {
        console.log("Facebook SDK already initialized");
        resolve();
        return;
      }
  
      window.fbAsyncInit = () => {
        console.log("Initializing Facebook SDK");
        window.FB.init({
          appId: '1001056741640607',
          cookie: true,
          xfbml: true,
          version: 'v20.0'
        });
        console.log("Facebook SDK initialized");
        resolve();
      };
  
      console.log("Loading Facebook SDK script");
  
      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          console.log("Facebook SDK script already present");
          resolve();
          return;
        }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        js.onload = () => {
          console.log("Facebook SDK script loaded");
        };
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    });
  };
  
  export const getFacebookLoginStatus = () => {
    return new Promise((resolve, reject) => {
      if (!window.FB) {
        reject("Facebook SDK not initialized");
        return;
      }
      window.FB.getLoginStatus((response) => {
        resolve(response);
      });
    });
  };
  
  export const fbLogin = (config) => {
    return new Promise((resolve, reject) => {
      if (!window.FB) {
        reject("Facebook SDK not initialized");
        return;
      }

      window.FB.init({
        appId: '1001056741640607',
        cookie: true,
        xfbml: true,
        version: 'v20.0'
      });

      window.FB.login((response) => {
        if (response.authResponse) {
          resolve(response);
        } else {
          reject('User cancelled login or did not fully authorize.');
        }
      }, config);
    });
  };
  