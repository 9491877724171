// import React from "react";
// import { Link, useLocation } from "react-router-dom";
// import logo from "../assets/header assets/chatzeal_logo.svg";
// import account from "../assets/header assets/Group 7751.svg";
// import { useDispatch } from "react-redux";
// import { logout } from "../store/feature/user/currentUserSlice";
// import searchicon from "../assets/header assets/search.svg";
// import notificationicon from "../assets/header assets/notificaton.svg";
// // import { unSubscribeNotification } from "../store/feature/notification/notificationSlice";

// const Header = ({ hasChannel, isActive = false }) => {
//   const dispatch = useDispatch();
//   const location = useLocation(); // Get the current path

//   const logoutHandle = () => {
//     // dispatch(unSubscribeNotification({ topic: "chats" }));
//     dispatch(logout());
//   };

//   return (
//     <div className="header">
//       <div className="brand">
//         <div className="logo">
//           <img src={logo} className="icon" alt="logo" />
//           <p className="logotext">Chatzeal</p>
//         </div>
//         <div className="navbar">
//           {[
//             "/home",
//             "/chats",
//             "/contacts",
//             "/campaigns",
//             "/tasks",
//             "/analytics",
//             "/templates",
//           ].map((path) => (
//             <Link
//               key={path}
//               to={path}
//               className={`nav ${hasChannel && isActive ? "" : "disabled"} ${
//                 location.pathname === path ? "clicked" : ""
//               }`}
//             >
//               {path.charAt(1).toUpperCase() + path.slice(2)}
//             </Link>
//           ))}
//         </div>
//       </div>
//       <div className="account">
//         <div className="header-search-box">
//           <img src={searchicon} alt="" />
//         </div>
//         <div className="header-notification-box">
//           <img src={notificationicon} alt="" />
//         </div>
//         <div className="header-logout-box">
//           <img src={account} onClick={() => logoutHandle()} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Header;

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/header assets/chatzeal_logo.svg";
import account from "../assets/header assets/Group 7751.svg";
import { useDispatch } from "react-redux";
import { logout } from "../store/feature/user/currentUserSlice";
import searchicon from "../assets/header assets/search.svg";
import notificationicon from "../assets/header assets/notificaton.svg";

const Header = ({ hasChannel, isActive = false, setContactzindex }) => {
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current path

  const [headerviewdropdown, setHeaderviewdropdownDropdown] = useState(false);

  const logoutOptionViewFn = () => {
    setHeaderviewdropdownDropdown(!headerviewdropdown);
    setContactzindex((prvestate) => ({
      ...prvestate,
      headerpdown: !prvestate.headerpdown,
    }));
    // setContactzindex(true);
  };

  const logoutHandle = () => {
    dispatch(logout());
  };

  return (
    <div className="header">
      {headerviewdropdown && (
        <div
          className="logout-drop-down-close-div"
          onClick={logoutOptionViewFn}
        ></div>
      )}

      <div className="brand">
        <div className="logo">
          <img src={logo} className="icon" alt="logo" />
          <p className="logotext">Chatzeal</p>
        </div>
        <div className="navbar">
          {[
            "/",
            "/chats",
            "/contacts",
            "/templates",
            // "/campaigns",
            // "/tasks",
            // "/analytics",
          ].map((path, index) => (
            <div
              key={index}
              // className={`${
              //   ["/campaigns", "/tasks", "/analytics"].includes(path)
              //     ? "nav-layer"
              //     : ""
              // }`}
            >
              <Link
                to={path}
                className={`nav ${hasChannel && isActive ? "" : "disabled"} 
              ${location.pathname === path ? "clicked" : ""}`}
              >
                {path!=="/" ? path.charAt(1).toUpperCase() + path.slice(2) : "Home"}
              </Link>
              {/* {["/campaigns", "/tasks", "/analytics"].includes(path) && (
                <div className="layer"></div>
              )} */}
            </div>
          ))}

          {/* <div className="Under-Development-text1">Under Development</div>
          <div className="Under-Development-text2">Under Development</div>
          <div className="Under-Development-text3">Under Development</div>
          <div className="Under-Development-text4">Under Development</div> */}
        </div>
      </div>

      <div className="header-acc-details-main-box">
        <div className="account">
          <div className="header-search-box">
            <img src={searchicon} alt="" />
          </div>
          <div className="header-notification-box">
            <img src={notificationicon} alt="" />
          </div>
          <div className="header-logout-box" onClick={logoutOptionViewFn}>
            <img src={account} />
          </div>
        </div>

        {headerviewdropdown && (
          <div className="header-drop-down-box">
            <div
              className="header-drop-down-content"
              onClick={() => logoutHandle()}
            >
              Logout
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
