import React from "react";
import { marked } from "marked";
import DOMPurify from "dompurify";

const TextChat = ({ content }) => {
  // console.log(content, "text");
  const text = content;
  const isBlockquote = text.startsWith(">");
  const isCode =
    text.startsWith("`") && text.endsWith("`") && !text.startsWith("```");
  const createMarkup = (text) => {
    let updatedText = text;

    const urlRegex = /https?:\/\/[^\s]+/g;
    const urls = [];
    updatedText = updatedText.replace(urlRegex, (url) => {
      urls.push(url);
      return `<<URL:${urls.length - 1}>>`;
    });

    updatedText = updatedText.replace(/\*(.*?)\*/g, (match, p1) => {
      if (p1.includes("\n")) {
        return match;
      } else {
        return `**${p1}**`;
      }
    });

    // Replace underscores with asterisks if they open and close on the same line
    updatedText = updatedText.replace(/_(.*?)_/g, (match, p1) => {
      if (p1.includes("\n")) {
        return match;
      } else {
        return `*${p1}*`;
      }
    });

    updatedText = updatedText.replace(
      /<<URL:(\d+)>>/g,
      (match, index) => urls[Number(index)]
    );

    if (text.startsWith("**") && text.endsWith("**")) {
      updatedText = `**\\${text.slice(1, -1)}**`;
    }
    if (text.includes("___")) {
      updatedText = text.replace(/_{3,}/g, "_");

      updatedText = `*\\${updatedText}*`;
    }
    if (text.startsWith("__") && text.endsWith("__") && !text.includes("___")) {
      updatedText = `*\\${text.slice(1, -1)}*`;
    }
    if (text.startsWith("```") && text.endsWith("```")) {
      updatedText = `\`\`\`\n${text.slice(3, -3)}\n\`\`\``;
    }
    if (text.startsWith("~~")) {
      updatedText = text.replace(/~{3,}/g, "~");
    }

    let rawMarkup = marked(updatedText, { gfm: true, breaks: true });
    const cleanMarkup = DOMPurify.sanitize(rawMarkup, {
      ADD_ATTR: ["target", "rel"],
    });
    const htmlWithTargetBlank = cleanMarkup.replace(
      /<a /g,
      '<a target="_blank" '
    );

    return { __html: htmlWithTargetBlank };
  };

  return (
    <div
      style={{
        borderLeft: isBlockquote ? "4px solid #ccc" : "none",
        paddingLeft: isBlockquote ? "8px" : "0",
        display: isBlockquote ? "inline-block" : "block",
        backgroundColor: isBlockquote ? "#ccc" : "transparent",
        backgroundColor: isCode ? "#f7f7f7" : "transparent",
        display: isCode ? "inline-block" : "block",
        padding: isCode ? "4px" : "0",
        borderRadius: isCode ? "4px" : "0",
        // background: "red",
      }}
      className="msg-chat-text-content"
      dangerouslySetInnerHTML={createMarkup(text)}
    />
  );
};

export default TextChat;
