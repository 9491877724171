import React, { useEffect } from "react";
import deleteIcon from "../../assets/chat assets/delete.svg";
import { ReactMic } from "react-mic";
const AudioRecorder = ({
  isRecording,
  recordingTime,
  setRecordingTime,
  onStop,
  onData,
  cancelRecording,
}) => {
  useEffect(() => {
    let timer;
    if (isRecording) {
      timer = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [isRecording]);

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }:${remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds}`;
  }

  return (
    <div
      className={`audio_container`}
      style={{ display: isRecording ? "flex" : "none" }}
    >
      {isRecording && (
        <>
          <div className="delete-icon" title="cancel" onClick={cancelRecording}>
            <img src={deleteIcon} alt="delete icon" />
          </div>
          <div className="timer">{formatTime(recordingTime)}</div>
        </>
      )}
      <div className="audio-waves">
        <ReactMic
          record={isRecording}
          className={"reactmic"}
          onStop={onStop}
          visualSetting="sinewave"
          onData={onData}
          strokeColor="#000000"
          mimeType="audio/ogg; codecs=opus"
        />
      </div>
    </div>
  );
};

export default AudioRecorder;
