import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PageLoader from '../../../page loader/PageLoader';

export const fetchContacts=createAsyncThunk('contacts/fetchContacts',(_,{getState})=>
{
    
    const contact=getState().contact
    const contacts=contact.contacts
    if(contacts && contacts.length!==0)
    {
        return Promise.resolve(contacts)
    }
    else
    {
        return fetch('/server/contacts')
        .then(res=>
        {
            if(!res.ok)
            {
                throw Error(res.statusText)
            }
            return res.json()
        })
        .then(response=>
        {
            return response.contacts
        })
        .catch(error=>
        {
            throw error
        })
    }
})

export const fetchContactById=createAsyncThunk('contacts/fetchContactById',(id,{getState})=>
{
    
    const contact=getState().contact
    const contactById=contact.contactsByID[id]
    if(contactById)
    {
        return Promise.resolve({id:id,contact:contactById})
    }
    else
    {
        return fetch(`/server/contacts/${id}`)
        .then(res=>
        {
            if(!res.ok)
            {
                throw Error(res.statusText)
            }
            return res.json()
        })
        .then(response=>
        {
            return {id:id,contact:response}
        })
        .catch(error=>
        {
            throw error
        })
    }
})

export const postContact = createAsyncThunk('contacts/postContact', (contact) => 
{
    PageLoader.start()
    return fetch('/server/contacts', 
    { 
        method: "POST",
        headers: 
        { 
            "Content-Type": "application/json" 
        },
        body: JSON.stringify(contact)
    })
    .then(res => 
    {
        PageLoader.stop()
        return res.json();
    })
    .then(response => 
    {
        if(response.status==="error")
        {
            throw response
        }
        return response;
    })
    .catch(error => 
    {
        PageLoader.stop()
        throw error;
    });
});

export const updateContact = createAsyncThunk('contacts/updateContact', ({id,contact}) => 
{
    PageLoader.start()
    return fetch(`/server/contacts/${id}`, 
    { 
        method: "PUT",
        headers: 
        { 
            "Content-Type": "application/json" 
        },
        body: JSON.stringify(contact)
    })
    .then(res => 
    {
        PageLoader.stop()
        
        return res.json();
    })
    .then(response => 
    {
        if(response.status==="error")
        {
            throw response
        }
        return {id:id,contact:contact};
    })
    .catch(error => 
    {
        PageLoader.stop()
        throw error;
    });
});

export const deleteContact = createAsyncThunk('contacts/deleteContact', ({id}) => 
{
    PageLoader.start()
    return fetch(`/server/contacts/${id}`, 
    { 
        method: "DELETE"
    })
    .then(res => 
    {
        PageLoader.stop()
        if (!res.ok) 
        {
            throw new Error(res.statusText);
        }
        return res.json();
    })
    .then(response => 
    {
        return {id:id};
    })
    .catch(error => 
    {
        PageLoader.stop()
        throw error;
    });
});

export const enableChat = createAsyncThunk('contacts/enableChat', ({id,body}) => 
{
    PageLoader.start()
    return fetch(`/server/contacts/${id}/chats`, 
    { 
        method: "POST",
        headers: 
        { 
            "Content-Type": "application/json" 
        },
        body: JSON.stringify(body)
    })
    .then(res => 
    {
        
        if (!res.ok) 
        {
            throw new Error(res.statusText);
        }
        return res.json();
    })
    .then(response => 
    {
        return fetch(`/server/chats`)
        .then(res=>{
            PageLoader.stop()
            if(res.status===204)
            {
                return { chats:[] }
            }
            else if(!res.ok) 
            {
                throw new Error(res.statusText);
            }
            return res.json();
        })
        .then(data=>{
            return { response,chats:data.chats}
        })

    })
    .catch(error => 
    {
        PageLoader.stop()
        throw error;
    });
});


const contactSlice = createSlice({
    name: "contact",
    initialState: {
        contacts: [],
        contactsByID:{},
        contactsLoading: true,
        contactByIDLoading:false,
        createContactLoading:false,
        updateContactLoading:false,
        deleteContactLoading:false,
        enableChatLoading:false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchContacts.pending, (state) => {
                state.contactsLoading = true;
            })
            .addCase(fetchContacts.fulfilled, (state, action) => {
                state.contacts = action.payload;
                state.contactsLoading = false;
            })
            .addCase(fetchContacts.rejected, (state, action) => {
                state.error = action.error.message;
                state.contactsLoading = false;
            })
            .addCase(fetchContactById.pending, (state) => {
                state.contactByIDLoading = true;
            })
            .addCase(fetchContactById.fulfilled, (state, action) => {
                const {id,contact}=action.payload
                state.contactsByID[id]= contact;
                state.contactByIDLoading = false;
            })
            .addCase(fetchContactById.rejected, (state, action) => {
                state.error = action.error.message;
                state.contactByIDLoading = false;
            })
            .addCase(postContact.pending, (state) => {
                state.createContactLoading = true;
            })
            .addCase(postContact.fulfilled, (state, action) => {
                state.createContactLoading = false;
                state.contacts.push(action.payload);
            })
            .addCase(postContact.rejected, (state, action) => {
                state.createContactLoading = false;
                state.error = action.error.message;
            })
            .addCase(updateContact.pending, (state) => {
                state.updateContactLoading = true;
            })
            .addCase(updateContact.fulfilled, (state, action) => {
                state.updateContactLoading = false;
                const { contact, id } = action.payload;
                const contactToUpdate = state.contacts.find(cont => cont.id === id);
                if (contactToUpdate) 
                {
                    
                  Object.assign(contactToUpdate, contact);
                  if(state.contactsByID[id])
                  {
                    const contactById=state.contactsByID[id]
                    state.contactsByID[id]={...contactById,...contact}
                  }
                }
            })
            .addCase(updateContact.rejected, (state, action) => {
                state.updateContactLoading = false;
                state.error = action.error.message;
            })
            .addCase(deleteContact.pending, (state) => {
                state.deleteContactLoading = true;
            })
            .addCase(deleteContact.fulfilled, (state, action) => {
                state.deleteContactLoading = false;
                const { id } = action.payload;
                state.contacts=state.contacts.filter(item=>item.id!==id)
            })
            .addCase(deleteContact.rejected, (state, action) => {
                state.deleteContactLoading = false;
                state.error = action.error.message;
            })
            .addCase(enableChat.pending, (state) => {
                state.enableChatLoading = true;
            })
            .addCase(enableChat.fulfilled, (state, action) => {
                state.enableChatLoading = false;
                const response = action.payload.response;
                const id=action.meta.arg.id

                const contact=state.contactsByID[id]
                if(contact)
                {
                    contact.chats = [...(contact.chats || []), response];
                }
            })
            .addCase(enableChat.rejected, (state, action) => {
                state.enableChatLoading = false;
                state.error = action.error.message;
            });
    }
});

export default contactSlice.reducer;