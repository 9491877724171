import React from "react";
import loginImg from "../../assets/home assets/login.jpg";

const FacebookLogin = ({ launchWhatsAppSignup, clicked }) => {
  return (
    <div className="facebook-login-steps">
      <div className="facebook-login-header">
        <p className="facebook-login-title">
          Connect your WhatsApp Business Account
        </p>
        <p className="facebook-login-content">
        You can link your existing WABA account or create a new one.
        </p>
      </div>

      <div className="facebook-login-main">
        <div className="facebook-login-image">
          <img src={loginImg} alt="login" />
        </div>
        <div className="facebook-login-instructions">
          <div className="facebook-login-instructions-heading">
            Please keep the following ready beforehand before initiating.
          </div>
          <div className="facebook-login-instructions-content1">
            1) The login credentials of the Meta/Facebook account that already
            owns your Business profile or that you want to use to create your
            Business profile for your business.
          </div>
          <div className="facebook-login-instructions-content2">
            2) The phone number that you want to use for your WhatsApp Business
            Account. You must be able to verify the phone number via a text
            message or a voice call.
          </div>
        </div>
      </div>
      <div className="facebook-login-footer">
        <button
          className={`facebook-login-button ${clicked ? "disabled" : ""}`}
          disabled={clicked}
          onClick={launchWhatsAppSignup}
        >
          Connect via Facebook
        </button>
      </div>
    </div>
  );
};

export default FacebookLogin;
