import { configureStore } from '@reduxjs/toolkit';
import chatReducer from './feature/chat/chatSlice';
import currentUserReducer from './feature/user/currentUserSlice'
import templateReducer from './feature/templates/templateSlice'
import channelReducer from './feature/channels/channelSlice'
import contactReducer from './feature/contacts/contactSlice'
import notificationReducer from './feature/notification/notificationSlice'

export const store = configureStore({
  reducer: {
    chat: chatReducer,
    currentuser: currentUserReducer,
    template:templateReducer,
    channel:channelReducer,
    contact:contactReducer,
    notification:notificationReducer
  }
});

export default store;
