import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileChat from "./chats components/FileChat";
import ImageChat from "./chats components/ImageChat";
import VideoChat from "./chats components/VideoChat";
import { formatTime } from "../../utils/utils";
import { base64ToBlob } from "../../cache/cacheUtils";
import Preview from "./Preview";
import { fetchMedia } from "../../store/feature/chat/chatSlice";

const FilePageChat = ({ message }) => {
  const { currentChat, chatList, loading, messages } = useSelector((state) => state.chat);
  const currentMessage =
    messages[currentChat?.id]
      ? messages[currentChat?.id].find((m) => m.id === message.id)
      : null;
  const loadingStatus = loading[currentMessage?.id] || false;
  const [state, setState] = useState({ blob: null, previewOpen: false });
  const dispatch = useDispatch();
  const blobs = useSelector((state) => state.chat.mediaBlobs);

  useEffect(() => {
    if (blobs[message.id]) {
      base64ToBlob(blobs[message.id])
        .then((result) => {
          setState((prevState) => ({ ...prevState, blob: result }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [blobs, message.id]);

  const downloadFile = (chatId, messageId, type) => {
    if (blobs[messageId]) {
      base64ToBlob(blobs[messageId]).then((fileBlob) => {
        setState({ blob: fileBlob, previewOpen: true });
      });
    } else {
      dispatch(fetchMedia({ chatId, messageId }))
        .unwrap()
        .then((result) => base64ToBlob(result.data))
        .then((fileBlob) => {
          setState({ blob: fileBlob, previewOpen: type === "document" });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const closePreview = () => {
    setState({ blob: null, previewOpen: false });
  };

  return (
    <>
      <div
        className="file-chat-message"
        key={message.id}
        onClick={() =>
          downloadFile(message.chat.id, message.id, message.content.file.type)
        }
        style={{ position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            backgroundColor: "none",
            width: "100%",
            height: "100%",
            zIndex: "10",
          }}
          className="file-chat-in-box"
        ></div>
        {message.content.file.type === "document" && (
          <FileChat
            content={message.content}
            blob={state.blob}
            loading={loadingStatus}
          />
        )}
        {message.content.file.type === "image" && (
          <ImageChat
            content={message.content}
            blob={state.blob}
            loading={loadingStatus}
          />
        )}
        {message.content.file.type === "video" && (
          <VideoChat
            content={message.content}
            blob={state.blob}
            loading={loadingStatus}
          />
        )}
      </div>
      {state.blob !== null && state.previewOpen && (
        <Preview blob={state.blob} closePreview={closePreview} />
      )}
    </>
  );
};

export default FilePageChat;
