import React from 'react';

const LoadingScreen = ({position="fixed"}) => {
  const loadingScreenStyle = {
    position: position,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    zIndex: 100, 
  };

  return <div style={loadingScreenStyle}></div>;
};

export default LoadingScreen;
