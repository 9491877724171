import React from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const EmojiContainer = ({ onSelect }) => {
  return (
    <div className="emoji-container">
      <Picker
        data={data}
        onEmojiSelect={onSelect}
        previewPosition={"none"}
        dynamicWidth={true}
        emojiSize={18}
        emojiButtonSize={30}
        emojiButtonRadius={"0px"}
        skinTonePosition={"top"}
        set={"native"}
        //  skinTonePosition={"none"}
      />
    </div>
  );
};

export default EmojiContainer;
