import React from 'react';
import deleteIcon from '../../assets/chat assets/delete.svg'
import { extractVariables, isValidVariables, replaceVariables } from './templateFormUtils';
import validator from 'validator';

const TemplateFormButton = ({ button, setFormState, index,setNewVariable,setCurrentEvent,prevStateRef,error,setError }) => 
{
    function handleDropDownChange(e) 
    {
        setFormState(prev => {
            const updatedButtons = [...prev.content.buttons];
            updatedButtons[index] = { ...updatedButtons[index], type: e.target.value };
            if(!updatedButtons[index].text)
            {
                updatedButtons[index] = { ...updatedButtons[index], text: ""};
            }
            return {
                ...prev,
                content: {
                    ...prev.content,
                    buttons: updatedButtons
                }
            };
        });
    }

    function handleLabelChange(e)
    {
        setError(prev=>({...prev,buttonLabelError:null}))
        setFormState(prev => {
            const updatedButtons = [...prev.content.buttons];
            updatedButtons[index] = { ...updatedButtons[index], text: e.target.value };
            return {
                ...prev,
                content: {
                    ...prev.content,
                    buttons: updatedButtons
                }
            };
        });
    }

    function handleInputValueChange(e)
    {
        setError(prev=>({...prev,buttonParamError:null}))
        setError(prev=>({...prev,urlParamError:null}))
        setError(prev=>({...prev,buttonValueError:null}))
        if(['phone_number','url'].includes(button.type))
        {
            setCurrentEvent({e,index})
            setFormState(prev => {
                prevStateRef.current=prev
                const updatedButtons = [...prev.content.buttons];
                if(button.type==="url")
                {
                    const {variables,text}=extractVariables(e.target.value,"button",setNewVariable)
                    if(variables.length!==0 && !isValidVariables(variables))
                    {
                        setError(prev=>({...prev,buttonVariableError:{error:"Variable name must only contain lowercase letters, numbers, and underscores, and must not start with an underscore.",fieldIndex:index}}))
                        return prevStateRef.current 
                    }
                    else
                    {
                        setError(prev=>({...prev,buttonVariableError:null}))
                        let params = button.params;

                        if (variables.length > 0) {
                            if (params.length === 0) {
                                // If params is empty, initialize it with the first variable
                                params = [{ name: variables[0], example: ""}];
                            } else {
                                // If params is not empty, update the first param's name and field while preserving the example
                                params[0] = {
                                    ...params[0], 
                                    name: variables[0], 
                                };
                            }
                        } else {
                            // If no variables are present, reset params to an empty array
                            params = [];
                        }

                        const actual_text = replaceVariables("button",text, params);
                        const isValidUrl=validator.isURL(actual_text)

                        if(isValidUrl && text.trim()!=="")
                        {
                            setError(prev=>({...prev,buttonUrlError:null}))
                        }
                        else
                        {
                            const urlError={error:"Enter a valid url.",fieldIndex:index}
                            setError(prev=>({...prev,buttonUrlError:urlError}))
                        }

                        updatedButtons[index] = 
                        { 
                            ...updatedButtons[index],
                            [button.type] : text,
                            actual_text:actual_text,
                            params:params
                        };

                        return {
                            ...prev,
                            content: {
                                ...prev.content,
                                buttons: updatedButtons
                            }
                        };

                    }

                }
                else
                {
                    updatedButtons[index] = 
                    { 
                        ...updatedButtons[index],
                        [button.type] : e.target.value
                    };
                    return {
                        ...prev,
                        content: {
                            ...prev.content,
                            buttons: updatedButtons
                        }
                    };
                }
                
                
            });
        }
        else if(['copy_code'].includes(button.type))
        {
            setFormState(prev => {
                const updatedButtons = [...prev.content.buttons];
                updatedButtons[index] = 
                { 
                    ...updatedButtons[index],
                    example_code:e.target.value
                };
                return {
                    ...prev,
                    content: {
                        ...prev.content,
                        buttons: updatedButtons
                    }
                };
            });
        }
    }

    const getPlaceholder=(type)=>
    {
        switch(type)
        {
            case "phone_number":
                return "Phone Number"
            case "location_request":
                return "Location Request"
            case "quick_reply":
                return "Quick Reply"
            case "url":
                return "URL"
            case "copy_code":
                return "copy code"
        }
    }

    const handleButtonParamChange = (e, paramIndex = 0) => {
        const { value } = e.target;
        setError(prev=>({...prev,buttonParamError:null}))
        setError(prev=>({...prev,urlParamError:null}))
        setFormState(prev => {
            const updatedButtons = prev.content.buttons.map((button, bIndex) => {
                if (bIndex === index) {
                    const updatedParams = button.params.map((param, pIndex) => {
                        if (pIndex === paramIndex) {
                            return { ...param, example: value.trim() };
                        }
                        return param;
                    });
                    const actual_text=replaceVariables("button", button.url, updatedParams)
                    if(!validator.isURL(actual_text))
                    {
                        setError(prev=>({...prev,urlParamError:{error:"Enter valid url parameter.",fieldIndex:index}}))
                    }
                    
                    return {
                        ...button,
                        actual_text:actual_text ,
                        params: updatedParams
                    };
                }
                return button;
            });
    
            return {
                ...prev,
                content: {
                    ...prev.content,
                    buttons: updatedButtons
                }
            };
        });
    };
    
    const handleDeleteButton=()=>
    {
        setFormState(prev=>
        {
            const updated=prev.content.buttons.filter((button,buttonIndex)=>buttonIndex!==index)
            
            setError(prevError => {
                if (prevError) {
                    return {
                        ...prevError,
                        buttonLabelError: null,
                        buttonParamError: null,
                        buttonVariableError: null,
                        buttonValueError: null,
                        buttonUrlError: null,
                        urlParamError: null
                    };
                }
                return prevError;
            });

            return {
                ...prev,
                content:
                {
                    ...prev.content,
                    buttons:updated
                }
            }
        })
    }

    return (
        <div className='form-button-content'>
            <div style={{display:"grid",placeItems:"center"}}>
                <label>&nbsp;</label>
                <img src={deleteIcon} width={"24px"} height={"24px"} className='icon' onClick={handleDeleteButton}/>
                {<label>&nbsp;</label>}
            </div>
            <div className='button-input'>
                <label>&nbsp; </label>
                <select
                    name='buttons'
                    value={button.type}
                    onChange={(e) => handleDropDownChange(e)}
                    className="input"
                    style={{ width: "150px" }}
                >
                    <option value="url">URL</option>
                    <option value="phone_number">Phone Number</option>
                    <option value="copy_code">Copy Code</option>
                    <option value="quick_reply">Quick Reply</option>
                    <option value="location_request">Location Request</option>
                </select>
            </div>
            
            <div style={{display:"flex",flexDirection:"column"}}>
                <div className='button-input'>
                    <label>
                        Label
                    </label>
                    <input
                    type="text"
                    autoComplete='off'
                    name={button.type+"_label"}
                    value={button.text}
                    maxLength={25}
                    onChange={handleLabelChange}
                    className={`input${(error?.buttonLabelError && error.buttonLabelError.missingLabel === index) ? " error-input" : ""}`}
                    style={{width:"100%"}}
                    />
                </div>
                <div className='error' 
                style={{visibility: (error?.buttonLabelError && error.buttonLabelError.missingLabel === index) ? "visible" : "hidden"}}>
                *{error?.buttonLabelError?.error}
            </div>
            </div>

            <div style={{display:"flex",flexDirection:"column"}}> 
                <div className='button-input'>
                    {["phone_number","url","copy_code"].includes(button.type) && <label>{getPlaceholder(button.type)}</label>}
                {["phone_number","url"].includes(button.type) && 
                <>
                    <div>
                    <input
                    type={button.type==="url" ? "url" : "tel"}
                    autoComplete='off'
                    maxLength={button.type === "phone_number" ? 20 : 2000}
                    name={button.type+"_example"}
                    value={button[button.type] || ""}
                    onChange={handleInputValueChange}
                    className={`input${((error.buttonUrlError?.error && error.buttonUrlError?.fieldIndex===index) || (error.buttonVariableError?.error && error.buttonVariableError?.fieldIndex===index)) ? " error-input" : ""}`}
                    style={{width:"100%"}}
                    />
                    </div>
                    {button.type === "url" && (
                    <div className='error'>
                        {error.buttonVariableError?.error && error.buttonVariableError?.fieldIndex === index
                        ? `*${error.buttonVariableError.error}`
                        : error.buttonUrlError?.error && error.buttonUrlError?.fieldIndex === index
                        ? `*${error.buttonUrlError.error}`
                        : null}
                    </div>
                    )}


                    </>
                    }

                    {["copy_code"].includes(button.type) &&
                    <input
                    type={"text"}
                    autoComplete='off'
                    maxLength={15}
                    name={button.type+"_example"}
                    value={button['example_code']}
                    onChange={handleInputValueChange}
                    className="input"
                    style={{width:"100%"}}
                    />
                    }

                    {["quick_reply","location_request"].includes(button.type) &&
                    <div style={{width:"170px"}}>
                        
                    </div>
                    }
                </div>
                <div className='error' 
                    style={{visibility: (error?.buttonValueError && error.buttonValueError.missingField === index) ? "visible" : "hidden"}}>
                    *{error?.buttonValueError?.error}
                </div> 
            
            </div>

            {button.type === "url" && button.params?.length !== 0 && (
                <div>
                    {button.params?.map((param, paramIndex) => (
                        <div key={paramIndex}>
                        <div className='button-variable-content' >
                            <label>{param.name}</label>
                            <input
                                value={param.example}
                                autoComplete='off'
                                onChange={(e) => handleButtonParamChange(e, paramIndex)}
                                required
                            />
                        </div>
                        <div className='error' 
                            style={{visibility: (error?.buttonParamError?.missingField === index || (error?.urlParamError?.error && error?.urlParamError?.fieldIndex===index)) ? "visible" : "hidden"}}>
                            *{(error?.buttonParamError?.missingField === index && error?.buttonParamError?.error) || (error?.urlParamError?.fieldIndex===index && error?.urlParamError?.error)}
                        </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default TemplateFormButton;