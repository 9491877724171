import React from "react";
import tick from "../../assets/home assets/tick.svg";

const HomeHeader = ({ step, userType,loading }) => {
  return (
    <>
      {!loading && step !== 0 && userType === "Administrator" && (
        <div className="home-header">
          <div className="tracker">
            <div className="stage-container">
              <div className={`stage ${step > 1 ? "completed" : ""}`}>
                {step > 1 ? (
                  <img src={tick} alt="Tick" width="16px" height="16px" />
                ) : (
                  "1"
                )}
              </div>
              <div className="stage-label">Connect</div>
            </div>
            <div className="tracking-line-container">
              <div
                className={`tracking-line ${
                  step > 1 ? "completed" : "incompleted"
                }`}
              ></div>
            </div>
            <div className="stage-container">
              <div className={`stage ${step >= 2 ? "" : "incompleted"}`}>2</div>
              <div className={`stage-label ${step >= 2 ? "" : "incompleted"}`}>
                Activate
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomeHeader;
