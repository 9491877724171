import React, { useEffect, useState } from "react";
import Spinner from "../../../page loader/Spinner";
import downloadIcon from "../../../assets/chat assets/download.svg";
import TextChat from "./TextChat";

const VideoChat = ({ content, blob, handlePreview, loading }) => {
  const [videoContent, setVideoContent] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    if (blob) {
      const objectURL = URL.createObjectURL(blob);
      const fileType = blob.type;

      if (fileType.startsWith("video/")) {
        generateThumbnail(objectURL);
      }

      // Clean up the object URL
      return () => URL.revokeObjectURL(objectURL);
    }
  }, [blob]);

  const generateThumbnail = (videoUrl) => {
    const video = document.createElement("video");
    video.src = videoUrl;
    video.muted = true;
    video.playsInline = true;

    video.addEventListener("loadeddata", () => {
      video.currentTime = 0;
    });

    video.addEventListener("seeked", () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const thumbnailUrl = canvas.toDataURL("image/png");
      setThumbnail(thumbnailUrl);
      setVideoContent(
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <img
            src={thumbnailUrl}
            alt="thumbnail"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <div className="chat-video-play-button-div">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 5V19L19 12L8 5Z" fill="white" />
            </svg>
          </div>
        </div>
      );
    });

    video.addEventListener("error", () => {
      console.error("Error loading video");
    });
  };

  const handleClick = () => {
    if (blob === null) {
      handlePreview(false, null);
    } else {
      handlePreview(true, blob);
    }
  };

  return (
    <>
      <div className="chat-video-container" onClick={handleClick}>
        <div className="chat-video-cover-div ">
        {!loading && !blob && (
              <div className="video-file-download-icon">
                <img src={downloadIcon} />
              </div>
            )}
          {loading && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Spinner size="30px" />
            </div>
          )}
          {!loading && videoContent}
        </div>
      </div>
    </>
  );
};

export default VideoChat;
