import React from "react";
import finalstep from "../../assets/home assets/finalstep.jpg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Activation3rdstep = ({ channel }) => 
{
  const navigate = useNavigate();
  const waba_id=useSelector(state=>state.channel.waba_id)
  const gotochatfn = () => {
    navigate("/chats");
  };

  return (
    <>
      <div className="facebook-login-steps">
        <div className="facebook-login-header">
          <p className="facebook-login-title">
            Your WhatsApp Business Phone Number is now active.
          </p>
        </div>

        <div className="facebook-login-final-main-box">
          <div className="facebook-login-step3-image">
            <img src={finalstep} alt="login" />
          </div>

          <div className="login-final-step-instructions">
            <div className="display-name-box">
              <div className="display-name-box-name-1">Display Name</div>
              <div className="display-name-box-name-2">{channel?.label}</div>
            </div>

            <div className="login-display-number-box">
              <div className="display-name-box-name-1">Phone Number</div>
              <div className="display-name-box-name-2">{channel?.handle}</div>
            </div>

            <div className="login-display-complete-content">
              Test your connection by sending a message from your personal
              WhatsApp app to the above number and reply from here.
            </div>
          </div>
        </div>

        <div className="facebook-login-final-points-note-box">
          <div className="point-bold">Points to note</div>
          <div>
            1) Ensure that you have set up a payment method in your WhatsApp
            Business Account for uninterrupted usage.
          </div>
          <div>
            2) You can send upto 250 messages in a rolling 24 hour window, if
            your business is not yet verified.
          </div>
          <div>
            3) To send an outgoing message to an new phone number, add the
            number under “Contacts” and send a message.
          </div>
        </div>

        <div className="activation-button-container">
        <div className="facebook-login-final-button-box">
          <button className="facebook-login-final-button" onClick={gotochatfn}>
            <div>GO TO CHATS</div>
          </button>
        </div>
        <div >
          <button className="whatsapp-manager-button">
              <a href={`https://business.facebook.com/wa/manage/phone-numbers?waba_id=${waba_id}`} target="_blank">OPEN WHATSAPP MANAGER</a>
            </button>
        </div>
        </div>
          
      </div>
    </>
  );
};

export default Activation3rdstep;
