import React from 'react'

const TemplatePreviewButton = ({button}) => 
{
  return (
    <div className='template-preview-button'>
      {button.text}
    </div>
  )
}

export default TemplatePreviewButton
