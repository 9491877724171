// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import PageLoader from '../../../page loader/PageLoader';
// import { blobToBase64 } from '../../../cache/cacheUtils';

// export const fetchChats = createAsyncThunk('chat/fetchChats', (_, { getState }) =>
// {
//   PageLoader.start();
//   const { chatList } = getState().chat;
//   if (chatList && chatList.length !== 0)
//   {
//     PageLoader.stop();
//     return Promise.resolve(chatList);
//   }
//   return fetch('/server/chats')
//     .then(response =>
//     {
//       PageLoader.stop();
//       if (response.status === 204)
//       {
//         throw new Error("No Chats found");
//       }
//       return response.json();
//     })
//     .then(data =>
//     {
//       return  data.chats
//     })
//     .catch(error =>
//     {
//       PageLoader.stop();
//       throw error;
//     });
// });

// export const fetchFilteredChats = createAsyncThunk('chat/fetchFilteredChats', ({query,unread}, { getState }) =>
//   {
//     if(query==="all_chats" && unread===false)
//     {
//       const {chatList}=getState().chat
//       return Promise.resolve(chatList)
//     }
//     else
//     {
//       PageLoader.start();
//       return fetch(`/server/chats?view=${query}&unread=${unread}`)
//         .then(response =>
//         {
//           PageLoader.stop();
//           if (response.status === 204)
//           {
//             throw new Error("No Chats found");
//           }
//           return response.json();
//         })
//         .then(data =>
//         {
//           return  data.chats
//         })
//         .catch(error =>
//         {
//           PageLoader.stop();
//           throw error;
//         });
//     }
//   });

// export const fetchMessages = createAsyncThunk('chat/fetchMessages', ({chatId,refresh=false}, { getState }) =>
// {
//   const { chat } = getState();
//   const chatData = chat.chatList.find(c => c.id === chatId);
//   if (!refresh && chatData && chatData.messages)
//   {
//     return Promise.resolve({ chatId, messages: chatData.messages });
//   }
//   return fetch(`/server/chats/${chatId}/messages`)
//     .then(response =>
//     {
//       if (response.status === 204)
//       {
//         return { chatId, messages: [] };
//       }
//       return response.json();
//     })
//     .then(data =>
//     {
//       const messages=data.messages.map(item=>
//       {
//         // item.isDownloading=false
//         return item
//       })
//       return { chatId, messages:messages }
//     })
//     .catch(error =>
//     {
//       throw error;
//     });
// });

// export const updateChatStatus = createAsyncThunk('chat/updateChatStatus', ({ chatId, status }) =>
// {
//   PageLoader.start();
//   return fetch(`/server/chats/${chatId}`,
//     {
//       method: 'PUT',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ state: status }),
//     })
//     .then(response =>
//     {
//       PageLoader.stop();
//       if (!response.ok)
//       {
//         throw new Error(response.statusText);
//       }
//       return response.json();
//     })
//     .then(() =>
//     {
//       PageLoader.stop();
//       return { chatId, status };
//     })
//     .catch(error =>
//     {
//       PageLoader.stop();
//       throw error;
//     });
// });

// export const sendMessage = createAsyncThunk('chat/sendMessage', (messageBody,{getState}) =>
// {
//   const {chat,content_type}=messageBody.message
//   const chatId=chat.id
//   if(content_type==="text" || content_type==="location" || content_type==="location_request" || content_type==="template")
//   {
//     const reqBody=
//     {
//       content_type:content_type,
//       content:messageBody.message.content
//     }
//     return fetch(`/server/chats/${chatId}/messages`,
//     {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(reqBody),
//     })
//     .then(response =>
//     {
//       if (!response.ok)
//       {
//         throw new Error(response.statusText);
//       }
//       return response.json();
//     })
//     .then(data => {
//       return { chatId:chatId, message: data };
//     })
//     .catch(error => {
//       throw error;
//     });
//   }
//   else if(["file","audio","sticker"].includes(content_type))
//   {
//       return fetch(`/server/chats/${chatId}/media/`,
//         {
//           method:"POST",
//           body:messageBody.formData
//         })
//         .then(res=>
//         {
//           if(!res.ok)
//           {
//             throw Error("Error inserting file: ",res.statusText)
//           }
//           else
//           {
//             return res.json()
//           }
//         })
//       .then(response=>
//       {
//         const message = messageBody.message;

//         // Create a new content object with the existing properties and add the id
//         const newContent = {
//           ...message.content[content_type],
//           id: response.id
//         };

//         // Create a new message object with the updated content
//         const newMessage = {
//           ...message,
//           content: {
//             ...message.content,
//             [content_type]: newContent
//           }
//         };

//         const reqBody = {
//           content_type: content_type,
//           content: newMessage.content
//         };

//         return fetch(`/server/chats/${chatId}/messages`,
//           {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify(reqBody),
//           })
//       })
//       .then(response =>
//       {
//         if (!response.ok)
//         {
//           throw new Error(response.statusText);
//         }
//         return response.json();
//       })
//       .then(data => {
//         return { chatId, message: data };
//       })
//       .catch(error => {
//         throw error;
//       });
//     }
// });

// export const fetchStarredMessages = createAsyncThunk('chat/fetchStarredMessages', (chatId, { getState }) =>
// {
//   const { chat } = getState();
//   const chatData = chat.chatList.find(c => c.id === chatId);
//   if (chatData && chatData.starredMessages)
//   {
//     return Promise.resolve({ chatId, messages: chatData.starredMessages });
//   }
//   return fetch(`/server/chats/${chatId}/messages?type=starred`)
//     .then(response =>
//     {
//       if (response.status === 204)
//       {
//         return { chatId, messages: [] };
//       }
//       return response.json();
//     })
//     .then(data => ({ chatId, messages: data.messages }))
//     .catch(error =>
//     {
//       throw error;
//     });
// });

// export const fetchFilesInChat = createAsyncThunk('chat/fetchFilesInChat', (chatId, { getState }) =>
// {
//   const { chat } = getState();
//   const chatData = chat.chatList.find(c => c.id === chatId);
//   if (chatData && chatData.filesInChat)
//   {
//     return Promise.resolve({ chatId, messages: chatData.filesInChat });
//   }
//   return fetch(`/server/chats/${chatId}/messages?type=files`)
//     .then(response =>
//     {
//       if (response.status === 204)
//       {
//         return { chatId, messages: [] };
//       }
//       return response.json();
//     })
//     .then(data => ({ chatId, messages: data.messages }))
//     .catch(error =>
//     {
//       throw error;
//     });
// });

// export const updateStarred = createAsyncThunk('chat/updateStarred', ({chatId,messageId,starred}, { getState }) =>
// {
//   PageLoader.start()
//   return fetch(`/server/chats/${chatId}/messages/${messageId}`,{method:"PUT",headers:{ "Content-Type":"Application/json"},body:JSON.stringify({starred:starred})})
//     .then(response =>
//     {
//       PageLoader.stop()
//       return response.json();
//     })
//     .then(data =>
//     {
//       return { chatId,messageId, starred  }
//     })
//     .catch(error =>
//     {
//       PageLoader.stop()
//       throw error;
//     });
// });

// export const fetchMedia = createAsyncThunk('chat/fetchMedia', ({ chatId, messageId }, { getState }) =>
// {
//   const { chat } = getState();
//   const base64 = chat.mediaBlobs[messageId];
//   if (base64)
//   {
//     return Promise.resolve({ id: messageId,chatId:chatId, data: base64 });
//   }
//   return fetch(`/server/chats/${chatId}/messages/${messageId}/media`)
//     .then(response =>
//     {
//       if (!response.ok)
//       {
//         throw new Error('Network response was not ok ' + response.statusText);
//       }
//       return response.blob();
//     })
//     .then(blob => blobToBase64(blob))
//     .then(base64 => ({ id: messageId,chatId:chatId, data: base64 }))
//     .catch(error =>
//     {
//       throw error;
//     });
// });

// export const updateReadStatus = createAsyncThunk('chat/updateRead', ({chatId,read_time,lastReadMessageId},{getState}) => {
//   let readStatus=true;
//   return fetch(`/server/chats/${chatId}`,{method:"PUT",headers:{ "Content-Type":"Application/json"},body:JSON.stringify({read:readStatus,last_read_message_id:lastReadMessageId})})
//     .then(response =>
//     {
//       return response.json();
//     })
//     .then(data =>
//     {
//       return { chatId,read_time,lastReadMessageId }
//     })
//     .catch(error =>
//     {
//       throw error;
//     });
// });

// export const updateAssignee=createAsyncThunk('chat/updateAssignee',({chatId,assigneeId},{getState})=>
// {
//   PageLoader.start()
//     return fetch(`/server/chats/${chatId}`,{method:"PUT",headers:{ "Content-Type":"Application/json"},body:JSON.stringify({owner_id:assigneeId})})
//       .then(response=>
//       {
//         PageLoader.stop()
//         if(!response.ok)
//         {
//           throw response.statusText
//         }
//         return response.json()
//       })
//       .then(res=>
//       {
//         return {chatId,assigneeId}
//       })
//       .catch(err=>
//       {
//         PageLoader.stop()
//         throw err
//       })
// })

// export const fetchPreview = createAsyncThunk('chat/preview',({ chatId,messageId,url},{getState})=>
// {
//     const { previews } = getState().chat;
//     const preview=previews[messageId]
//     if(preview)
//     {
//       return Promise.resolve(preview)
//     }

//     return fetch(`https://test-840896869.development.catalystserverless.com/server/link-preview/preview?url=${encodeURIComponent(url)}`,{method:"POST"})
//     .then(response=>
//     {
//       if (response.ok)
//       {
//         return response.json();
//       }
//       else
//       {
//         return { messageId:messageId,url:url,data:{}}
//       }
//     })
//     .then(data=>
//     {
//       return { url:url, messageId:messageId , data:data}
//     })
//     .catch(error=>
//     {
//       throw error
//     })
// })

// const chatSlice = createSlice({
//   name: 'chat',
//   initialState:
//   {
//     chatList: [],
//     messages:{},
//     starredMessages:{},
//     filesInChat:{},
//     starredChanges:false,
//     mediaBlobs: {},
//     previews:{},
//     pendingUpdate:[],
//     loading:{},
//     currentInputChat:{},
//     currentMediaChat:{},
//     views:[],
//     currentChat: null,
//     fetchChatsLoading: false,
//     fetchMessagesLoading: false,
//     fetchStarredMessagesLoading:false,
//     fetchFilesInChatLoading:false,
//     fetchMediaLoading:false,
//     sendMessageLoading:false,
//     updateChatStatusLoading: false,
//     updateAssigneeLoading:false,
//     error: null,
//   },
//   reducers:
//   {
//     postChat:(state,action)=>
//     {
//       state.chatList.push(action.payload)
//     },
//     updateContactInChat: (state, action) => {
//       const { id, contact } = action.payload;

//       // Store the chat list and current chat in variables
//       const chatList = state.chatList.map(chat => {
//         if (chat.contact.id === id) {
//           // Update the contact in the found chat
//           return {
//             ...chat,
//             contact: { ...chat.contact, ...contact }
//           };
//         }
//         return chat;
//       });

//       // Check if the current chat needs to be updated
//       let currentChat = state.currentChat;
//       if (currentChat && currentChat.contact.id === id) {
//         currentChat = {
//           ...currentChat,
//           contact: { ...currentChat.contact, ...contact }
//         };
//       }

//       // Assign the updated variables back to the state
//       state.chatList = chatList;
//       state.currentChat = currentChat;
//     },

//     deleteChat: (state, action) => {
//       state.chatList = state.chatList.filter(chat => chat.contact.id !== action.payload);
//       if (state.currentChat && state.currentChat.contact.id === action.payload) {
//           state.currentChat = null;
//       }
//   },

//     addNewMessage:(state,action)=>
//     {
//       const { chatId ,messages }= action.payload
//       const chat=state.chatList.find(chat=>chat.id===chatId)
//       // const currentChatId=state.currentChat.id
//       if(chat)
//       {
//         // if(currentChatId!==chatId)
//         // {
//           chat.unread_count=parseInt(chat.unread_count)+parseInt(messages.length)
//           if(state.currentChat)
//           {
//             state.currentChat.unread_count=parseInt(chat.unread_count)+parseInt(messages.length)
//           }
//         // }
//         if(messages.length!==0 && chat.messages && chat.messages.length!==0)
//         {
//           chat.messages=[...chat.messages,...messages]
//         }
//       }
//     },
//     updateOutgoingStatus:(state,action)=>
//     {
//       const { chatId,messages }=action.payload
//       const chat=state.chatList.find(chat=>chat.id===chatId)
//       if(chat)
//       {
//         if(messages.length!==0 && chat.messages && chat.messages.length!==0)
//         {
//           messages.forEach(message=>
//           {
//             chat.messages.forEach((chatMessage,index)=>
//             {
//               if(chatMessage.id===message.id)
//               {
//                 chat.messages[index]={...chat.messages[index],...message}
//               }
//             })
//           })
//         }
//       }

//     },
//     setCurrentChat: (state, action) =>
//     {
//       state.currentChat = action.payload;
//     },
//     updateCurrentInputChat: (state, action) =>
//     {
//       const { chatId, message,type } = action.payload;
//       state.currentInputChat[chatId]=
//       {
//         type:type,
//         text:message
//       }
//     },
//     updateCurrentMediaChat: (state, action) =>
//     {
//       const { chatId, file } = action.payload;
//       state.currentMediaChat[chatId]=file
//     },
//     updateReadTime: (state, action) =>
//     {
//       const {read_time,chatId,lastReadMessageId} = state.pendingUpdate[0];
//       const chatIndex = state.chatList.findIndex(chat => chat.id === chatId);

//       if (chatIndex !== -1)
//       {
//         // state.chatList[chatIndex].last_read_message_id=last_read_message_id
//         state.chatList[chatIndex].unread_count=0
//         state.currentChat.unread_count=0
//         const updatedMessages = state.chatList[chatIndex]?.messages.map(chat =>
//         {
//           if (chat.origin === 'incoming' && chat.read_time === null)
//           {
//             return { ...chat, read_time: read_time };
//           }
//           return chat;
//         });

//         state.chatList[chatIndex] =
//         {
//           ...state.chatList[chatIndex],
//           messages: updatedMessages,
//         };

//         state.pendingUpdate=[]
//       }
//     },
//     updateMediaBlobs: (state,action)=>
//     {
//       const {messageId,blob}=action.payload
//       if (!messageId || !blob) {
//         return;
//       }

//       state.mediaBlobs = {
//         ...state.mediaBlobs,
//         [messageId]: blob
//       };
//     }
//   },

//   extraReducers: (builder) =>
//   {
//     builder
//       // Fetch Chats
//       .addCase(fetchChats.pending, (state) =>
//       {
//         state.fetchChatsLoading = true;
//       })
//       .addCase(fetchChats.fulfilled, (state, action) =>
//       {
//         state.fetchChatsLoading = false;
//         state.chatList = action.payload;
//       })
//       .addCase(fetchChats.rejected, (state, action) =>
//       {
//         state.fetchChatsLoading = false;
//         state.chatList = [];
//         state.error = action.error.message;
//       })
//       // Fetch filtered chats
//       .addCase(fetchFilteredChats.pending, (state,action)=>
//       {
//          //nothing to do
//       })
//       .addCase(fetchFilteredChats.fulfilled, (state,action)=>
//       {
//         const filteredChats=action.payload
//         // update the state of chatList array items filteredChats items which  are present in filteredChats
//         state.chatList = state.chatList.map(chat => {
//           const matchedChat = filteredChats.find(filteredChat => filteredChat.id === chat.id);
//           return matchedChat ? { ...chat, ...matchedChat } : chat;
//         });
//         const currentChat=state.currentChat
//         if(currentChat!==null)
//         {
//           const isCurrentChatInFilteredChat=filteredChats.findIndex(filteredChat=>filteredChat.id===currentChat.id)
//           if(isCurrentChatInFilteredChat===-1)
//           {
//             state.currentChat=null
//           }
//         }

//         state.views=filteredChats
//       })
//       .addCase(fetchFilteredChats.rejected, (state,action)=>
//       {
//         state.views=[]
//         state.error=action.error
//       })
//       // Fetch Messages
//       .addCase(fetchMessages.pending, (state) =>
//       {
//
//         state.fetchMessagesLoading = true;
//       })
//       .addCase(fetchMessages.fulfilled, (state, action) =>
//       {
//         state.fetchMessagesLoading = false;
//         const { chatId, messages } = action.payload;
//         const chat = state.chatList.find(c => c.id === chatId);
//         if (chat)
//         {
//           chat.messages = messages;
//         }
//       })
//       .addCase(fetchMessages.rejected, (state, action) =>
//       {
//         state.fetchMessagesLoading = false;
//         state.error = action.error.message;
//       })
//       // // Fetch templates
//       // .addCase(fetchTemplates.pending,(state,action)=>
//       // {
//       //   state.templatesLoading=true
//       // })
//       // .addCase(fetchTemplates.fulfilled,(state,action)=>
//       // {
//       //   state.templatesLoading=false
//       //   const existingTemplates=state.templates
//       //   const newTemplates=action.payload
//       //   state.templates=[...newTemplates]
//       // })
//       // .addCase(fetchTemplates.rejected,(state,action)=>
//       // {
//       //   state.templatesLoading=false
//       //   state.error=action.error
//       // })
//       // Update Chat Status
//       .addCase(updateChatStatus.pending, (state) =>
//       {
//         state.updateChatStatusLoading = true;
//       })
//       .addCase(updateChatStatus.fulfilled, (state, action) =>
//       {
//         state.updateChatStatusLoading = false;
//         const { chatId, status } = action.payload;
//         const chat = state.chatList.find(c => c.id === chatId);
//         if (chat)
//         {
//           chat.state = status;
//           if(state.currentChat!==null)
//           {
//             state.currentChat.state=status
//           }
//         }

//       })
//       .addCase(updateChatStatus.rejected, (state, action) =>
//       {
//         state.updateChatStatusLoading = false;
//         state.error = action.error.message;
//       })
//       // Send Message
//       .addCase(sendMessage.pending, (state,action) =>
//       {
//
//         const messageBody=action.meta.arg.message
//         const chat = state.chatList.find(c => c.id === messageBody.chat.id);
//         if (chat)
//         {
//           delete state.currentInputChat[chat.id]
//           delete state.currentMediaChat[chat.id]
//           chat.messages = chat.messages || [];
//           chat.messages.push(messageBody);
//         }
//       })
//       .addCase(sendMessage.fulfilled, (state, action) =>
//       {
//         state.sendMessageLoading = false;
//         const tempId=action.meta.arg.message.id
//         const metaData = action.meta.arg.metaData;
//         const { chatId, message } = action.payload;

//         const chat = state.chatList.find(c => c.id === chatId);
//         if (chat)
//         {
//           chat.messages = chat.messages || [];
//           // Find the pending message and replace it
//           const pendingIndex = chat.messages.findIndex(m => m.id ===tempId);
//           if (pendingIndex !== -1)
//           {
//             if(metaData)
//             {
//               const {size,mime_type}=metaData
//               if(size)
//               {
//                 message.content[message.content_type].size=size
//               }

//               if(mime_type)
//               {
//                 message.content[message.content_type].mime_type=mime_type
//               }
//             }
//             chat.messages[pendingIndex] = message;

//             if(message.content_type==="file" && chat.filesInChat)
//             {
//               chat.filesInChat.push(message)
//             }
//           }
//         }
//         if (state.mediaBlobs[tempId])
//         {
//           state.mediaBlobs[message.id] = state.mediaBlobs[tempId];
//           delete state.mediaBlobs[tempId];
//         }
//       })
//       .addCase(sendMessage.rejected, (state, action) =>
//       {
//         const messageBody = action.meta.arg.message;
//         const chatId=messageBody.chat.id
//         const tempId=messageBody.id
//         const chat = state.chatList.find(c => c.id === chatId);
//         if (chat)
//         {
//           chat.messages = chat.messages || [];
//           // Mark the pending message as failed
//           const pendingIndex = chat.messages.findIndex(m => m.id === tempId);
//           if (pendingIndex !== -1)
//           {
//             chat.messages[pendingIndex].status = "failed";
//           }
//         }

//         state.error = action.error.message;
//       })
//       // Fetch Starred Messages
//       .addCase(fetchStarredMessages.pending, (state) =>
//       {
//         state.fetchStarredMessagesLoading = true;
//       })
//       .addCase(fetchStarredMessages.fulfilled, (state, action) =>
//       {
//         state.fetchStarredMessagesLoading=false
//         const { chatId, messages } = action.payload;
//         const chat = state.chatList.find(c => c.id === chatId);
//         if (chat)
//         {
//           chat.starredMessages = messages;
//         }
//       })
//       .addCase(fetchStarredMessages.rejected, (state, action) =>
//       {
//         state.fetchStarredMessagesLoading=false
//         state.error = action.error.message;
//       })
//       // Fetch Files in Chat
//       .addCase(fetchFilesInChat.pending, (state) =>
//       {
//         state.fetchFilesInChatLoading = true;
//       })
//       .addCase(fetchFilesInChat.fulfilled, (state, action) =>
//       {
//         state.fetchFilesInChatLoading=false
//         const { chatId, messages } = action.payload;
//         const chat = state.chatList.find(c => c.id === chatId);
//         if (chat)
//         {
//           chat.filesInChat = messages;
//         }
//       })
//       .addCase(fetchFilesInChat.rejected, (state, action) =>
//       {
//         state.fetchFilesInChatLoading=false
//         state.error = action.error.message;
//       })
//       // Fetch Media
//       .addCase(fetchMedia.pending, (state, action) =>
//       {
//         const { messageId } = action.meta.arg;
//         state.loading[messageId]=true
//       })
//       .addCase(fetchMedia.fulfilled, (state, action) =>
//       {
//         state.fetchMediaLoading = false;
//         const { id, data } = action.payload;
//         state.mediaBlobs[id] = data;
//           state.loading[id]=false
//       })
//       .addCase(fetchMedia.rejected, (state, action) =>
//       {
//         const { messageId } = action.meta.arg;
//         state.loading[messageId]=false
//         state.error = action.error.message;
//       })
//       // update starred
//       .addCase(updateStarred.pending, (state,action)=>
//       {
//         state.starredChanges=true
//         const { chatId, messageId } = action.meta.arg;
//         const chat = state.chatList.find(c => c.id === chatId);
//         if (chat)
//         {
//           const message = chat.messages.find(m => m.id === messageId);
//           if (message)
//           {
//             const starred=!message.starred;
//             message.starred = starred
//             if(starred)
//             {
//               if(chat.starredMessages)
//               {
//                 chat.starredMessages.push(message)
//               }
//             }
//             else
//             {
//               if(chat.starredMessages)
//               {
//                 chat.starredMessages=chat.starredMessages.filter(message=>message.id!==messageId)
//               }
//             }
//           }
//         }
//       })
//       .addCase(updateStarred.fulfilled,(state,action)=>
//       {
//         //do nothing as all are done in pending
//       })
//       .addCase(updateStarred.rejected, (state, action) =>
//       {
//         const { chatId, messageId } = action.meta.arg;
//         const chat = state.chatList.find(c => c.id === chatId);
//         if (chat)
//         {
//           const message = chat.messages.find(m => m.id === messageId);
//           if (message)
//           {
//             const starred = !message.starred;
//             message.starred = starred;
//             if (starred)
//             {
//               if (chat.starredMessages)
//               {
//                 chat.starredMessages.push(message);
//               }
//               else
//               {
//                 chat.starredMessages = [message];
//               }
//             }
//             else
//             {
//               chat.starredMessages = chat.starredMessages.filter(m => m.id !== messageId);
//             }
//           }
//         }
//         state.error=action.error
//       })
//       //update read status
//       .addCase(updateReadStatus.fulfilled,(state,action)=>
//       {
//         const {chatId,read_time,lastReadMessageId}=action.payload
//         state.pendingUpdate=[...state.pendingUpdate,{ chatId,read_time,lastReadMessageId}]
//         const chat=state.chatList.find(chat=>chat.id===chatId)
//         if(chat)
//         {
//           chat.unread_count=0;
//           state.currentChat.unread_count=0
//           chat.last_read_message_id=lastReadMessageId
//         }
//       })
//       .addCase(updateReadStatus.rejected,(state,action)=>
//       {
//         state.error=action.error
//       })
//       //update Assignee
//       .addCase(updateAssignee.pending,(state)=>
//       {
//         state.updateAssigneeLoading=true
//       })
//       .addCase(updateAssignee.fulfilled,(state,action)=>
//       {
//         state.updateAssigneeLoading=false
//         const { chatId,assigneeId}=action.payload
//         const index=state.chatList.findIndex(c=>c.id===chatId)
//         state.chatList[index].owner_id=assigneeId
//         state.currentChat.owner_id=assigneeId
//       })
//       .addCase(updateAssignee.rejected,(state,action)=>
//       {
//         state.updateAssigneeLoading=false
//         state.error=action.error
//       })
//       //fetch preview
//       .addCase(fetchPreview.pending, (state, action) =>
//       {
//         const { messageId } = action.meta.arg;
//         // if (chat)
//         // {
//         //   const message = chat.messages.find(m => m.id === messageId);
//         //   if (message)
//         //   {
//         //     message.isDownloading = true;

//         //   }
//         // }
//         state.loading[messageId]=true
//       })
//       .addCase(fetchPreview.fulfilled, (state, action) =>
//       {
//         const { url, messageId, data } = action.payload;
//         state.loading[messageId]=false
//         state.previews[messageId] = { url:url,data:data};
//       })
//       .addCase(fetchPreview.rejected, (state, action) =>
//       {
//         const { messageId } = action.meta.arg;
//         state.loading[messageId]=false
//         state.error = action.error.message;
//       })
//   },
// });

// export const { setCurrentChat,updateCurrentInputChat,updateReadTime,updateCurrentMediaChat,updateMediaBlobs,postChat,addNewMessage,updateOutgoingStatus,deleteChat,updateContactInChat } = chatSlice.actions;
// export default chatSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PageLoader from "../../../page loader/PageLoader";
import { blobToBase64 } from "../../../cache/cacheUtils";

export const fetchChats = createAsyncThunk(
  "chat/fetchChats",
  (_, { getState }) => {
    PageLoader.start();
    const { chatList } = getState().chat;
    if (chatList && chatList.length !== 0) {
      PageLoader.stop();
      return Promise.resolve(chatList);
    }
    return fetch("/server/chats")
      .then((response) => {
        PageLoader.stop();
        if (response.status === 204) {
          throw new Error("No Chats found");
        }
        return response.json();
      })
      .then((data) => {
        return data.chats;
      })
      .catch((error) => {
        PageLoader.stop();
        throw error;
      });
  }
);

export const fetchFilteredChats = createAsyncThunk(
  "chat/fetchFilteredChats",
  ({ query, unread }, { getState }) => {
    if (query === "all_chats" && unread === false) {
      const { chatList } = getState().chat;
      return Promise.resolve(chatList);
    } else {
      PageLoader.start();
      return fetch(`/server/chats?view=${query}&unread=${unread}`)
        .then((response) => {
          PageLoader.stop();
          if (response.status === 204) {
            throw new Error("No Chats found");
          }
          return response.json();
        })
        .then((data) => {
          return data.chats;
        })
        .catch((error) => {
          PageLoader.stop();
          throw error;
        });
    }
  }
);

export const fetchMessages = createAsyncThunk(
  "chat/fetchMessages",
  ({ chatId, refresh = false }, { getState }) => {
    const { chat } = getState();
    const messages = chat.messages[chatId];
    if (!refresh && messages) {
      return Promise.resolve({ chatId, messages: messages });
    }
    return fetch(`/server/chats/${chatId}/messages`)
      .then((response) => {
        if (response.status === 204) {
          return { chatId, messages: [] };
        }
        return response.json();
      })
      .then((data) => {
        const messages = data.messages.map((item) => {
          // item.isDownloading=false
          return item;
        });
        return { chatId, messages: messages };
      })
      .catch((error) => {
        throw error;
      });
  }
);

export const updateChatStatus = createAsyncThunk(
  "chat/updateChatStatus",
  ({ chatId, status }) => {
    PageLoader.start();
    return fetch(`/server/chats/${chatId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ state: status }),
    })
      .then((response) => {
        PageLoader.stop();
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(() => {
        PageLoader.stop();
        return { chatId, status };
      })
      .catch((error) => {
        PageLoader.stop();
        throw error;
      });
  }
);

export const sendMessage = createAsyncThunk(
  "chat/sendMessage",
  (messageBody, { getState }) => {
    const { chat, content_type } = messageBody.message;
    const chatId = chat.id;
    if (
      content_type === "text" ||
      content_type === "location" ||
      content_type === "location_request" ||
      content_type === "template"
    ) {
      const reqBody = {
        content_type: content_type,
        content: messageBody.message.content,
      };
      return fetch(`/server/chats/${chatId}/messages`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqBody),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          return { chatId: chatId, message: data };
        })
        .catch((error) => {
          throw error;
        });
    } else if (["file", "audio", "sticker"].includes(content_type)) {
      return fetch(`/server/chats/${chatId}/media/`, {
        method: "POST",
        body: messageBody.formData,
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Error inserting file: ", res.statusText);
          } else {
            return res.json();
          }
        })
        .then((response) => {
          const message = messageBody.message;

          // Create a new content object with the existing properties and add the id
          const newContent = {
            ...message.content[content_type],
            id: response.id,
          };

          // Create a new message object with the updated content
          const newMessage = {
            ...message,
            content: {
              ...message.content,
              [content_type]: newContent,
            },
          };

          const reqBody = {
            content_type: content_type,
            content: newMessage.content,
          };

          return fetch(`/server/chats/${chatId}/messages`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(reqBody),
          });
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          return { chatId, message: data };
        })
        .catch((error) => {
          throw error;
        });
    }
  }
);

export const fetchStarredMessages = createAsyncThunk(
  "chat/fetchStarredMessages",
  (chatId, { getState }) => {
    const { chat } = getState();
    const messages = chat.starredMessages[chatId];
    if (messages) {
      return Promise.resolve({ chatId, messages: messages });
    }
    return fetch(`/server/chats/${chatId}/messages?type=starred`)
      .then((response) => {
        if (response.status === 204) {
          return { chatId, messages: [] };
        }
        return response.json();
      })
      .then((data) => ({ chatId, messages: data.messages }))
      .catch((error) => {
        throw error;
      });
  }
);

export const fetchFilesInChat = createAsyncThunk(
  "chat/fetchFilesInChat",
  (chatId, { getState }) => {
    const { chat } = getState();
    const messages = chat.filesInChat[chatId];
    if (messages) {
      return Promise.resolve({ chatId, messages: messages });
    }
    return fetch(`/server/chats/${chatId}/messages?type=files`)
      .then((response) => {
        if (response.status === 204) {
          return { chatId, messages: [] };
        }
        return response.json();
      })
      .then((data) => ({ chatId, messages: data.messages }))
      .catch((error) => {
        throw error;
      });
  }
);

export const updateStarred = createAsyncThunk(
  "chat/updateStarred",
  ({ chatId, messageId, starred }, { getState }) => {
    PageLoader.start();
    return fetch(`/server/chats/${chatId}/messages/${messageId}`, {
      method: "PUT",
      headers: { "Content-Type": "Application/json" },
      body: JSON.stringify({ starred: starred }),
    })
      .then((response) => {
        PageLoader.stop();
        return response.json();
      })
      .then((data) => {
        return { chatId, messageId, starred };
      })
      .catch((error) => {
        PageLoader.stop();
        throw error;
      });
  }
);

export const fetchMedia = createAsyncThunk(
  "chat/fetchMedia",
  ({ chatId, messageId }, { getState }) => {
    const { chat } = getState();
    const base64 = chat.mediaBlobs[messageId];
    if (base64) {
      return Promise.resolve({ id: messageId, chatId: chatId, data: base64 });
    }
    return fetch(`/server/chats/${chatId}/messages/${messageId}/media`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.blob();
      })
      .then((blob) => blobToBase64(blob))
      .then((base64) => ({ id: messageId, chatId: chatId, data: base64 }))
      .catch((error) => {
        throw error;
      });
  }
);

export const updateReadStatus = createAsyncThunk(
  "chat/updateRead",
  ({ chatId, read_time, lastReadMessageId }, { getState }) => {
    let readStatus = true;
    return fetch(`/server/chats/${chatId}`, {
      method: "PUT",
      headers: { "Content-Type": "Application/json" },
      body: JSON.stringify({
        read: readStatus,
        last_read_message_id: lastReadMessageId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return { chatId, read_time, lastReadMessageId };
      })
      .catch((error) => {
        throw error;
      });
  }
);

export const updateAssignee = createAsyncThunk(
  "chat/updateAssignee",
  ({ chatId, assigneeId }) => {
    PageLoader.start();
    return fetch(`/server/chats/${chatId}`, {
      method: "PUT",
      headers: { "Content-Type": "Application/json" },
      body: JSON.stringify({ owner_id: assigneeId }),
    })
      .then((response) => {
        PageLoader.stop();
        if (!response.ok) {
          return Promise.reject(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        return { chatId, assigneeId };
      })
      .catch((err) => {
        PageLoader.stop();
        return Promise.reject(err);
      });
  }
);

export const fetchPreview = createAsyncThunk(
  "chat/preview",
  ({ chatId, messageId, url }, { getState }) => {
    const { previews } = getState().chat;
    const preview = previews[messageId];
    if (preview) {
      return Promise.resolve(preview);
    }

    return fetch(
      `https://test-840896869.development.catalystserverless.com/server/link-preview/preview?url=${encodeURIComponent(
        url
      )}`,
      { method: "POST" }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return { messageId: messageId, url: url, data: {} };
        }
      })
      .then((data) => {
        return { url: url, messageId: messageId, data: data };
      })
      .catch((error) => {
        throw error;
      });
  }
);

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    chatList: [],
    messages: {},
    starredMessages: {},
    filesInChat: {},
    currentChatUpdated: false,
    mediaBlobs: {},
    previews: {},
    pendingUpdate: [],
    loading: {},
    currentInputChat: {},
    currentMediaChat: {},
    views: [],
    currentChat: null,
    fetchChatsLoading: true,
    fetchMessagesLoading: false,
    fetchStarredMessagesLoading: false,
    fetchFilesInChatLoading: false,
    fetchMediaLoading: false,
    sendMessageLoading: false,
    updateChatStatusLoading: false,
    updateAssigneeLoading: false,
    error: null,
  },
  reducers: {
    postChat: (state, action) => {
      state.chatList.push(action.payload);
    },
    updateContactInChat: (state, action) => {
      const { id, contact } = action.payload;

      // Store the chat list and current chat in variables
      const chatList = state.chatList.map((chat) => {
        if (chat.contact.id === id) {
          // Update the contact in the found chat
          return {
            ...chat,
            contact: { ...chat.contact, ...contact },
          };
        }
        return chat;
      });

      // Check if the current chat needs to be updated
      let currentChat = state.currentChat;
      if (currentChat && currentChat.contact.id === id) {
        currentChat = {
          ...currentChat,
          contact: { ...currentChat.contact, ...contact },
        };
      }

      // Assign the updated variables back to the state
      state.chatList = chatList;
      state.currentChat = currentChat;
    },

    deleteChat: (state, action) => {
      const chatId = action.payload;

      const chatToDelete = state.chatList.find(
        (chat) => chat.contact.id === chatId
      );
      const updatedChatList = state.chatList.filter(
        (chat) => chat.contact.id !== chatId
      );

      const updatedMessages = { ...state.messages };
      const updatedStarredMessages = { ...state.starredMessages };
      const updatedFilesInChat = { ...state.filesInChat };

      if (chatToDelete) {
        if (updatedMessages[chatToDelete.id]) {
          delete updatedMessages[chatToDelete.id];
        }

        if (updatedStarredMessages[chatToDelete.id]) {
          delete updatedStarredMessages[chatToDelete.id];
        }

        if (updatedFilesInChat[chatToDelete.id]) {
          delete updatedFilesInChat[chatToDelete.id];
        }
      }

      const updatedCurrentChat =
        state.currentChat && state.currentChat.contact.id === chatId
          ? null
          : state.currentChat;

      state.chatList = updatedChatList;
      state.messages = updatedMessages;
      state.starredMessages = updatedStarredMessages;
      state.filesInChat = updatedFilesInChat;
      state.currentChat = updatedCurrentChat;
    },
    addNewMessage: (state, action) => {
      const { chatId, messages } = action.payload;

      const chat = state.chatList.find((chat) => chat.id === chatId);

      if (chat) {
        const updatedUnreadCount =
          parseInt(chat.unread_count) + parseInt(messages.length);
        chat.unread_count = updatedUnreadCount;

        if (state.currentChat && state.currentChat.id === chatId) {
          state.currentChat.unread_count = updatedUnreadCount;
        }

        if (messages.length !== 0 && state.messages[chatId]) {
          const updatedMessages = [...state.messages[chatId], ...messages];
          state.messages[chatId] = updatedMessages;
        }
      }
    },
    updateOutgoingStatus: (state, action) => {
      const { chatId, messages } = action.payload;

      const chatMessages = state.messages[chatId];

      if (chatMessages) {
        const updatedChatMessages = chatMessages.map((chatMessage) => {
          const messageToUpdate = messages.find(
            (message) => message.id === chatMessage.id
          );
          return messageToUpdate
            ? { ...chatMessage, ...messageToUpdate }
            : chatMessage;
        });

        state.messages[chatId] = updatedChatMessages;
      }
    },
    setCurrentChat: (state, action) => {
      state.currentChatUpdated = false;
      state.currentChat = action.payload;
    },
    updateCurrentInputChat: (state, action) => {
      const { chatId, message, type } = action.payload;
      state.currentInputChat[chatId] = {
        type: type,
        text: message,
      };
    },
    updateCurrentMediaChat: (state, action) => {
      const { chatId, file } = action.payload;
      state.currentMediaChat[chatId] = file;
    },
    updateReadTime: (state, action) => {
      const { read_time, chatId } = state.pendingUpdate[0];

      const chatIndex = state.chatList.findIndex((chat) => chat.id === chatId);

      if (chatIndex !== -1) {
        const chat = state.chatList[chatIndex];

        // Update unread count for the chat and currentChat
        const updatedUnreadCount = 0;
        chat.unread_count = updatedUnreadCount;

        if (state.currentChat && state.currentChat.id === chatId) {
          state.currentChat.unread_count = updatedUnreadCount;
        }

        state.currentChatUpdated = true;
        // Update read_time for incoming messages
        const updatedMessages = state.messages[chatId].map((message) => {
          if (message.origin === "incoming" && message.read_time === null) {
            return { ...message, read_time: read_time };
          }
          return message;
        });

        state.messages[chatId] = updatedMessages;

        // Clear pending updates
        state.pendingUpdate = [];
      }
    },
    updateMediaBlobs: (state, action) => {
      const { messageId, blob } = action.payload;
      if (!messageId || !blob) {
        return;
      }

      state.mediaBlobs = {
        ...state.mediaBlobs,
        [messageId]: blob,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      // Fetch Chats
      .addCase(fetchChats.pending, (state) => {
        state.fetchChatsLoading = true;
      })
      .addCase(fetchChats.fulfilled, (state, action) => {
        state.fetchChatsLoading = false;
        state.chatList = action.payload;
      })
      .addCase(fetchChats.rejected, (state, action) => {
        state.fetchChatsLoading = false;
        state.chatList = [];
        state.error = action.error.message;
      })
      // Fetch filtered chats
      .addCase(fetchFilteredChats.pending, (state, action) => {
        //nothing to do
      })
      .addCase(fetchFilteredChats.fulfilled, (state, action) => {
        const filteredChats = action.payload;
        // update the state of chatList array items filteredChats items which  are present in filteredChats
        state.chatList = state.chatList.map((chat) => {
          const matchedChat = filteredChats.find(
            (filteredChat) => filteredChat.id === chat.id
          );
          return matchedChat ? { ...chat, ...matchedChat } : chat;
        });
        const currentChat = state.currentChat;
        if (currentChat !== null) {
          const isCurrentChatInFilteredChat = filteredChats.findIndex(
            (filteredChat) => filteredChat.id === currentChat.id
          );
          if (isCurrentChatInFilteredChat === -1) {
            state.currentChat = null;
          }
        }

        state.views = filteredChats;
      })
      .addCase(fetchFilteredChats.rejected, (state, action) => {
        state.views = [];
        state.error = action.error;
      })
      // Fetch Messages
      .addCase(fetchMessages.pending, (state) => {
        state.currentChatUpdated = false;
        state.fetchMessagesLoading = true;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.fetchMessagesLoading = false;
        const { chatId, messages } = action.payload;
        state.messages[chatId] = messages;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.fetchMessagesLoading = false;
        state.error = action.error.message;
      })
      // Update Chat Status
      .addCase(updateChatStatus.pending, (state) => {
        state.updateChatStatusLoading = true;
      })
      .addCase(updateChatStatus.fulfilled, (state, action) => {
        state.currentChatUpdated = true;
        state.updateChatStatusLoading = false;
        const { chatId, status } = action.payload;
        const chat = state.chatList.find((c) => c.id === chatId);
        if (chat) {
          chat.state = status;
          if (state.currentChat !== null) {
            state.currentChat.state = status;
          }
        }
      })
      .addCase(updateChatStatus.rejected, (state, action) => {
        state.updateChatStatusLoading = false;
        state.error = action.error.message;
      })
      // Send Message
      .addCase(sendMessage.pending, (state, action) => {
        state.starredChanges = false;

        const messageBody = action.meta.arg.message;
        const chatId = messageBody.chat.id;

        let chatMessages = state.messages[chatId];

        if (chatMessages) {
          delete state.currentInputChat[chatId];
          delete state.currentMediaChat[chatId];
          chatMessages = [...chatMessages, messageBody];
        } else {
          chatMessages = [messageBody];
        }

        state.messages[chatId] = chatMessages;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.sendMessageLoading = false;

        const tempId = action.meta.arg.message.id;
        const metaData = action.meta.arg.metaData;
        const { chatId, message } = action.payload;

        let chatMessages = state.messages[chatId];

        if (chatMessages) {
          const pendingIndex = chatMessages.findIndex((m) => m.id === tempId);

          if (pendingIndex !== -1) {
            if (metaData) {
              const { size, mime_type } = metaData;

              if (size) {
                message.content[message.content_type].size = size;
              }

              if (mime_type) {
                message.content[message.content_type].mime_type = mime_type;
              }
            }

            chatMessages[pendingIndex] = message;

            if (message.content_type === "file" && state.filesInChat[chatId]) {
              state.filesInChat[chatId].push(message);
            }

            state.messages[chatId] = chatMessages;
          }
        }

        if (state.mediaBlobs[tempId]) {
          state.mediaBlobs[message.id] = state.mediaBlobs[tempId];
          delete state.mediaBlobs[tempId];
        }
      })
      .addCase(sendMessage.rejected, (state, action) => {
        const messageBody = action.meta.arg.message;
        const chatId = messageBody.chat.id;
        const tempId = messageBody.id;

        let chatMessages = state.messages[chatId];

        if (chatMessages) {
          const pendingIndex = chatMessages.findIndex((m) => m.id === tempId);

          if (pendingIndex !== -1) {
            chatMessages[pendingIndex].status = "failed";
          }
          state.messages[chatId] = chatMessages;
        }
        state.error = action.error.message;
      })
      // Fetch Starred Messages
      .addCase(fetchStarredMessages.pending, (state) => {
        state.fetchStarredMessagesLoading = true;
      })
      .addCase(fetchStarredMessages.fulfilled, (state, action) => {
        state.fetchStarredMessagesLoading = false;
        const { chatId, messages } = action.payload;
        state.starredMessages[chatId] = messages;
      })
      .addCase(fetchStarredMessages.rejected, (state, action) => {
        state.fetchStarredMessagesLoading = false;
        state.error = action.error.message;
      })
      // Fetch Files in Chat
      .addCase(fetchFilesInChat.pending, (state) => {
        state.fetchFilesInChatLoading = true;
      })
      .addCase(fetchFilesInChat.fulfilled, (state, action) => {
        state.fetchFilesInChatLoading = false;
        const { chatId, messages } = action.payload;
        state.filesInChat[chatId] = messages;
      })
      .addCase(fetchFilesInChat.rejected, (state, action) => {
        state.fetchFilesInChatLoading = false;
        state.error = action.error.message;
      })
      // Fetch Media
      .addCase(fetchMedia.pending, (state, action) => {
        const { messageId } = action.meta.arg;
        state.loading[messageId] = true;
      })
      .addCase(fetchMedia.fulfilled, (state, action) => {
        state.fetchMediaLoading = false;
        const { id, data } = action.payload;
        state.mediaBlobs[id] = data;
        state.loading[id] = false;
      })
      .addCase(fetchMedia.rejected, (state, action) => {
        const { messageId } = action.meta.arg;
        state.loading[messageId] = false;
        state.error = action.error.message;
      })
      // update starred
      .addCase(updateStarred.pending, (state, action) => {
        state.starredChanges = true;
        const { chatId, messageId } = action.meta.arg;

        const chatMessages = state.messages[chatId];

        if (chatMessages) {
          const message = chatMessages.find((m) => m.id === messageId);

          if (message) {
            const starred = !message.starred;
            message.starred = starred;

            if (starred) {
              if (state.starredMessages[chatId]) {
                state.starredMessages[chatId].push(message);
              }
            } else {
              if (state.starredMessages[chatId]) {
                state.starredMessages[chatId] = state.starredMessages[
                  chatId
                ].filter((m) => m.id !== messageId);
              }
            }
          }
        }

        state.messages[chatId] = chatMessages;
      })
      .addCase(updateStarred.fulfilled, (state, action) => {
        //do nothing as all are done in pending
      })
      .addCase(updateStarred.rejected, (state, action) => {
        const { chatId, messageId } = action.meta.arg;
        const chat = state.messages[chatId];
        if (chat) {
          const message = chat.find((m) => m.id === messageId);
          if (message) {
            const starred = !message.starred;
            message.starred = starred;
            if (starred) {
              if (state.starredMessages[chatId]) {
                state.starredMessages[chatId].push(message);
              }
            } else {
              if (state.starredMessages[chatId]) {
                state.starredMessages[chatId] = state.starredMessages[
                  chatId
                ].filter((m) => m.id !== messageId);
              }
            }
          }
        }
        state.error = action.error;
      })
      //update read status
      .addCase(updateReadStatus.fulfilled, (state, action) => {
        const { chatId, read_time, lastReadMessageId } = action.payload;
        state.pendingUpdate = [
          ...state.pendingUpdate,
          { chatId, read_time, lastReadMessageId },
        ];
        const chat = state.chatList.find((chat) => chat.id === chatId);
        if (chat) {
          chat.unread_count = 0;
          state.currentChat.unread_count = 0;
          state.currentChatUpdated = true;
          chat.last_read_message_id = lastReadMessageId;
        }
      })
      .addCase(updateReadStatus.rejected, (state, action) => {
        state.error = action.error;
      })
      //update Assignee
      .addCase(updateAssignee.pending, (state) => {
        state.updateAssigneeLoading = true;
      })
      .addCase(updateAssignee.fulfilled, (state, action) => {
        state.currentChatUpdated = true;
        state.updateAssigneeLoading = false;
        const { chatId, assigneeId } = action.payload;
        const index = state.chatList.findIndex((c) => c.id === chatId);
        state.chatList[index].owner_id = assigneeId;
        state.currentChat.owner_id = assigneeId;
      })
      .addCase(updateAssignee.rejected, (state, action) => {
        state.updateAssigneeLoading = false;
        state.error = action.error;
      })
      //fetch preview
      .addCase(fetchPreview.pending, (state, action) => {
        const { messageId } = action.meta.arg;
        // if (chat)
        // {
        //   const message = chat.messages.find(m => m.id === messageId);
        //   if (message)
        //   {
        //     message.isDownloading = true;

        //   }
        // }
        state.loading[messageId] = true;
      })
      .addCase(fetchPreview.fulfilled, (state, action) => {
        const { url, messageId, data } = action.payload;
        state.loading[messageId] = false;
        state.previews[messageId] = { url: url, data: data };
      })
      .addCase(fetchPreview.rejected, (state, action) => {
        const { messageId } = action.meta.arg;
        state.loading[messageId] = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setCurrentChat,
  updateCurrentInputChat,
  updateReadTime,
  updateCurrentMediaChat,
  updateMediaBlobs,
  postChat,
  addNewMessage,
  updateOutgoingStatus,
  deleteChat,
  updateContactInChat,
} = chatSlice.actions;
export default chatSlice.reducer;
