import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const subscribeNotification = createAsyncThunk(
    'notification/subscribeNotification',
    ({ topic }) => {
        return fetch(`/server/internal/subscriptions`, {
            method: "POST",
            headers: {
                "Content-Type": "Application/json"
            },
            body: JSON.stringify({ topic })
        })
        .then(response => {
            if (!response.ok) {
                return response.json().then(errorResponse => { throw errorResponse; });
            }
            return response.json();
        });
    }
);

export const unSubscribeNotification = createAsyncThunk(
    'notification/unSubscribeNotification',
    ({ topic }) => {
        return fetch(`/server/internal/subscriptions/${topic}`, {
            method: "DELETE"
        })
        .then(response => {
            if (!response.ok) {
                return response.json().then(errorResponse => { throw errorResponse; });
            }
            return { topic };
        });
    }
);

const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        subscriptions: {},
        count:0,
        error: null,
        loading: false
    },
    reducers: 
    {
        updateNotificationCount:(state,action)=>
        {
            state.count=action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(subscribeNotification.pending, (state) => {
                state.loading = true;
            })
            .addCase(subscribeNotification.fulfilled, (state, action) => {
                const { topic, expiry_time } = action.payload;
                state.loading = false;
                state.subscriptions[topic] = expiry_time;
            })
            .addCase(subscribeNotification.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(unSubscribeNotification.pending, (state) => {
                state.loading = true;
            })
            .addCase(unSubscribeNotification.fulfilled, (state, action) => {
                const topic = action.payload.topic;
                state.loading = false;
                delete state.subscriptions[topic];
            })
            .addCase(unSubscribeNotification.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export const { updateNotificationCount }=notificationSlice.actions

export default notificationSlice.reducer;