import React, { useState, useCallback, useMemo, useEffect } from "react";
import star from "../../assets/chat assets/star.svg";
import unstar from "../../assets/chat assets/unstar.svg";
import read from "../../assets/chat assets/messageReadTick.svg";
import delivered from "../../assets/chat assets/delivered.svg";
import sent from "../../assets/chat assets/mdi_tick 1.svg";
import failed from "../../assets/chat assets/tdesign_error.svg";
import pending from "../../assets/chat assets/pending.svg";
import TextChat from "./chats components/TextChat";
import AudioChat from "./chats components/AudioChat";
import ImageChat from "./chats components/ImageChat";
import FileChat from "./chats components/FileChat";
import LocationChat from "./chats components/LocationChat";
import LocationRequestChat from "./chats components/LocationRequestChat";
import ContactsChat from "./chats components/ContactsChat";
import StickerChat from "./chats components/StickerChat";
import TemplateChat from "./chats components/TemplateChat";
import InteractiveChat from "./chats components/InterativeChat";
import VideoChat from "./chats components/VideoChat";
import { useSelector, useDispatch } from "react-redux";
import { fetchMedia, updateStarred } from "../../store/feature/chat/chatSlice";
import { base64ToBlob } from "../../cache/cacheUtils";
import Preview from "../chats page/Preview";
import { formatFullTime, formatTime, formatTimestamp } from "../../utils/utils";

const ChatHeader = React.memo(({ name, chat, formattedTime }) => (
  <div className="chat-message-header">
    <div
      className={
        chat.origin === "incoming"
          ? "chat-message-header-incomeing"
          : "chat-message-header-outgoing"
      }
    >
      <div className="chat-sender-details">
        <div className="chat-sender-name">
          <p>{name}</p>
        </div>
        <div className="chat-sender-dot"></div>
        <div className="chat-sending-time">{formattedTime}</div>
      </div>
    </div>
  </div>
));

const ChatDetailFooter = React.memo(
  ({
    status,
    statusIcon,
    showDetails,
    delivered_time,
    read_time,
    starred,
    showStar,
    handleStarredClick,
  }) => (
    <div className="chat-message-footer">
      {starred === true ? (
        <img
          src={star}
          alt="starred"
          className="msg-stared-img"
          onClick={handleStarredClick}
        />
      ) : (
        status !== "failed" &&
        showStar && (
          <img
            src={unstar}
            alt="unstar"
            className="msg-unstared-img"
            onClick={handleStarredClick}
          />
        )
      )}

      <div
        style={{ cursor: "pointer" }}
      >
        {statusIcon}
      </div>
      {showDetails && status !== "failed" && (
        <div className="chat-info" style={{opacity:showDetails ? "1" :"0"}}>
          {delivered_time && (
            <div className="read-time-info delivered">
              <span>Delivered:</span>{" "}
              <p>{formatTimestamp(delivered_time)}</p>
            </div>
          )}
          {read_time && (
            <div className="read-time-info read">
              <span>Read:</span> <p>{formatTimestamp(read_time)}</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
);

const ChatContent = React.memo(
  ({
    content,
    content_type,
    blob,
    chat,
    handlePreview,
    downLoadMedia,
    loading,
    onDownloadComplete,
  }) => {
    switch (content_type) {
      case "text":
        return <TextChat content={content.text} />;
      case "audio":
        return (
          <AudioChat
            content={content}
            downLoadAudio={downLoadMedia}
            blob={blob}
            loading={loading}
            playAudioAfterDownload={onDownloadComplete}
          />
        );
      case "file":
        if (content.file.type === "document") {
          return (
            <div>
              <FileChat
                content={content}
                blob={blob}
                handlePreview={handlePreview}
                loading={loading}
              />

              {content.file.caption && (
                <TextChat content={content.file.caption} />
              )}
            </div>
          );
        } else if (content.file.type === "image") {
          return (
            <div>
              <ImageChat
                content={content}
                blob={blob}
                handlePreview={handlePreview}
                loading={loading}
              />
              {content.file.caption && (
                <TextChat content={content.file.caption} />
              )}
            </div>
          );
        } else if (content.file.type === "video") {
          return (
            <div>
              <VideoChat
                content={content}
                blob={blob}
                handlePreview={handlePreview}
                loading={loading}
              />
              {content.file.caption && (
                <TextChat content={content.file.caption} />
              )}
            </div>
          );
        }
        break;
      case "location":
        return <LocationChat chat={chat} />;
      case "location_request":
        return <LocationRequestChat content={content} />;
      case "contacts":
        return <ContactsChat content={content} />;
      case "sticker":
        return (
          <StickerChat
            content={content}
            downloadSticker={downLoadMedia}
            blob={blob}
            loading={loading}
          />
        );
      case "template":
        return <TemplateChat content={content} />;
      case "interactive":
        return <InteractiveChat content={content} />;
      default:
        return null;
    }
  }
);

const Chat = ({ chat, user }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showStar, setShowStar] = useState(false);
  const [popup, setPopup] = useState({ visible: false, content: "" });
  const [blob, setBlob] = useState(null);

  const { chatList, currentChat, loading,messages } = useSelector((state) => state.chat);
  // const chatContact = chatList.find((c) => c.id === currentChat.id);
  const currentMessage = messages[currentChat?.id]
    ? messages[currentChat?.id].find((m) => m.id === chat.id)
    : null;
  const loadingStatus = loading[currentMessage?.id] || false;

  const blobs = useSelector((state) => state.chat.mediaBlobs);
  const users = useSelector((state) => state.currentuser.users);

  const currentChatId = chat.chat.id;
  const {
    content,
    content_type,
    starred,
    origin,
    time,
    status,
    delivered_time,
    read_time,
  } = chat;
  const dispatch = useDispatch();

  const closePopup = useCallback(() => {
    setPopup({ visible: false, content: null });
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closePopup();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [closePopup]);

  useEffect(() => {
    if (blobs[chat.id]) {
      base64ToBlob(blobs[chat.id])
        .then(setBlob)
        .catch((err) => console.error("Error converting base64 to blob:", err));
    }
  }, [blobs, chat.id]);

  const downLoadMedia = useCallback(
    (showPopupAfterDownload) => {
      if (!loadingStatus) {
        dispatch(fetchMedia({ chatId: currentChatId, messageId: chat.id }))
          .unwrap()
          .then((result) => {
            return base64ToBlob(result.data);
          })
          .then((fileBlob) => {
            setBlob(fileBlob);
            if (showPopupAfterDownload) {
              handlePreview(true, fileBlob);
            }
          })
          .catch((err) => console.log(err));
      }
    },
    [dispatch, currentChatId, chat.id, loadingStatus]
  );

  const handlePreview = useCallback(
    (showPopup, existingBlob) => {
      const fileBlob = existingBlob || blob;
      if (fileBlob !== null) {
        if (showPopup) {
          setPopup({ visible: true, content });
        }
      } else {
        if (
          content_type === "file" &&
          content.file &&
          content.file.type === "document"
        ) {
          downLoadMedia(true);
        } else {
          downLoadMedia(false);
        }
      }
    },
    [blob, content, content_type, downLoadMedia]
  );

  const formattedTime = useMemo(() => formatTime(time), [time]);
  const userData = useMemo(
    () => users.find((user) => user.user_id === chat.owner_id),
    [users, chat.owner_id]
  );
  const name = useMemo(
    () => (origin === "incoming" ? user : userData?.first_name || "You"),
    [origin, user, userData]
  );
  const chatStyle = useMemo(
    () => (origin === "incoming" ? "chat incoming" : "chat outgoing"),
    [origin]
  );
  const alignStyle = useMemo(
    () => (origin === "incoming" ? "flex-start" : "flex-end"),
    [origin]
  );

  const statusIcon = useMemo(() => {
    switch (status) {
      case "read":
        return <img
         src={read} 
         alt="read" 
         className="messgae-read-img" 
         onMouseEnter={() => setShowDetails(true)}
         onMouseLeave={() => setShowDetails(false)}
         />;
      case "delivered":
        return (
          <img
          onMouseEnter={() => setShowDetails(true)}
          onMouseLeave={() => setShowDetails(false)}
            src={delivered}
            alt="delivered"
            className="messgae-delivered-img"
          />
        );
      case "sent":
      case "submitted":
      case "accepted":
        return <img 
        src={sent} 
        alt="sent" 
        className="messgae-sent-img" 
        onMouseEnter={() => setShowDetails(true)}
        onMouseLeave={() => setShowDetails(false)}
        />;
      case "failed":
        return <img src={failed} alt="failed" className="messgae-failed-img" />;
      case "pending":
        return (
          <img src={pending} alt="pending" className="messgae-pending-img" />
        );
      default:
        return null;
    }
  }, [status]);

  const handleStarredClick = useCallback(() => {
    dispatch(
      updateStarred({
        chatId: currentChatId,
        messageId: chat.id,
        starred: !starred,
      })
    );
  }, [dispatch, currentChatId, chat.id, starred]);

  return (
    <>
      <div
        className="chat-wrapper"
        style={{ justifyContent: alignStyle }}
        onMouseEnter={() => setShowStar(true)}
        onMouseLeave={() => setShowStar(false)}
      >
        <div className={chatStyle}>
          <div className="message">
            <ChatHeader
              name={name}
              starred={starred}
              showStar={showStar}
              status={status}
              chat={chat}
              formattedTime={formattedTime}
              handleStarredClick={handleStarredClick}
            />
            <div
              className={
                chat.origin === "incoming"
                  ? "msg-contenet-and-footer-incomeing"
                  : "msg-contenet-and-footer-outgoing"
              }
            >
              <ChatContent
                content={content}
                content_type={content_type}
                blob={blob}
                chat={chat}
                handlePreview={handlePreview}
                downLoadMedia={downLoadMedia}
                loading={loadingStatus}
              />
              <ChatDetailFooter
                status={status}
                statusIcon={statusIcon}
                showDetails={showDetails}
                delivered_time={delivered_time}
                read_time={read_time}
                formatFullTime={formatFullTime}
                setShowDetails={setShowDetails}
                starred={starred}
                showStar={showStar}
                handleStarredClick={handleStarredClick}
              />
            </div>
          </div>
        </div>
      </div>
      {popup.visible && content_type !== "audio" && (
        <Preview blob={blob} closePreview={closePopup} />
      )}
    </>
  );
};

export default React.memo(Chat);
