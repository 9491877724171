export const formatTime = (dateString) => {
  const date = new Date(dateString);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

  
export const formatFullTime = (dateString) => 
{
  const date = new Date(dateString);
  const day = date.toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' });
  const time = date.toLocaleString('default', { hour: 'numeric', minute: 'numeric', hour12: true });
  return `${day} ${time}`;
};
  

export const formatDate = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = d.getFullYear();
  const hours = String(d.getHours()).padStart(2, '0');
  const minutes = String(d.getMinutes()).padStart(2, '0');
  const seconds = String(d.getSeconds()).padStart(2, '0');
  const milliseconds = String(d.getMilliseconds()).padStart(3, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}:${milliseconds}`;
};


export function formatTimestamp(timestamp) {
  // Check if the timestamp has milliseconds
  let timestampWithoutMilliseconds = timestamp;
  const timeParts = timestamp.split(' ')[1].split(':');
  if (timeParts.length === 4) {
    // If there are 4 parts, it means milliseconds are included
    timestampWithoutMilliseconds = timestamp.split(':').slice(0, 3).join(':');
  }
  
  // Parse the date
  const date = new Date(timestampWithoutMilliseconds);
  
  if (isNaN(date.getTime())) {
    // Handle invalid date
    return 'Invalid date';
  }

  // Format the day and month with leading zeros
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  
  // Get hours and minutes
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Determine AM/PM and convert to 12-hour format
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'
  const formattedHours = String(hours).padStart(2, '0');

  const formattedDate = `${day}/${month}/${year}`;
  const formattedTime = `${formattedHours}:${minutes} ${ampm}`;

  return `${formattedDate} ${formattedTime}`;
}



export const createMessageBody=(config)=>
{
  const { owner_id,chatId,content_type,content}=config

  const tempId = `${chatId}-${Date.now()}`;
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().replace('T', ' ').substring(0, 19);
  const message = 
  {
    chat:
    {
      id:chatId
    },
    id: tempId,
    status: "pending",
    owner_id:owner_id,
    content_type: content_type,
    content: content,
    time: formattedDate
  };

  return message
}

export const createNewMessageFromNotification=(msg)=>
{
  const newMessages=[]
  msg.messages.forEach(message=>
  {
    const body=
    {
      chat:
      {
        id:msg.chats.id
      }
    }
  
    Object.assign(body,message)
    newMessages.push(body)
  })
  
  return newMessages
}
