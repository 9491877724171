import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enableChat, fetchContactById } from '../../store/feature/contacts/contactSlice';
import Spinner from '../../page loader/Spinner';
import close from '../../assets/contacts assets/chatheaderclose.svg'
import { fetchChats, postChat, setCurrentChat } from '../../store/feature/chat/chatSlice';
import { useNavigate } from 'react-router-dom';

const ContactPopup = ({ id, onClose,handleEdit,handleDelete }) => {
    const { contactsByID, contactByIDLoading,deleteContactLoading, updateContactLoading,enableChatLoading  } = useSelector(state => state.contact);
    const chatList=useSelector(state=>state.chat.chatList)
    const [contact,setContact]=useState(contactsByID[id])
    const navigate=useNavigate()
    const channels = useSelector(state => state.channel.channels);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchContactById(id));
    }, [dispatch, id]);

    useEffect(()=>{
        if(contactsByID[id])
        {
            setContact(contactsByID[id])
        }
    },[contactsByID])

    const handleEnable = (type) => 
    {
        if(type==="enable")
        {
            const body = {
                channel: {
                    id: channels[0].id
                }
            };

            dispatch(enableChat({ id, body }))
            .unwrap()
            .then((response) => {
                if(chatList && chatList.length!==0)
                {
                    const chat=response.chats.find(chat=>chat.id===response.response.id)
                    if(chat)
                    {
                        dispatch(postChat(chat))
                    }
                }
                
                onClose()
            })
            .catch((error) => {
                console.error("Failed to enable chat:", error);
            });

            
        }
        else
        {
            navigate("/chats")
            if(chatList && chatList.length!==0)
            {
                const chat=chatList.find(chat=>chat.id===contact.chats[0]?.id)
                if(chat)
                {
                    dispatch(setCurrentChat(chat))
                }
            }
            else
            {
                dispatch(fetchChats())
                .unwrap()
                .then((response)=>{
                    const chat=response.find(chat=>chat.id===contact.chats[0]?.id)
                    if(chat)
                    {
                        dispatch(setCurrentChat(chat))
                    }
                })
                .catch(err=>{
                    console.log(err);
                    
                })
            }
        }
    };

    function editForm(e)
    {
        handleEdit(contact,e)
        onClose()
    }

    function deleteContact(e)
    {
        handleDelete(contact?.id,e)
        onClose()
    }

    return (
        <div className='overlay'>
            <div className="contact-popup">
            {contactByIDLoading && <Spinner />}
            {!contactByIDLoading && contactsByID[id] && (
                <div className="contact-details-popup">
                    <div className='contact-details-popup-header'>
                        <p className='contact-popup-title'>Contact Details</p>
                        <div className='contact-details-popup-header-actions'>
                            <button className='popup-edit-btn' 
                            onClick={(e)=>editForm(e)}
                            disabled={updateContactLoading || deleteContactLoading || enableChatLoading}
                            >
                                EDIT
                            </button>
                            <img src={close} className='icon' width={"22px"} height={"22px"} alt='close' onClick={onClose}/>
                        </div>
                    </div>
                    <div className='profile-name'>
                        <div className='profile-letter'>
                            {contact?.name?.charAt(0).toUpperCase()}
                        </div>
                        <div className='inside-profile-name'>
                            <p style={{fontSize:"12px"}}>Name:</p> <p style={{fontSize:"14px"}}>{contact?.name}</p>
                        </div>
                    </div>
                    <div className='profile-mobile'>
                        <p style={{fontSize:"12px"}}>Mobile:</p><p style={{fontSize:"14px"}}>{contact?.mobile}</p>
                    </div>
                    <div className='contact-popup-email'>
                        <p style={{fontSize:"12px"}}>Email:</p> <p style={{fontSize:"14px"}}>{contact?.email || "-"}</p>
                    </div>

                    <div className='contact-bottom'>
                    
                        <button className="delete-button" 
                        onClick={(e)=>deleteContact(e)}
                        disabled={updateContactLoading || deleteContactLoading || enableChatLoading}
                        >
                            DELETE
                        </button>
                        <button className="enable-button" 
                        disabled={updateContactLoading || deleteContactLoading || enableChatLoading}
                        onClick={()=>handleEnable(contact?.chats?.length === 0 ? "enable" : "chat")}>
                        {contact?.chats?.length === 0 ? "ENABLE CHAT" : "GO TO CHAT"}
                        </button>
                    </div>

                    
                </div>
            )}
        </div>
        </div>
    );
}

export default ContactPopup;
