import React, { useState, useRef, useEffect } from "react";
import sendBtn from "../../assets/chat assets/send-btn.svg";
import attachments from "../../assets/chat assets/attachment.svg";
import smiley from "../../assets/chat assets/keybord-smily.svg";
import sticker from "../../assets/chat assets/sticker.svg";
import mic from "../../assets/chat assets/mic_24dp_FILL0_wght400_GRAD0_opsz24 1.svg";
import { debounce, size } from "lodash";
import validator from "validator";
import {
  sendMessage,
  updateAssignee,
  updateCurrentInputChat,
  updateCurrentMediaChat,
  updateMediaBlobs,
} from "../../store/feature/chat/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../page loader/Spinner";
import EmojiContainer from "./EmojiContainer";
import close from "../../assets/chat assets/material-symbols_close.svg";
import Attachments from "./Attachments";
import Media from "./Media";
import { blobToBase64 } from "../../cache/cacheUtils";
import { createMessageBody } from "../../utils/utils";
import AudioRecorder from "./AudioRecorder";

const debouncedCheckForUrl = debounce((checkForUrl) => {
  checkForUrl();
}, 1000);

const ChatFooterCopy = ({
  linkPreview,
  setLinkPreview,
  loading,
  setLoading,
  height,
  setHeight,
  contactzindex,
}) => {
  const [messageText, setMessageText] = useState("");
  const [textAreaHeight, setTextAreaHeight] = useState(0);
  const [linkPreviewHeight, setLinkPreviewHeight] = useState(0);
  const [attachmentsHeight, setAttachmentsHeight] = useState(0);
  const [emojiHeight, setEmojiHeight] = useState(0);
  const [file, setFile] = useState(null);
  const owner = useSelector((state) => state.currentuser.currentUser);
  const currentInputChat = useSelector((state) => state.chat.currentInputChat);
  const currentMediaChat = useSelector((state) => state.chat.currentMediaChat);
  const currentChat = useSelector((state) => state.chat.currentChat);
  const users = useSelector((state) => state.currentuser.users);
  const currentUserInfo = useSelector((state) => state.currentuser.currentUser);
  const currentUser = currentUserInfo?.role_details.role_name;
  const currentUserId = currentUserInfo.user_id;
  const assigneeInfo = users.find(
    (user) => user.user_id === currentChat.owner_id
  );
  const currentChatRef = useRef(currentChat);
  const [isRecording, setIsRecording] = useState(false);

  const [recordingTime, setRecordingTime] = useState(0);
  const [notify, setNotify] = useState({ status: false, message: "" });
  const maxSize = 16777216;
  const tempSizeRef = useRef(0);
  const isCanceledRef = useRef(false);

  const [messageText1, setMessageText1] = useState("");
  const [borderColor, setBorderColor] = useState("#ecedf2");
  //   const [notify,setNotify]=useState(false)

  const fileInputRef = useRef(null);

  const isCaption = attachmentsHeight !== 0 && file !== null;

  const textareaRef = useRef(null);
  const dispatch = useDispatch();

  const [viewattachment, setViewattachment] = useState(false);

  useEffect(() => {
    setEmojiHeight(0);
  }, []);

  useEffect(() => {
    if (file) {
      setAttachmentsHeight(10);
      setViewattachment((pre) => !pre);
    }
  }, [file]);

  useEffect(() => {
    if (currentChat !== null) {
      currentChatRef.current = currentChat;

      setIsRecording(false);
      setRecordingTime(0);
      setEmojiHeight(0);
      tempSizeRef.current = 0;
      isCanceledRef.current = false;
      setFile(null);
      setAttachmentsHeight(0);
      if (
        currentInputChat &&
        currentInputChat[currentChat.id] !== undefined &&
        currentInputChat[currentChat.id] !== null
      ) {
        const { type, text } = currentInputChat[currentChat.id];
        if (type === "text") {
          setMessageText(text);
        }
      } else {
        setMessageText("");
      }
    }
  }, [currentChat]);

  useEffect(() => {
    setFile(null);
    setAttachmentsHeight(0);
  }, [currentMediaChat]);

  useEffect(() => {
    const totalHeight =
      textAreaHeight + linkPreviewHeight + emojiHeight + attachmentsHeight + 10;
    setHeight({
      chatArea: 82 - totalHeight,
      chatFooter: totalHeight,
    });
  }, [
    textAreaHeight,
    linkPreviewHeight,
    emojiHeight,
    attachmentsHeight,
    setHeight,
  ]);

  const handleAttachments = () => {
    setAttachmentsHeight(0);
    setLinkPreview(null);
    setLinkPreviewHeight(0);
    setEmojiHeight(0);
    setViewattachment((prev) => !prev);
  };

  const adjustTextareaHeight = (text) => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = "auto";

      // Check if the text contains a newline character
      if (typeof text === "string" && text.includes("\n")) {
        const newHeightPx = Math.min(textarea.scrollHeight, 120); // 120px = 15vh approximately
        textarea.style.height = `${newHeightPx}px`;

        const newHeightVh = (newHeightPx / window.innerHeight) * 100; // Convert pixel height to vh
        const heightInVh = Math.min(newHeightVh, 15);
        setTextAreaHeight(heightInVh); // Cap the height at 15vh
      } else {
        textarea.style.height = "auto"; // Reset height to auto when no newline
        setTextAreaHeight(0); // Set the textarea height to 0 when no newline
      }
    }
  };

  const urlFromObj = (str) => {
    try {
      const url = new URL(str);
      const obj = {
        protocol: url.protocol,
        hostname: url.hostname,
        pathname: url.pathname,
      };
      const newURL = `${obj.protocol}//${obj.hostname}`;
      return newURL;
    } catch (error) {
      return null;
    }
  };

  function getImage(url, image) {
    if (image && image.startsWith("/")) {
      try {
        const parsedURL = new URL(url);
        const imagePath = image.replace("//", "/");
        const fullPath = `${parsedURL.origin}${imagePath}`;
        return fullPath;
      } catch (error) {
        return image;
      }
    } else {
      return image;
    }
  }

  const isValidUrl = (string) => {
    let url = urlFromObj(
      string.includes("www.") ? string.replace("www.", "") : string
    );
    if (url === null) {
      return false;
    } else {
      const urlRegex =
        /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/;

      const hasTld = /\.[a-zA-Z]{2,}$/.test(url);

      const isUrl =
        validator.isURL(url, { require_protocol: true }) &&
        urlRegex.test(url) &&
        hasTld;

      return isUrl;
    }
  };

  const checkForUrl = (text) => {
    const words = text.split(" ");
    const lastWord = words[words.length - 1];
    if (lastWord.trim() !== "") {
      const url = isValidUrl(lastWord);
      if (url) {
        fetchLinkPreview(lastWord);
      } else {
        setLinkPreview(null);
        setLinkPreviewHeight(0);
      }
    }
  };

  const fetchLinkPreview = async (url) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://test-840896869.development.catalystserverless.com/server/link-preview/preview?url=${encodeURIComponent(
          url
        )}`,
        { method: "POST" }
      );
      if (response.ok) {
        const data = await response.json();
        setLinkPreview(data);
        setLinkPreviewHeight(10); // Set link preview height to 10vh
      } else {
        console.error("Failed to fetch link preview:", response.statusText);
        setLinkPreview({ url });
        setLinkPreviewHeight(10); // Set link preview height to 10vh
      }
    } catch (error) {
      console.error("Failed to fetch link preview:", error);
      setLinkPreview({ url });
      setLinkPreviewHeight(10); // Set link preview height to 10vh
    } finally {
      setLoading(false);
    }
  };

  const sendMessageHandler = () => {
    if (!isCaption && !messageText.trim()) {
      return;
    } else if (isCaption) {
      blobToBase64(file)
        .then((result) => {
          const config = {
            chatId: currentChat.id,
            owner_id: owner.user_id,
            content_type: "file",
            content: {
              file: {
                type:
                  (file.type.startsWith("image/") && "image") ||
                  (file.type.startsWith("video/") && "video") ||
                  "document",
              },
            },
          };
          if (config.content.file.type === "document") {
            config.content.file.name = file.name || "untitled";
          }
          if (messageText.trim() !== "") {
            config.content.file.caption = messageText.trim();
          }
          const messageBody = createMessageBody(config);

          const formData = new FormData();
          formData.append("file", file);

          const contentBody = {
            message: messageBody,
            formData: formData,
            metaData: {
              size: file.size,
              mime_type: file.type,
            },
          };

          dispatch(
            updateMediaBlobs({ messageId: messageBody.id, blob: result })
          );
          dispatch(sendMessage(contentBody));
          setMessageText("");
          setFile(null);
          setTextAreaHeight(0);
          setAttachmentsHeight(0);
          setEmojiHeight(0);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const config = {
        chatId: currentChat.id,
        owner_id: owner.user_id,
        content_type: "text",
        content: {
          text: messageText.trim(),
        },
      };
      const contentBody = {
        message: createMessageBody(config),
      };
      dispatch(sendMessage(contentBody));
      setMessageText("");
      setLinkPreview(null);
      setTextAreaHeight(0);
      setLinkPreviewHeight(0);
      setEmojiHeight(0);
    }

    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
    }
  };

  function resetToDefaultHeight() {
    setFile(null);
    setAttachmentsHeight(0);
    setEmojiHeight(0);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessageHandler();
    } else if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      setMessageText((prevMessageText) => prevMessageText + "\n");
      adjustTextareaHeight(messageText + "\n");
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const {
    title = "",
    description = "",
    url = "",
    image = "",
  } = linkPreview || {};
  const truncatedTitle = title
    ? truncateText(title, 80)
    : truncateText(url, 80);
  const truncatedDescription = description
    ? truncateText(description, 150)
    : "";
  const truncatedUrl = url ? truncateText(url, 50) : truncateText(url, 100);
  const info = {
    title: truncatedTitle,
    description: truncatedDescription,
    url: truncatedUrl,
    image: image ? getImage(url, image) : null,
  };

  const linkInfoStyle = {
    width: info.image ? "80%" : "100%",
  };

  const toggleEmojiPicker = () => {
    setEmojiHeight((prevEmojiHeight) => (prevEmojiHeight === 0 ? 42 : 0));
    setViewattachment(false);
  };

  const addEmoji = (emojiText) => {
    try {
      const sys = emojiText.unified.split("-");
      const codeArr = sys.map((item) => parseInt(item, 16)); // Convert each part to an integer using base 16
      const emoji = String.fromCodePoint(...codeArr); // Spread the array into the function
      const newMessage = messageText + emoji;
      setMessageText(newMessage);
      adjustTextareaHeight(newMessage);
      dispatch(
        updateCurrentInputChat({
          chatId: currentChat.id,
          message: newMessage,
          type: isCaption ? "caption" : "text",
        })
      );
    } catch (error) {
      console.error("Failed to add emoji:", error);
    }
  };

  const closeMedia = () => {
    setFile(null);
    setAttachmentsHeight(0);
    dispatch(updateCurrentMediaChat({ chatId: currentChat.id, file: null }));
  };

  const closeAttachments = () => {
    setAttachmentsHeight(0);
    closeMedia();
  };
  function checkPermission() {
    return navigator.permissions
      .query({ name: "microphone" })
      .then((permissionStatus) => {
        if (permissionStatus.state === "granted") {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  }

  function startRecording() {
    checkPermission().then((hasPermission) => {
      if (hasPermission) {
        setIsRecording(true);
        isCanceledRef.current = false;
        tempSizeRef.current = 0;
        setRecordingTime(0);
      } else {
        setNotify({
          status: true,
          message: "Enable microphone permission to record audio",
        });
      }
    });
  }

  const stopRecording = () => {
    setIsRecording(false);
  };

  function closePopup() {
    setNotify({ status: false, message: "" });
  }

  function cancelRecording() {
    isCanceledRef.current = true;
    setIsRecording(false);
  }

  function onData(blob) {
    tempSizeRef.current += blob.size;

    if (tempSizeRef.current >= maxSize) {
      stopRecording();
    }
  }

  function onStop(recordedBlob) {
    if (isCanceledRef.current) {
      // console.log("canceled");
    } else {
      // console.log("sending...");
      const audioFile = recordedBlob;
      // console.log(audioFile);

      // blobToBase64(audioFile)
      // .then(result=>
      // {
      //   console.log(result);
      //   const config=
      //   {
      //     chatId:currentChatRef.current.id,
      //     owner_id:owner.user_id,
      //     content_type:"audio",
      //     content:
      //     {
      //       audio:
      //       {}
      //     }
      //   }

      //   const formData=new FormData()
      //   formData.append("file",audioFile)

      //   const messageBody=createMessageBody(config)

      //   const contentBody=
      //   {
      //     message:messageBody,
      //     formData:formData,
      //     metaData:
      //     {
      //       size:audioFile.size
      //     }
      //   }

      //   dispatch(updateMediaBlobs({messageId:messageBody.id,blob:result}))
      //   dispatch(sendMessage(contentBody));
      // })
      // .catch(err=>
      // {
      //   console.log(err);
      // })
    }
  }

  const handleStickerIconClick = () => {
    fileInputRef.current.click();
  };

  //   const handleFileChange = (event) => {
  //     const stickerFile = event.target.files[0];
  //     if (stickerFile) {
  //       // Check if the sticker file is of type .webp
  //       if (stickerFile.type === "image/webp") {
  //         blobToBase64(stickerFile)
  //           .then((result) => {
  //             const config = {
  //               chatId: currentChatRef.current.id,
  //               owner_id: owner.user_id,
  //               content_type: "sticker",
  //               content: {
  //                 sticker: {},
  //               },
  //             };

  //             const formData = new FormData();
  //             formData.append("file", stickerFile);

  //             const messageBody = createMessageBody(config);

  //             const contentBody = {
  //               message: messageBody,
  //               formData: formData,
  //               metaData: {
  //                 size: stickerFile.size,
  //                 mime_type: stickerFile.type,
  //               },
  //             };

  //             dispatch(
  //               updateMediaBlobs({ messageId: messageBody.id, blob: result })
  //             );
  //             dispatch(sendMessage(contentBody));
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       } else {
  //         setNotify({ status: true, message: "Sticker should be of type .webp" });
  //       }

  //       // blobToBase64(stickerFile)
  //       //     .then(result => {
  //       //       const config = {
  //       //         chatId: currentChatRef.current.id,
  //       //         owner_id: owner.user_id,
  //       //         content_type: "audio",
  //       //         content: {
  //       //           audio: {}
  //       //         }
  //       //       };

  //       //       const formData = new FormData();
  //       //       formData.append("file", stickerFile);

  //       //       const messageBody = createMessageBody(config);

  //       //       const contentBody = {
  //       //         message: messageBody,
  //       //         formData: formData,
  //       //         metaData: {
  //       //           size: stickerFile.size,
  //       //           mime_type: stickerFile.type
  //       //         }
  //       //       };

  //       //       dispatch(updateMediaBlobs({ messageId: messageBody.id, blob: result }));
  //       //       dispatch(sendMessage(contentBody));
  //       //     })
  //       //     .catch(err => {
  //       //       console.log(err);
  //       //     });
  //     }
  //   };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileSizeMB = selectedFile.size / (1024 * 1024);
      const fileType = selectedFile.type;
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      if (fileType.startsWith("image/")) {
        if (
          (fileType === "image/jpeg" || fileType === "image/png") &&
          fileSizeMB <= 5
        ) {
          setFile(selectedFile);
        } else {
          setNotify({
            status: true,
            message: "Image should be JPEG or PNG and not more than 5MB.",
          });
          event.target.value = null;
        }
      } else if (fileType.startsWith("video/")) {
        if (
          (fileType === "video/mp4" || fileType === "video/3gpp") &&
          fileSizeMB <= 16
        ) {
          setFile(selectedFile);
        } else {
          setNotify({
            status: true,
            message: "Video should be MP4 or 3GP and not more than 16MB.",
          });
          event.target.value = null;
        }
      } else if (
        ["pdf", "xls", "xlsx", "doc", "docx", "ppt", "pptx", "txt"].includes(
          fileExtension
        )
      ) {
        if (fileSizeMB <= 100) {
          setFile(selectedFile);
        } else {
          setNotify({
            status: true,
            message: "Document should not be more than 100MB.",
          });
          event.target.value = null;
        }
      } else {
        setNotify({ status: true, message: "Unsupported file type." });
        event.target.value = null;
      }
    }
  };

  const handleInputChange = (e) => {
    const text = e.target.value;
    setMessageText(text);
    if (isCaption) {
      dispatch(
        updateCurrentInputChat({
          chatId: currentChat.id,
          message: text,
          type: "caption",
        })
      );
    } else {
      dispatch(
        updateCurrentInputChat({
          chatId: currentChat.id,
          message: text,
          type: "text",
        })
      );

      if (!text.trim()) {
        setLinkPreview(null);
        setLinkPreviewHeight(0);
        // setBorderColor("#E5F1FF");
      }

      // Check if there is text in the textarea
      if (text.trim()) {
        setBorderColor("#E5F1FF");
      }
      // else {
      //   setBorderColor("#E5F1FF");
      // }
    }

    adjustTextareaHeight(text);
  };

  const handleFocus = () => {
    setBorderColor("#E5F1FF");
  };

  const handleBlur = () => {
    // if (!messageText.trim()) {
    //   setBorderColor("#ecedf2");
    // }
    setBorderColor("#ecedf2");
  };

  const handleAssignUser = () => {
    dispatch(
      updateAssignee({ chatId: currentChat.id, assigneeId: currentUserId })
    );
  };

  const attachmentdropdownclosefn = () => {
    setViewattachment((prev) => !prev);
    setEmojiHeight(0);
  };

  const emoijedropdownclosefn = () => {
    setEmojiHeight(0);
  };

  return (
    <>
      {viewattachment && (
        <div
          className="attachment-drop-down-clos-div"
          onClick={attachmentdropdownclosefn}
        ></div>
      )}

      {/* {emojiHeight == "42" && (
        <div
          className="emoije-drop-down-clos-div"
          onClick={emoijedropdownclosefn}
        ></div>
      )} */}
      {notify.status && (
        <div className="notification">
          <div className="notification-content">
            <p>{notify.message}</p>
            <button onClick={closePopup} className="close-btn">
              OK,got it
            </button>
          </div>
        </div>
      )}

      <div
        className={`chatting-bar ${
          linkPreview || loading ? "link-preview-open" : ""
        }`}
        // style={{ height: `${height.chatFooter}vh` }}
        style={{
          zIndex:
            contactzindex.headerpdown || contactzindex.contactdown ? 0 : 4,
          height: `${height.chatFooter}vh`,
        }}
      >
        {loading && (
          <div
            className="link-preview"
            style={{
              justifyContent: "center",
              alignItems: "center",
              zIndex: "10",
            }}
          >
            <Spinner color={"white"} size={"30px"} />
          </div>
        )}
        {emojiHeight !== 0 && <EmojiContainer onSelect={addEmoji} />}
        {!loading && linkPreview && (
          <div className="link-preview">
            {info.image && (
              <div className="img">
                <img
                  src={info.image}
                  alt="Link preview"
                  f5f7fb
                  style={{ objectFit: "cover" }}
                />
              </div>
            )}
            <div className="link-preview-info" style={linkInfoStyle}>
              <h4>{info.title}</h4>
              <p>{info.description}</p>
              <p>{info.url}</p>
            </div>
          </div>
        )}
        {/* {attachmentsHeight !== 0 && file === null && (
          <div style={{ height: attachmentsHeight + "vh", width: "95%" }}>
            <Attachments
              setFile={setFile}
              reset={resetToDefaultHeight}
              caption={messageText}
              setMessageText={setMessageText}
            />
          </div>
        )} */}
        {attachmentsHeight !== 0 && file !== null && (
          <div style={{ height: attachmentsHeight + "vh", width: "100%" }}>
            <Media file={file} onRemove={closeMedia} />
          </div>
        )}

        {(!assigneeInfo?.first_name ||
          (assigneeInfo?.first_name && currentChat?.state === "closed") ||
          currentUserInfo?.user_id !== currentChat?.owner_id) && (
          <div className="chatting-field-box2">
            {(!assigneeInfo?.first_name ||
              currentUserInfo?.user_id !== currentChat?.owner_id) &&
              " Only the chat assignee can send messages. "}
            {!assigneeInfo?.first_name && currentUser === "Administrator" && (
              <span onClick={handleAssignUser}> Assign to yourself</span>
            )}
          </div>
        )}

        {/* {((assigneeInfo?.first_name && currentChat?.state==="closed") || currentUserInfo?.user_id!==currentChat?.owner_id) &&
          <div className="chatting-field-box2">
            {currentUserInfo?.user_id!==currentChat?.owner_id && "Only the chat assignee can send messages."}
          </div>
        } */}

        {assigneeInfo?.first_name &&
          currentChat?.state === "open" &&
          currentUserInfo?.user_id === currentChat?.owner_id && (
            <div
              className="chatting-field-box"
              style={{ borderColor: borderColor || "initial" }}
            >
              <>
                <div
                  className="chat-fields"
                  style={{
                    justifyContent: isRecording ? "end" : "space-between",
                  }}
                >
                  {!isRecording && (
                    <>
                      <div className="input-container">
                        <textarea
                          id="message-input"
                          placeholder={"Type your message here..."}
                          value={messageText}
                          //   onChange={handleInputChange}

                          onChange={handleInputChange} // Update input handler
                          onFocus={handleFocus} // Add focus handler
                          onBlur={handleBlur} // Add blur handler
                          onInput={adjustTextareaHeight}
                          onKeyDown={handleKeyDown}
                          ref={textareaRef}
                        ></textarea>
                      </div>
                      <div className="chat-kb-options-box">
                        <div className="chat-kb-attachment-box">
                          {!file && !messageText.trim() && (
                            <img
                              src={attachments}
                              className="chat-attachments-icon"
                              title="attachments"
                              alt="attachments"
                              onClick={() => handleAttachments()}
                            />
                          )}
                          {/* {attachmentsHeight !== 0 && (
                          <img
                            src={close}
                            className="chat-close-icon"
                            title="close"
                            alt="close"
                            onClick={() => closeAttachments()}
                          />
                        )} */}
                        </div>

                        <img
                          src={smiley}
                          alt="smiley"
                          className="chat-emoji-icon"
                          title="emoji"
                          onClick={toggleEmojiPicker}
                        />
                        <img
                          src={mic}
                          alt="mic"
                          className="chat-mic-icon"
                          title="audio"
                          onClick={startRecording}
                        />

                        {/* <img
                      src={sticker}
                      alt="sticker"
                      className="chat-sticker-icon"
                      title="sticker"
                      onClick={handleStickerIconClick}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    /> */}

                        <div className="key-board-line"></div>

                        {/* {messageText.trim() && (
                        <div
                          id="send-btn"
                          onClick={
                            isRecording ? stopRecording : sendMessageHandler
                          }
                        >
                          Send
                          <img
                            src={sendBtn}
                            className="chat-send-btn-icon"
                            alt="send"
                          />
                        </div>
                      )} */}

                        {viewattachment && (
                          <div className="kb-drop-down-box">
                            <div>
                              <label htmlFor="Document">Document</label>
                              <input
                                id="Document"
                                accept=".pdf,.doc,.docx,.txt,.xlsx,.ppt,.pptx"
                                type="file"
                                style={{ display: "none" }}
                                ref={fileInputRef}
                                onChange={(e) => handleFileChange(e)}
                              ></input>
                            </div>
                            <div>
                              <label htmlFor="Image">Image</label>
                              <input
                                id="Image"
                                accept="image/*"
                                type="file"
                                style={{ display: "none" }}
                                ref={fileInputRef}
                                onChange={(e) => handleFileChange(e)}
                              ></input>
                            </div>
                            <div>
                              <label htmlFor="Video">Video</label>
                              <input
                                id="Video"
                                accept="video/*"
                                type="file"
                                style={{ display: "none" }}
                                ref={fileInputRef}
                                onChange={(e) => handleFileChange(e)}
                              ></input>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  <AudioRecorder
                    isRecording={isRecording}
                    recordingTime={recordingTime}
                    setRecordingTime={setRecordingTime}
                    onData={onData}
                    onStop={onStop}
                    cancelRecording={cancelRecording}
                  />
                </div>
              </>
            </div>
          )}
      </div>

      {notify.status && (
        <div className="notification">
          <div className="notification-content">
            <p>{notify.message}</p>
            <button onClick={() => closePopup()} className="close-btn">
              OK,got it
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatFooterCopy;
