import React, { useEffect, useState } from "react";
import activateImg from "../../assets/home assets/accept_activate.jpg";
import { useDispatch, useSelector } from "react-redux";
import { updateChannel } from "../../store/feature/channels/channelSlice";

const ActivationPage = ({ id, setIdToActivate }) => {
  const [otp, setOtp] = useState("");
  const [warning, setWarning] = useState("");
  const { error, updateLoading } = useSelector((state) => state.channel);

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
    }
  };

  const handleActivate = () => {
    if (otp.length === 6) {
      const body = {
        config: {
          pin: otp,
        },
      };
      dispatch(updateChannel({ id: id, body: body, action: "activate" }));
      setIdToActivate(null);
    } else {
      setWarning("Please enter a 6-digit code.");
    }
  };
  return (
    <div className="facebook-login-steps2">
      <div className="facebook-login-header2">
        <p className="facebook-login-title">
          Activate your WhatsApp Business Phone Number
        </p>
        <p className="facebook-login-content">
          In order to start messaging, you have to activate your WhatsApp
          Business phone number.
        </p>
      </div>

      <div className="facebook-login-main-step2">
        <div className="facebook-login-step2-image ">
          <img src={activateImg} alt="activate" />
        </div>
        <div className="facebook-activation-container">
          <div className="facebook-activation-instruction">
            <div>
              Enter any 6 digit number to set as the two step verification PIN
              of your phone number.
            </div>
          </div>

          <div className="facebook-activation-input">
            <input
              type="text"
              value={otp}
              onChange={handleInputChange}
              placeholder="Enter 6-digit code"
              maxLength="6"
              className="code-input"
            />
            <button onClick={handleActivate} className="activate-button">
              <div className="activate-button-text">ACTIVATE</div>
            </button>
          </div>

          <div
            className={
              error !== null
                ? "facebook-activation-info"
                : "facebook-activation-info-hide"
            }
          >
            {error !== null && (
              <div>
                Seems your phone number has a PIN enabled already. Please enter
                the same PIN. If you have forgotten the current PIN, please
                reset it in the WhatsApp Business Manager and then try this step
                again.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivationPage;
