import React, { useEffect, useState } from "react";
import Spinner from "../../../page loader/Spinner";

const StickerChat = ({ content, downloadSticker, loading, blob }) => {
  const [sticker, setSticker] = useState(null);
  useEffect(() => {
    if (!blob) {
      downloadSticker(false);
    } else {
      const url = URL.createObjectURL(blob);
      setSticker(
        <img
          src={url}
          alt="sticker"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            overflow: "hidden",
            cursor: "pointer",
          }}
        />
      );
    }
    // if(!content.sticker.fsid)
    // {

    // }
    // else
    // {
    //   setSticker( <img alt='sticker' style={{width:"100%",height:"100%",objectFit:"contain",overflow:"hidden",cursor:"pointer"}}/>)
    // }
  }, [blob]);
  return <div className="sticker-container">
    {!loading && sticker}
    {loading && !blob && <Spinner/>}
  </div>;
};

export default StickerChat;
