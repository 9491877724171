// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchMessages,
//   setCurrentChat,
//   updateReadStatus,
//   updateReadTime,
// } from "../../store/feature/chat/chatSlice";

// import Chat from "./Chat";
// import Spinner from "../../page loader/Spinner";
// // import ChatFooter from "./ChatFooter";
// import ChatFooterCopy from "./ChatFooterCopy";
// import { formatDate } from "../../utils/utils";
// import ChatDetails from "./ChatDetails";
// import close from "../../assets/icons/chatheaderclose.svg";
// import search from "../../assets/icons/chatheadersearcxh.svg";
// import detailsimg from "../../assets/icons/chatheadrdetails.svg";
// import detailsopenimg from "../../assets/icons/chatheadrdetailsopen.svg";
// import { updateNotificationCount } from "../../store/feature/notification/notificationSlice";

// const ChatContainer = ({ highlightedMessage, setHighlightedMessage }) => {
//   const chatAreaRef = useRef(null);
//   const prevMessagesLength = useRef(0);
//   const [linkPreview, setLinkPreview] = useState(null);
//   const { currentChat, chatList, fetchMessagesLoading } = useSelector(
//     (state) => state.chat
//   );

//   const chatMessages=useSelector((state) => state.chat.messages);
//   const pendingUpdate = useSelector((state) => state.chat.pendingUpdate);
//   const starredChanges=useSelector(state=>state.chat.starredChanges)
//   const currentUser = useSelector((state) => state.currentuser.currentUser);
//   const currentMessages =chatMessages[currentChat?.id] || [];
//   const [loading, setLoading] = useState(false);
//   const [height, setHeight] = useState({ chatArea: 74, chatFooter: 8 });
//   const dispatch = useDispatch();

//   const [showChatDetails, setShowChatDetails] = useState(true);
//   const [unreadMessages,setUnreadMessages]=useState([])
//   const pushNotificationCount=useSelector(state=>state.notification.count)

//   const user = currentChat?.contact.name;
//   const firstLetter = user.charAt(0).toUpperCase();
//   const groupedMessages = currentMessages.reduce((acc, message) => {
//     const date = formatDate(message.time).split(" ")[0];
//     if (!acc[date]) {
//       acc[date] = [];
//     }
//     acc[date].push(message);
//     return acc;
//   }, {});

//   Object.keys(groupedMessages).forEach((date) => {
//     groupedMessages[date].sort((a, b) => new Date(a.time) - new Date(b.time));
//   });

//   const sortedDates = Object.keys(groupedMessages).sort((a, b) => {
//     const dateA = new Date(a.split("/").reverse().join("-"));
//     const dateB = new Date(b.split("/").reverse().join("-"));
//     return dateA - dateB;
//   });

//   const clearCurrentChat = (e) => {
//     e.preventDefault();
//     dispatch(setCurrentChat(null));
//   };

//   useEffect(() =>
//   {

//     if(currentChat.unread_count!==0 && currentMessages && currentMessages.length!==0 && currentChat.owner_id===currentUser.user_id)
//     {
//       const unread = [];
//       let unreadContainerStarted = false;
//       const messages=currentMessages
//       const sortedMessages = [...messages].sort((a, b) => new Date(a.time) - new Date(b.time));

//       sortedMessages.forEach((chat) => {

//       const isUnread = chat.origin === "incoming" && chat.id === currentChat.last_read_message_id;

//       if (unreadContainerStarted && chat.origin==="incoming")
//       {
//         unread.push(chat);
//       }

//       if (isUnread)
//       {
//         unreadContainerStarted = true;
//       }

//     });

//     if (unread.length > 0) {
//       // Reverse the order if needed
//       setUnreadMessages([...unread].reverse());
//     }
//     }
//   }, [currentMessages, currentChat]);

//   useEffect(()=>
//   {
//     if(unreadMessages.length!==0)
//     {
//       if(pushNotificationCount!==0)
//       {
//         if (pendingUpdate.length !== 0)
//         {
//           dispatch(updateReadTime());
//           dispatch(updateNotificationCount(0))
//         }
//       }

//       const now = new Date();
//       const formattedNow = formatDate(now);

//       let last_read_message_id=null;
//       for (let i = 0; i < unreadMessages.length; i++) {

//         if (unreadMessages[i].origin === "incoming") {
//           last_read_message_id = unreadMessages[i].id;
//           break; // Exit the loop at the first match
//         }
//       }
//      if(last_read_message_id!==null && last_read_message_id!==currentChat.last_read_message_id && currentChat?.owner_id===currentUser.user_id)
//      {
//       dispatch(
//         updateReadStatus({
//           chatId: currentChat.id,
//           read_time: formattedNow,
//           lastReadMessageId: last_read_message_id,
//         }))
//       setUnreadMessages([])
//      }
//     }
//   },[unreadMessages,currentChat])

//   useEffect(() => {
//     if (highlightedMessage) {
//       const timeout = setTimeout(() => {
//         setHighlightedMessage(null);
//       }, 1000); // Remove highlight after 2 seconds
//       return () => clearTimeout(timeout);
//     }
//   }, [highlightedMessage, setHighlightedMessage]);

//   useEffect(() => {
//     if (chatAreaRef.current) {
//       if (
//         currentMessages.length !== prevMessagesLength.current ||
//         (chatAreaRef.current.toDown && currentMessages.length === prevMessagesLength.current)
//         // (currentChat.unread_count===0 || currentMessages.length===prevMessagesLength.current)
//       ) {
//         // Delay the scroll to ensure the DOM is fully rendered
//         setTimeout(() =>
//         {
//           if (chatAreaRef.current) // Ensure element exists
//           {
//             chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
//           }
//         }, 0);

//         prevMessagesLength.current = currentMessages.length;
//       }
//     }
//   }, [currentMessages]);

//   // useEffect(()=>{
//   //   setTimeout(() =>
//   //     {
//   //       if (chatAreaRef.current)
//   //       {
//   //         chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
//   //       }
//   //     }, 0);
//   // },[currentMessages])

//   useEffect(() => {
//     if (currentChat)
//     {
//       if (pendingUpdate.length !== 0)
//       {
//         dispatch(updateReadTime());
//       }
//       dispatch(fetchMessages({chatId:currentChat.id}));
//     }
//   }, [currentChat, dispatch]);

//   const renderMessages = (messages) => {
//     let unreadContainerStarted = false;
//     let messageElements = [];
//     let unreadTracked = false;

//     messages.forEach((chat) => {
//       const isUnread = chat.origin === "incoming" && chat.id === currentChat?.last_read_message_id;

//       if (unreadTracked && chat.origin === "incoming") {
//         if (!unreadContainerStarted) {
//           unreadContainerStarted = true;
//           messageElements.push(
//             <div className="unread-message-container" key={`unread-${chat.id}`}>
//               <div className="unread-message-text-container">
//                 <div className="unread-message-text">Unread Messages</div>
//               </div>
//               <Chat chat={chat} user={user} />
//             </div>
//           );
//         } else {
//           messageElements.push(
//             <div
//               key={chat.id}
//               id={`message-${chat.id}`}
//               className={highlightedMessage === chat.id ? "highlight" : ""}
//             >
//               <Chat chat={chat} user={user} />
//             </div>
//           );
//         }
//       } else {
//         unreadContainerStarted = false;
//         messageElements.push(
//           <div
//             key={chat.id}
//             id={`message-${chat.id}`}
//             className={highlightedMessage === chat.id ? "highlight" : ""}
//           >
//             <Chat chat={chat} user={user} />
//           </div>
//         );
//       }

//       if (isUnread) {
//         unreadTracked = true;
//       }
//     });

//     return messageElements;
//   };

//   const detailspageviewfn = () => {
//     setShowChatDetails(!showChatDetails);
//   };

//   return (
//     <div
//       className="chat-container"
//     >
//       <div className="chat-header">
//         <div className="header-left">
//         <div className="chat-profile">
//             {/* <img
//               src="https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg"
//               alt="chat-profile"
//             /> */}

//             <div className="chat-inside-profile-img-div">
//               <div className="chat-inside-profile-image-letter">
//                 {firstLetter}
//               </div>
//             </div>
//           </div>
//           <span className="chat-user-name">{user}</span>
//         </div>

//         <div className="header-right">
//           <div className="counter-container">
//             <div className="header-right-all-icons">
//               <div className="header-right-search-box" title="Search">
//                 <img src={search} alt="" />
//               </div>
//               <div
//                 className="header-right-details-box"
//                 onClick={detailspageviewfn}
//                 title="Details"
//               >
//                 {showChatDetails ? (
//                   <img src={detailsopenimg} alt="" />
//                 ) : (
//                   <img src={detailsimg} alt="" />
//                 )}
//               </div>
//               <div
//                 className="header-right-close-box"
//                 onClick={(e) => clearCurrentChat(e)}
//                 title="Close"
//               >
//                 <img src={close} alt="" />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <>
//         <div className="parent-div">
//           <div
//             className={
//               showChatDetails ? "chat-total-body" : "chat-total-body-hidden"
//             }
//           >
//             {fetchMessagesLoading ? (
//               <div
//                 className="chat-area"
//                 style={{
//                   display: "grid",
//                   placeItems: "center",
//                   height: "72vh",
//                 }}
//               >
//                 <Spinner />
//               </div>
//             ) : (
//               <div>
//                 <div
//                   className={`chat-area ${
//                     linkPreview !== null || loading ? "link-preview-open" : ""
//                   }`}
//                   style={{ height: `${height.chatArea}vh` }}
//                   ref={chatAreaRef}
//                   // onScroll={handleScroll}
//                 >
//                   {currentMessages.length !== 0 ? (
//                     sortedDates.map((date) => (
//                       <div key={date}>
//                         <div className="date">
//                           <div className="date-span">
//                             <span>{date}</span>
//                           </div>
//                         </div>
//                         {renderMessages(groupedMessages[date])}
//                       </div>
//                     ))
//                   ) : (
//                     <div className="no-message">No messages yet</div>
//                   )}
//                 </div>
//               </div>
//             )}

//             <div>
//               <ChatFooterCopy
//                 currentChat={currentChat}
//                 linkPreview={linkPreview}
//                 setLinkPreview={setLinkPreview}
//                 loading={loading}
//                 setLoading={setLoading}
//                 height={height}
//                 setHeight={setHeight}
//               />
//             </div>
//           </div>
//           {showChatDetails && (
//             <div className="chat-deatils-body">
//               <ChatDetails setHighLightedMessage={setHighlightedMessage} />
//             </div>
//           )}
//         </div>
//       </>
//     </div>
//   );
// };

// export default ChatContainer;

import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMessages,
  setCurrentChat,
  updateReadStatus,
  updateReadTime,
} from "../../store/feature/chat/chatSlice";
import Chat from "./Chat";
import Spinner from "../../page loader/Spinner";
import ChatFooterCopy from "./ChatFooterCopy";
import { formatDate } from "../../utils/utils";
import ChatDetails from "./ChatDetails";
import close from "../../assets/icons/chatheaderclose.svg";
import search from "../../assets/icons/chatheadersearcxh.svg";
import detailsimg from "../../assets/icons/chatheadrdetails.svg";
import detailsopenimg from "../../assets/icons/chatheadrdetailsopen.svg";
import { updateNotificationCount } from "../../store/feature/notification/notificationSlice";

const ChatContainer = ({
  highlightedMessage,
  setHighlightedMessage,
  contactzindex,
}) => {
  const chatAreaRef = useRef(null);
  const prevMessagesLength = useRef(0);
  const [linkPreview, setLinkPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState({ chatArea: 74, chatFooter: 8 });
  const [showChatDetails, setShowChatDetails] = useState(true);
  const [unreadMessages, setUnreadMessages] = useState([]);

  const dispatch = useDispatch();
  const { currentChat, chatList, fetchMessagesLoading } = useSelector(
    (state) => state.chat
  );
  const chatMessages = useSelector((state) => state.chat.messages);
  const pendingUpdate = useSelector((state) => state.chat.pendingUpdate);
  const currentChatUpdated = useSelector(
    (state) => state.chat.currentChatUpdated
  );
  const currentUser = useSelector((state) => state.currentuser.currentUser);
  const pushNotificationCount = useSelector(
    (state) => state.notification.count
  );

  const currentMessages = chatMessages[currentChat?.id] || [];
  const user = currentChat?.contact.name;
  const firstLetter = user?.charAt(0).toUpperCase() || "";

  const groupedMessages = useMemo(() => {
    const groups = currentMessages.reduce((acc, message) => {
      const date = formatDate(message.time).split(" ")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {});

    Object.keys(groups).forEach((date) => {
      groups[date].sort((a, b) => new Date(a.time) - new Date(b.time));
    });

    return groups;
  }, [currentMessages]);

  const sortedDates = useMemo(() => {
    return Object.keys(groupedMessages).sort((a, b) => {
      const dateA = new Date(a.split("/").reverse().join("-"));
      const dateB = new Date(b.split("/").reverse().join("-"));
      return dateA - dateB;
    });
  }, [groupedMessages]);

  const clearCurrentChat = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(setCurrentChat(null));
    },
    [dispatch]
  );

  const detailspageviewfn = useCallback(() => {
    setShowChatDetails((prev) => !prev);
  }, []);

  useEffect(() => {
    if (
      currentChat?.unread_count &&
      currentMessages.length &&
      currentChat?.owner_id === currentUser?.user_id
    ) {
      const unread = [];
      let unreadContainerStarted = false;
      const sortedMessages = [...currentMessages].sort(
        (a, b) => new Date(a.time) - new Date(b.time)
      );

      sortedMessages.forEach((chat) => {
        const isUnread =
          chat.origin === "incoming" &&
          chat.id === currentChat?.last_read_message_id;

        if (unreadContainerStarted && chat.origin === "incoming") {
          unread.push(chat);
        }

        if (isUnread) {
          unreadContainerStarted = true;
        }
      });

      if (unread.length) {
        setUnreadMessages([...unread].reverse());
      }
    }
  }, [currentMessages, currentChat, currentUser]);

  useEffect(() => {
    if (unreadMessages.length) {
      if (pushNotificationCount) {
        if (pendingUpdate.length) {
          dispatch(updateReadTime());
          dispatch(updateNotificationCount(0));
        }
      }

      const now = new Date();
      const formattedNow = formatDate(now);

      let last_read_message_id = null;
      for (let i = 0; i < unreadMessages.length; i++) {
        if (unreadMessages[i].origin === "incoming") {
          last_read_message_id = unreadMessages[i].id;
          break;
        }
      }

      if (
        last_read_message_id &&
        last_read_message_id !== currentChat?.last_read_message_id &&
        currentChat?.owner_id === currentUser?.user_id
      ) {
        dispatch(
          updateReadStatus({
            chatId: currentChat.id,
            read_time: formattedNow,
            lastReadMessageId: last_read_message_id,
          })
        );
        setUnreadMessages([]);
      }
    }
  }, [
    unreadMessages,
    currentChat,
    currentUser,
    pushNotificationCount,
    pendingUpdate,
    dispatch,
  ]);

  useEffect(() => {
    if (highlightedMessage) {
      const timeout = setTimeout(() => {
        setHighlightedMessage(null);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [highlightedMessage, setHighlightedMessage]);

  useEffect(() => {
    if (
      chatAreaRef.current &&
      currentMessages.length !== prevMessagesLength.current
    ) {
      setTimeout(() => {
        if (chatAreaRef.current)
          chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
      }, 0);
      prevMessagesLength.current = currentMessages.length;
    }
  }, [currentMessages, currentChat]);

  useEffect(() => {
    if (currentChat) {
      if (pendingUpdate.length && !currentChatUpdated) {
        dispatch(updateReadTime());
      }

      if (!currentChatUpdated) {
        dispatch(fetchMessages({ chatId: currentChat.id }));
      }
    }
  }, [currentChat, dispatch, pendingUpdate]);

  const renderMessages = useCallback(
    (messages) => {
      let unreadContainerStarted = false;
      let messageElements = [];
      let unreadTracked = false;

      messages.forEach((chat) => {
        const isUnread =
          chat.origin === "incoming" &&
          chat.id === currentChat?.last_read_message_id;

        if (unreadTracked && chat.origin === "incoming") {
          if (!unreadContainerStarted) {
            unreadContainerStarted = true;
            messageElements.push(
              <div
                className="unread-message-container"
                key={`unread-${chat.id}`}
              >
                <div className="unread-message-text-container">
                  <div className="unread-message-text">Unread Messages</div>
                </div>
                <Chat chat={chat} user={user} />
              </div>
            );
          } else {
            messageElements.push(
              <div
                key={chat.id}
                id={`message-${chat.id}`}
                className={highlightedMessage === chat.id ? "highlight" : ""}
              >
                <Chat chat={chat} user={user} />
              </div>
            );
          }
        } else {
          unreadContainerStarted = false;
          messageElements.push(
            <div
              key={chat.id}
              id={`message-${chat.id}`}
              className={highlightedMessage === chat.id ? "highlight" : ""}
            >
              <Chat chat={chat} user={user} />
            </div>
          );
        }

        if (isUnread) {
          unreadTracked = true;
        }
      });

      return messageElements;
    },
    [currentChat, highlightedMessage, user]
  );

  return (
    <div className="chat-container">
      <div className="chat-header">
        <div className="header-left">
          <div className="chat-profile">
            <div className="chat-inside-profile-img-div">
              <div className="chat-inside-profile-image-letter">
                {firstLetter}
              </div>
            </div>
          </div>
          <span className="chat-user-name">{user}</span>
        </div>

        <div className="header-right">
          <div className="counter-container">
            <div className="header-right-all-icons">
              <div className="header-right-search-box" title="Search">
                <img src={search} alt="" />
              </div>
              <div
                className="header-right-details-box"
                onClick={detailspageviewfn}
                title="Details"
              >
                {showChatDetails ? (
                  <img src={detailsopenimg} alt="" />
                ) : (
                  <img src={detailsimg} alt="" />
                )}
              </div>
              <div
                className="header-right-close-box"
                onClick={clearCurrentChat}
                title="Close"
              >
                <img src={close} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="parent-div">
        <div
          className={
            showChatDetails ? "chat-total-body" : "chat-total-body-hidden"
          }
        >
          {fetchMessagesLoading ? (
            <div
              className="chat-area"
              style={{
                display: "grid",
                placeItems: "center",
                height: "72vh",
              }}
            >
              <Spinner />
            </div>
          ) : (
            <div>
              <div
                className={`chat-area ${
                  linkPreview !== null || loading ? "link-preview-open" : ""
                }`}
                style={{ height: `${height.chatArea}vh` }}
                ref={chatAreaRef}
              >
                {currentMessages.length ? (
                  sortedDates.map((date) => (
                    <div key={date}>
                      <div className="date">
                        <div className="date-span">
                          <span>{date}</span>
                        </div>
                      </div>
                      {renderMessages(groupedMessages[date])}
                    </div>
                  ))
                ) : (
                  <div className="no-message">No messages yet</div>
                )}
              </div>
            </div>
          )}

          <div>
            <ChatFooterCopy
              currentChat={currentChat}
              linkPreview={linkPreview}
              setLinkPreview={setLinkPreview}
              loading={loading}
              setLoading={setLoading}
              height={height}
              setHeight={setHeight}
              contactzindex={contactzindex}
            />
          </div>
        </div>
        {showChatDetails && (
          <div className="chat-details-body">
            <ChatDetails setHighLightedMessage={setHighlightedMessage} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatContainer;
