import React, { useEffect, useRef, useState } from 'react';
import './TemplateForm.css';
import back from '../../assets/chat assets/back_arrow.svg';
import {createTemplateRequestBody, extractVariables, formatFileSize, hasDuplicates, isFormValid, isValidVariables, replaceVariables, templateBodyToFormState, validateCopyCode, validateName, validatePhoneNumber} from './templateFormUtils'
import TemplateFormButton from './TemplateFormButton';
import TemplatePreviewButton from './TemplatePreviewButton';
import addImage from '../../assets/templates assets/image_add.svg'
import pdfIcon from '../../assets/chat assets/file_pdf.svg'
import TemplatePreviewThumbnail from './TemplatePreviewThumbnail';
import Preview from '../chats page/Preview';
import { useDispatch, useSelector } from 'react-redux';
import { editTemplate, fetchTemplateMedia, postTemplate } from '../../store/feature/templates/templateSlice';
import Spinner from '../../page loader/Spinner';
import { base64ToBlob } from '../../cache/cacheUtils';


const initialState=
{
    name: '',
    language: 'en',
    category: 'marketing',
    purpose:"individual",
    content: 
    {
        header: 
        {
            format:"text",
            text: "",
            actual_text: "",
            params: []
        },
        body:
        {
            text: "",
            actual_text: "",
            params: []
        },
        footer: 
        {
            text: ""
        },
        buttons:[]
    }
}

const TemplateForm = ({ closeForm,isEdit=false,initialFormState=initialState,id=null ,templateState}) => 
{
    const [newVariable,setNewVariable]=useState(null)
    const [formState, setFormState] = useState(initialFormState || initialState);
    const [file,setFile]=useState(null)
    const [error,setError]=useState({})
    const [preview,setPreview]=useState(false)
    const [currentEvent,setCurrentEvent]=useState(null)
    const prevStateRef = useRef(initialFormState || initialState);
    const fileInputRef=useRef(null)
    const {templateMedia,templateMediaLoading}=useSelector(state=>state.template)
    const prevFileRef=useRef(null)

    const dispatch=useDispatch()

    useEffect(()=>
    {
        if(id && initialFormState.content.header && initialFormState.content.header?.format!=="text")
        {
            dispatch(fetchTemplateMedia({id}))
        }
    },[])

    useEffect(()=>
    {
        if(id && templateMedia[id])
        {
            base64ToBlob(templateMedia[id])
            .then(res=>{
                setFile(res)
                prevFileRef.current=res
            })
            .catch(err=>console.log(err))
        }
    },[templateMedia])

    useEffect(()=>
    {
        if(currentEvent!==null)
        {
            const {name}=currentEvent.target || currentEvent.e.target
            if(newVariable)
            {
                if (["header", "body"].includes(name)) 
                {

                    const inputValue = formState.content[name].text;
                    
                    const startIndex = inputValue.lastIndexOf(newVariable);
                    const endIndex = startIndex+newVariable.length
    
                    if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) 
                    {
                        currentEvent.target.setSelectionRange(startIndex+2, endIndex-2); 
                        setNewVariable(null)
                    }
                }
                else if(["url_example"].includes(name))
                {
                    const inputValue = formState.content.buttons[currentEvent.index].url;
                    const startIndex = inputValue.lastIndexOf(newVariable);
                    const endIndex = startIndex+newVariable.length
    
                    if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) 
                    {
                        currentEvent.e.target.setSelectionRange(startIndex+2, endIndex-2); 
                        setNewVariable(null)
                    }
                }
            }
        }
    },[currentEvent,newVariable])

    const handleChange = (e) => 
    {
        setCurrentEvent(e)
        const { name, value } = e.target;
        setFormState(prevState => 
        {
            prevStateRef.current=prevState
            if (["header", "body", "footer"].includes(name)) 
            {
                if (name === "header") 
                {
                    const {variables,text} = extractVariables(value,"header",setNewVariable);
                    if(variables.length!==0 && !isValidVariables(variables))
                    {
                        setError(prev=>({...prev,headerError:"Variable name must only contain lowercase letters, numbers, and underscores, and must not start with an underscore."}))
                        return prevStateRef.current 
                    }
                    else
                    {
                        setError(prev=>({...prev,headerError:null}))
                        let params =prevState.content.header.params?.length ? [...prevState.content.header.params] : [];

                        if (variables.length > 0) {
                            if (params.length === 0) {
                                // If params is empty, initialize it with the first variable
                                params = [{ name: variables[0], example: ""}];
                            } else {
                                // If params is not empty, update the first param's name and field while preserving the example
                                params[0] = {
                                    ...params[0], 
                                    name: variables[0], 
                                };
                            }
                        } else {
                            // If no variables are present, reset params to an empty array
                            params = [];
                        }

    
                        const actual_text = replaceVariables(name,text, params);

                        if(actual_text.length<=60)
                        {
                            setError(prev=>({...prev,limitExceedError:null}))
                            return {
                                ...prevState,
                                content: {
                                ...prevState.content,
                                header: {
                                    ...prevState.content.header,
                                    text: text,
                                    actual_text,
                                    params:params
                                }
                                }
                            };
                        }
                        else
                        {
                            setError(prev=>({...prev,limitExceedError:{ error:"Header should not be more than 60 characters.",field:"header"}}))
                            return prevStateRef.current
                        }
                    }
                    
                } 
                else if (name === "body") 
                {
                    const {variables,text} = extractVariables(value,"body",setNewVariable);
                    if(variables.length!==0 && (hasDuplicates(variables) || !isValidVariables(variables)) )
                    {
                        if(hasDuplicates(variables))
                        {
                            setError(prev=>({...prev,bodyError:"Two variables cant have same name"}))
                            return prevStateRef.current 
                        }
                        else
                        {
                        
                            setError(prev=>({...prev,bodyError:"Variable name must only contain lowercase letters, numbers, and underscores, and must not start with an underscore."}))
                            return prevStateRef.current 
                            
                        }
                    }
                    else
                    {
                        setError(prev=>({...prev,bodyError:null}))
                        const params = variables.map(varName => 
                        {
                            const existingParam = prevState.content.body.params.find(param => param.name === varName);
                            return existingParam ? existingParam : { name: varName, example: "" };
                        });

                        const actual_text = replaceVariables(name,text, params);
                        if(actual_text.length<=1024)
                        {
                            setError(prev=>({...prev,limitExceedError:null}))
                            return {
                                ...prevState,
                                content: 
                                {
                                    ...prevState.content,
                                    body: 
                                    {
                                        ...prevState.content.body,
                                        text: text,
                                        actual_text,
                                        params
                                    }
                                }
                            };
                        }
                        else
                        {
                            setError(prev=>({...prev,limitExceedError:{ error:"Body should not be more than 1024 characters.",field:"body"}}))
                            return prevStateRef.current
                        }
                        
                    }
                } 
                else 
                {
                    setError(prev => ({ ...prev, footerError:null }));
                    const actual_text=replaceVariables(name,value, [])
                    if(actual_text.length<=60)
                    {
                        setError(prev=>({...prev,limitExceedError:null}))
                        return {
                            ...prevState,
                            content: 
                            {
                                ...prevState.content,
                                footer: 
                                {
                                    text: actual_text
                                }
                            }
                        };
                    }
                    else
                    {
                        setError(prev=>({...prev,limitExceedError:{ error:"Footer should not be more than 60 characters.",field:"footer"}}))
                        return prevStateRef.current
                    }
                }
            } 
            else if(name==="format")
            {
                setFile(null)
                setError(prev=>({...prev,fileUploadError:null}))
                const updated={...prevState.content.header, format:value }
                if(value==="text")
                {
                    updated.text="";
                    updated.params=[];
                }
                return {
                    ...prevState,
                    content:
                    {
                        ...prevState.content,
                        header:updated
                    }
                }
            }
            else 
            {
                if(name==="name" && !validateName(value.trim()))
                {
                    setError(prev=>({...prev,nameError:"Template name can only contain lowercase letters, numbers, and underscores."}))
                }
                else
                {
                    setError(prev=>({...prev,nameError:null}))
                }
                return {
                ...prevState,
                [name]: value.trim(),
                };
            }
        });
    };


    const handleParamChange = (section, index, e) => {
        const { value } = e.target;
        setFormState(prevState => {
            const newState = JSON.parse(JSON.stringify(prevState));
    
            if (section === 'header') {
                setError(prev => ({ ...prev, headerParamError: null }));
                const newParams = [...newState.content.header.params];
                newParams[index].example = value;
                const actual_text = replaceVariables(section, newState.content.header.text, newParams);
    
                if (actual_text.length <= 60) {
                    setError(prev => ({ ...prev, limitExceedError: null }));
                    return {
                        ...newState,
                        content: {
                            ...newState.content,
                            header: {
                                ...newState.content.header,
                                params: newParams,
                                actual_text: actual_text
                            }
                        }
                    };
                } else {
                    setError(prev => ({ ...prev, limitExceedError: { error: "Header should not be more than 60 characters.", field: "header" } }));
                    return prevState; 
                }
            } else {
                setError(prev => ({ ...prev, bodyParamError: null }));
                const newParams = newState.content[section].params ? [...newState.content[section].params] : [];
                newParams[index].example = value;
                const actual_text = replaceVariables(section, newState.content[section].text, newParams);
    
                if (actual_text.length <= 1024) {
                    setError(prev => ({ ...prev, limitExceedError: null }));
                    return {
                        ...newState,
                        content: {
                            ...newState.content,
                            [section]: {
                                ...newState.content[section],
                                params: newParams,
                                actual_text: actual_text
                            }
                        }
                    };
                } else {
                    setError(prev => ({ ...prev, limitExceedError: { error: "Body should not be more than 1024 characters.", field: "body" } }));
                    return prevState; 
                }
            }
        });
    };
    

    const handleNewButtonClick=()=>
    {
        setFormState(prev=>
        {
            return {
                ...prev,
                content:
                {
                    ...prev.content,
                    buttons:
                    [
                        ...prev.content.buttons,
                        {
                            type:"url",
                            url:"",
                            text:"",
                            params:[]
                        }
                    ]
                }
            }
        })
    }

    const handleCreate = () => 
    {
        if (isFormValid(formState,setError,file)) 
        {
            const hasErrors = Object.entries(error).some(([key, value]) => key !== "limitExceedError" && value !== null);
            if(!hasErrors)
            {
                const templateBody=createTemplateRequestBody(formState,file)
                
                if(isEdit)
                {
                    const body=
                    {
                        headerType:templateBody.headerType,
                        reqBody:
                        {
                            content:templateBody.reqBody.content
                        }
                    }

                    // need to check two blobs has same data
                    if(body.headerType!=="text")
                    {
                        if(file?.size===prevFileRef.current.size && file?.type===prevFileRef.current.type)
                        {
                            const prevHeader=templateState.content.header
                            body.reqBody.header={...body.reqBody.header,...prevHeader}
                        }
                        else
                        {
    
                            body.formData=templateBody.formData
                        }
                    }
                    
                    // console.log(JSON.stringify({id:id,template:body},null,2));
                    dispatch(editTemplate({id:id,template:body}))
                    .unwrap()
                    .then(response=>
                    {
                        // alert("Template created successfully.")
                    })
                    .catch(err=>{
                        const message=err.message || "Failed to create template."
                        alert(message)
                    })
                    setFormState(initialState)
                    closeForm(false)
                }
                else
                {
                    // console.log(JSON.stringify(templateBody,null,2));
                    dispatch(postTemplate(templateBody))
                    .unwrap()
                    .then(response=>
                    {
                        // alert("Template Updated successfully.")
                    })
                    .catch(err=>{
                        const message=err.message || "Failed to edit template."
                        alert(message)
                    })
                    setFormState(initialState)
                    closeForm(false)
                }
            }
        }
    
    };
    
    const handleFileUpload = (e) => {
        const selectedFile = e.target.files[0];
        const format = formState.content.header.format;
        let errorMessage = null;
    
        if(selectedFile && format)
        {
            if (format === "image") {
                if ((selectedFile.type === "image/jpeg" || selectedFile.type === "image/png") && selectedFile.size <= 16 * 1024 * 1024) {
                    prevFileRef.current=file
                    setFile(selectedFile);
                } else {
                  errorMessage = "Only .jpeg and .png images less than 16MB are supported";
                }
              } else if (format === "video") {
                if ((selectedFile.type === "video/mp4" || selectedFile.type === "video/3gp") && selectedFile.size <= 16 * 1024 * 1024) {
                    prevFileRef.current=file
                    setFile(selectedFile);
                } else {
                  errorMessage = "Only .mp4 and .3gp videos less than 16MB are supported";
                }
              } else if (format === "pdf") {
                if (selectedFile.type === "application/pdf" && selectedFile.size <= 100 * 1024 * 1024) {
                    prevFileRef.current=file
                    setFile(selectedFile);
                } else {
                  errorMessage = "Only PDF files less than 100MB are supported";
                }
              }
        }
    
        if (errorMessage) {
          setFile(null);
          setError(prev => ({
            ...prev,
            fileUploadError: errorMessage
          }));
        } else {
          setError(prev => ({
            ...prev,
            fileUploadError: null
          }));
        }
      };

    const handleChangeFile = () => {
    fileInputRef.current.click();
    };

    const handleRemoveFile = () => {
    setFile(null);
    fileInputRef.current.value = null;
    };

    const handlePreview=()=>
    {
        setPreview(true)
    }

    const closePreview=()=>
    {
        setPreview(false)
    }
    
    const handleBlur=(e)=>
    {
        const {name}=e.target
        if (['header', 'body', 'footer'].includes(name)) 
        {
            setError((prev) => ({...prev,limitExceedError:null}))
        }
    }

    return (
    <>
    <div className="template-form-container">
        <div className="template-form-header-section">
        <div className='template-header-title-section'>
            <div>
                <img src={back} alt="back" width="18px" height="18px" className='icon' onClick={() => closeForm(false)}/>
            </div>
            <div>
                 <p className="title">{isEdit ? "Edit Template" : "Create New Template"}</p>
            </div>
        </div>

        <div className="button-container">
            <button className="create-button" onClick={handleCreate}>Save</button>
            <button className="close-button" onClick={() => closeForm(false)}>Cancel</button>
        </div>

        </div>
        <div className="main-form-section">
            <div className='form-area'>

            <div className="template-form-group-inline">
            <div style={{display:"flex",flexDirection:"column"}}>
            <div className="form-control-inline">
                <label className="label">Name</label>
                <input
                disabled={isEdit}
                type='text'
                name='name'
                autoComplete="off"
                value={formState.name}
                onChange={handleChange}
                className={`input top${error.nameError ? " error-input" : ""}`}
                />
            </div>
            <div className='error' style={{visibility:error.nameError ? "visible" : "hidden"}}>*{error.nameError}</div>
            </div>

            <div className="form-control-inline">
                <label className="label">Language</label>
                <select
                name='language'
                value={formState.language}
                onChange={handleChange}
                className="input top"
                disabled={isEdit}
                >
                <option value="en">English</option>
                <option value="en_GB">English (UK)</option>
                <option value="en_US">English (US)</option>
                <option value="hi">Hindi</option>
                <option value="ta">Tamil</option>
                </select>
            </div>
            </div>

            <div className="template-form-group">
            <label className="label">Category</label>
            <div className="radio-group">
                <label>
                <input
                    type="radio"
                    disabled={isEdit}
                    name="category"
                    value="marketing"
                    checked={formState.category === 'marketing'}
                    onChange={handleChange}
                />
                Marketing
                </label>
                <label>
                <input
                    type="radio"
                    disabled={isEdit}
                    name="category"
                    value="utility"
                    checked={formState.category === 'utility'}
                    onChange={handleChange}
                />
                Utility
                </label>
                <label>
                <input
                    type="radio"
                    disabled={isEdit}
                    name="category"
                    autoComplete="off"
                    value="authentication"
                    checked={formState.category === 'authentication'}
                    onChange={handleChange}
                />
                Authentication
                </label>
            </div>
            </div>

            {/* <div className='template-form-group'>
            <label className="label">Purpose</label>
                <select
                name='purpose'
                value={formState.purpose}
                onChange={handleChange}
                className="input top"
                disabled={isEdit}
                >
                <option value="broadcast">Broadcast</option>
                <option value="individual">Individual</option>
                </select>
            </div> */}

            <h4 className='content-title'>Content</h4>
            <div className="content-container">
            <div className="form-content">
                <div className='form-header-content form-label-content'>
                    <div className='form-control-inline'>
                        <label>Header</label>

                        {/* <div className="template-form-group">
                            <div className="radio-group">
                                <label >
                                <input
                                    type="radio"
                                    name="format"
                                    value="text"
                                    checked={formState.content.header?.format === 'text'}
                                    onChange={handleChange}
                                />
                                text
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    name="format"
                                    value="image"
                                    checked={formState.content.header?.format === 'image'}
                                    onChange={handleChange}
                                />
                                image
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    name="format"
                                    autoComplete="off"
                                    value="video"
                                    checked={formState.content.header?.format === 'video'}
                                    onChange={handleChange}
                                />
                                video
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    name="format"
                                    autoComplete="off"
                                    value="pdf"
                                    checked={formState.content.header?.format === 'pdf'}
                                    onChange={handleChange}
                                />
                                pdf
                                </label>
                            </div>
                        </div> */}


                       {formState.content.header?.format==="text" && 
                       <>
                       <input
                        type="text"
                        name="header"
                        autoComplete="off"
                        value={formState.content.header?.text}
                        onChange={handleChange}
                        // maxLength={60}
                        onBlur={handleBlur}
                        className={`input ${error?.headerError ? " error-input" : ""}`}
                        />
                        <div className='error' style={{visibility:(error.headerError || (error.limitExceedError?.error && error.limitExceedError?.field==="header")) ? "visible" : "hidden"}}>*{error.headerError || error.limitExceedError?.error}</div>
                        </>}

                        <>
                            {["image", "pdf", "video"].includes(formState.content.header?.format) &&
                                <div className="file-container">
                                <div className='file-upload-container'>
                                    {templateMediaLoading && 
                                        <div>
                                            <Spinner/>
                                            ...Loading Media
                                        </div>
                                    }
                                    {!templateMediaLoading && 
                                    <>
                                        <label htmlFor="fileUpload">
                                            {file ? 
                                            (
                                            <div>
                                            {formState.content.header?.format==="image" && 
                                            <div style={{width:"100%",height:"200px",backgroundColor:"blue",overflow:"hidden"}}>
                                                    <img src={URL.createObjectURL(file)} alt={formState.content.header?.format} style={{width:"100%",height:"100%",objectFit:"cover" }} />
                                                </div>}
                                                {formState.content.header?.format==="video" && 
                                                <div style={{width:"100%",height:"200px",backgroundColor:"blue",overflow:"hidden"}}>
                                                    <TemplatePreviewThumbnail blob={file} playBtn={false} type={formState.content.header?.format}/>
                                                </div>}
                                                {formState.content.header?.format==="pdf" && 
                                                <div style={{width:"100%",height:"200px",backgroundColor:"blue",overflow:"hidden"}}>
                                                    <TemplatePreviewThumbnail blob={file} playBtn={false} type={formState.content.header?.format}/>
                                                </div>}
                                            </div>
                                            ) : 
                                            (
                                                <img src={addImage} alt={"add " + formState.content.header?.format} style={{ cursor: "pointer" }} />
                                            )}
                                        </label>
                                        <input 
                                        id="fileUpload" 
                                        type="file" 
                                        accept={
                                            formState.content.header?.format === "image" ? "image/jpeg, image/png" :
                                            formState.content.header?.format === "pdf" ? "application/pdf" :
                                            formState.content.header?.format === "video" ? "video/mp4, video/3gp" : ""
                                        }
                                        onChange={handleFileUpload}
                                        style={{ display: "none" }}
                                        ref={fileInputRef}
                                        />
                                    </>}
                                </div>
                                {file && 
                                    <div className='file-options'>
                                    <button onClick={handleChangeFile}>Change File</button>
                                    <p onClick={handleRemoveFile} style={{ cursor: "pointer" }}>Remove</p>
                                    </div>}
                                </div>
                            }
                            <div className='error' style={{visibility:error.fileUploadError ? "visible" : "hidden"}}>*{error.fileUploadError}</div>
                        </>
                    </div>
                    {formState.content.header?.params && formState.content.header?.params.length > 0 && (
                    <div>
                        <div className='form-label-content'><p className='example-title'>Examples</p></div>
                    {formState.content.header?.params.map((variable, index) => (
                        <div style={{marginBottom:"10px"}} key={index}>
                            <div className='variable-content' >
                                <label>{variable.name}</label>
                                <input
                                    value={variable.example}
                                    onChange={(e) => handleParamChange('header', index, e)}
                                    className={`${error?.headerParamError ? " error-input" : ""}`}
                                    required
                                />
                            </div>
                            <div className='error' style={{visibility:error.headerParamError ? "visible" : "hidden",marginLeft:"13%",paddingTop:"5px"}}>*{error.headerParamError}</div>
                        </div>
                        ))}
                    </div>
                    )}
                </div>

                <div className='form-body-content form-label-content'>
                <>
                <div className='form-control-inline'>
                    <label>Body</label>
                    <textarea
                    name="body"
                    value={formState.content.body.text}
                    onChange={handleChange}
                    // maxLength={1024}
                    onBlur={handleBlur}
                    className="textarea"
                    />
                    
                </div>
                <div className='error' style={{visibility:(error.bodyError || (error.limitExceedError?.error && error.limitExceedError?.field==="body"))? "visible" : "hidden"}}>*{error.bodyError || error.limitExceedError?.error}</div>
                </>
                {formState.content.body.params && formState.content.body.params.length !== 0 && (
                <>
                    <div className='form-label-content'><p className='example-title'>Examples</p></div>
                    <div className='body-variable-container'>
                        {formState.content.body.params.map((variable, index) => (
                            <div style={{marginBottom:"10px"}} key={index}>
                            <div className='variable-content' >
                                <label>{variable.name}</label>
                                <input
                                    value={variable.example}
                                    onChange={(e) => handleParamChange('body', index, e)}
                                    className={`${(error?.bodyParamError && index===error?.bodyParamError?.missingField) ? "error-input" : ""}`}
                                    required
                                />
                            </div>
                            <div className='error' style={{visibility:(error?.bodyParamError && index===error?.bodyParamError?.missingField) ? "visible" : "hidden",marginLeft:"26%",paddingTop:"5px"}}>*{error?.bodyParamError!==(undefined || null) && error?.bodyParamError?.error}</div>
                        </div>
                        ))}
                    </div>
                </>
                )}
                
                </div>
                <div className='form-footer-content form-label-content'>
                <>
                <div className='form-control-inline'>
                    <label>Footer</label>
                    <input
                    type="text"
                    name="footer"
                    autoComplete="off" 
                    value={formState.content.footer.text}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // maxLength={60}
                    className={`input${error?.footerError ? " error-input" : ""}`}
                    />
                </div>
                <div className='error' style={{visibility:(error.footerError || (error.limitExceedError?.error && error.limitExceedError?.field==="footer")) ? "visible" : "hidden"}}>*{error.footerError || error.limitExceedError?.error}</div>
                </>
                </div>

                
                <div className='form-button-container form-label-content'>
                <div className='form-control-inline'>
                    <label>Buttons</label>
                </div>
                    {(formState.content.buttons && formState.content.buttons.length!==0) && 
                    formState.content.buttons.map((button,index)=><TemplateFormButton key={index} index={index} button={button} setFormState={setFormState} setNewVariable={setNewVariable} setCurrentEvent={setCurrentEvent} prevStateRef={prevStateRef} setError={setError} error={error}/>)}
                    {((formState.content.buttons && formState.content.buttons.length<10) || !formState.content.buttons) && <button onClick={()=>handleNewButtonClick()}>New Button</button>}
                </div>
            </div>
            </div>
            </div>

            <div className='preview-content'>
            <p>Preview</p>
            <div className="template-preview-content">
                {formState.content.header?.format==="text" && <div className='template-preview-header'>{formState.content.header?.actual_text}</div>}
                {["image","video","pdf"].includes(formState.content.header?.format) && file &&
                <>
                    {formState.content.header?.format==="image" && 
                    <div className='preview-media-content' onClick={handlePreview}>
                        <img src={URL.createObjectURL(file)} width="100%" height="100%"/>
                    </div>}

                    {(formState.content.header?.format==="video")&& 
                        <div className='preview-media-content' onClick={handlePreview}>
                            <TemplatePreviewThumbnail playBtn={true} blob={file} type={formState.content.header?.format}/>
                        </div>
                    }

                    {(formState.content.header?.format==="pdf")&& 
                        <div className='preview-media-content' onClick={handlePreview}>
                            <div style={{height:"75%"}}>
                                <TemplatePreviewThumbnail playBtn={true} blob={file} type={formState.content.header?.format}/>
                            </div>
                            <div style={{display:"flex",cursor:"pointer",backgroundColor:"#F0EEFB",height:"25%"}}>
                            <div className='non-text-icon'>
                            <img src={pdfIcon} width="20px" height="24px" alt={file.mime_type} />
                            </div>
                            <div className='content-box'>
                            <div className='content-details'>
                                <p style={{ fontSize: "12px", fontWeight: "400" }}>{file.name || "Untitled"}</p>
                                {file.size && <p style={{ fontSize: "10px", fontWeight: "400", color: "rgba(0,0,0,0.6)" }}>{formatFileSize(file.size)}</p>}
                            </div>
                            
                            </div>
                            </div>
                        </div>
                    }
                </>
                }
                <div className='template-preview-body'>{formState.content.body.actual_text}</div>
                <div className='template-preview-footer'>{formState.content.footer.text}</div>
            </div>
            <div className="template-preview-button-container"> 
                    {formState.content.buttons && formState.content.buttons.length!==0 &&
                    formState.content.buttons.map((button,index)=>
                    {
                       if(button.text!=="")
                       {
                        return <TemplatePreviewButton key={index} button={button}/>
                       }
                    })}
                </div>
            </div>
        </div>
    </div>
    {preview && file && <Preview blob={file} closePreview={closePreview}/>}
    </>
    );
};

export default TemplateForm;