import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import Chats from "./components/chats page/Chats";
import Home from "./components/home page/Home";
import Contacts from "./components/contacts page/Contacts";
import Campaigns from "./components/Campaigns";
import Tasks from "./components/Tasks";
import Analytics from "./components/Analytics";
import { useEffect, useState } from "react";
import PageLoader from "./page loader/PageLoader";
import { useDispatch, useSelector } from "react-redux";
import { userLogin, getAllUsers } from "./store/feature/user/currentUserSlice";
import TemplatesPage from "./components/templates page/TemplatesPage";
import { fetchChannels } from "./store/feature/channels/channelSlice";
import {
  unSubscribeNotification,
  updateNotificationCount,
} from "./store/feature/notification/notificationSlice";
import { createNewMessageFromNotification } from "./utils/utils";
import {
  addNewMessage,
  updateOutgoingStatus,
} from "./store/feature/chat/chatSlice";
import Spinner from "./page loader/Spinner";

function App() 
{
  const dispatch = useDispatch();
  const {currentUser,currentUserLoading} = useSelector((state) => state.currentuser);
  const channelList = useSelector((state) => state.channel.channels);
  const channelLoading = useSelector((state) => state.channel.channelsLoading);
  const [hasChannel, setHasChannel] = useState(false);
  const [channels, setChannels] = useState(channelList || []);
  const [loading, setLoading] = useState(true);
  const [appLoading,setAppLoading]=useState(true)
  const [contactzindex, setContactzindex] = useState({
    headerpdown: false,
    contactdown: false,
  });

  function enableNotification() {
    var notification = window.catalyst.notification;
    notification
      .enableNotification()
      .then(() => {
        console.log("Client registration successful");

        window.catalyst.notification.messageHandler = (msg) => {
          console.log("Received message:", msg);
          handleMessageNotification(msg);
        };
      })
      .catch((error) => {
        console.error("Client registration failed:", error);
      });
  }

  const handleMessageNotification = (msg) => {
    const parsedMsg = JSON.parse(msg.replace(/'/g, ""));
    const msgObj = JSON.parse(parsedMsg);
    const { topic, event, data } = msgObj;
    if (topic === "chats") {
      if (event === "new_messages") {
        const newMessages = createNewMessageFromNotification(data);
        if (data.chats && data.messages.length !== 0) {
          dispatch(
            addNewMessage({ chatId: data.chats.id, messages: newMessages })
          );
          dispatch(updateNotificationCount(newMessages.length));
        } else {
          console.log("empty data or chats");
        }
      } else if (event === "message_status_updates") {
        const updatedStatusMessages = createNewMessageFromNotification(data);
        if (data.chats && data.messages.length !== 0) {
          dispatch(
            updateOutgoingStatus({
              chatId: data.chats.id,
              messages: updatedStatusMessages,
            })
          );
        } else {
          console.log("empty data or chats");
        }
      } else {
        console.log("notified");
      }
    } else {
      console.log("notified");
    }
  };

  useEffect(() => 
  {
    console.log("users check");
    
    enableNotification();
    setLoading(true);
    dispatch(userLogin())
      .unwrap()
      .then(() => {
        setAppLoading(false)
        dispatch(getAllUsers())
        .unwrap()
        .then(()=>{
          setLoading(false);
        })
        .catch(err=>{throw err})
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });

    // Adding event listener for beforeunload event
    const handleBeforeUnload = () => {
      dispatch(unSubscribeNotification({ topic: "chats" }));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      dispatch(unSubscribeNotification({ topic: "chats" }));
    };
  }, [dispatch]);

  useEffect(() => 
  {
    if (currentUser !== null && !currentUserLoading) 
    {
      PageLoader.start()
      dispatch(fetchChannels())
      .unwrap()
      .then(()=>{
        PageLoader.stop()
      })
      .catch(err=>{
        PageLoader.stop()
        console.log(err);
        
      })
    }
    
  }, [currentUser,currentUserLoading, dispatch]);

  useEffect(() => {
    if (channelList && channelList.length !== 0) {
      setHasChannel(true);
      setChannels(channelList);
    }
  }, [channelList]);

  return (
    <div className="App">
      {appLoading && <div className="loading"><Spinner/></div>}
      {((loading && currentUserLoading) || channelLoading) && <PageLoader />}
      {!loading && !currentUserLoading && currentUser?.first_name && (
        <>
          <PageLoader visible={false} />
          <Header
            hasChannel={hasChannel}
            isActive={channels[0]?.status !== "pending" && true}
            setContactzindex={setContactzindex}
          />
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  currentUser={currentUser}
                  hasChannel={hasChannel}
                  channels={channels}
                />
              }
            />
            {hasChannel && channels[0]?.status !== "pending" && (
              <>
                <Route
                  path="/chats"
                  element={
                    <Chats
                      contactzindex={contactzindex}
                      setContactzindex={setContactzindex}
                    />
                  }
                />
                <Route path="/contacts" element={<Contacts />} />
                {/* <Route path="/campaigns" element={<Campaigns />} />
                <Route path="/tasks" element={<Tasks />} />
                <Route path="/analytics" element={<Analytics />} /> */}
                <Route path="/templates" element={<TemplatesPage />} />
              </>
            )}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </>
      )}
    </div>
  );
}

export default App;

