import React from "react";
import Spinner from "../../../page loader/Spinner";

const TemplateChat = ({ content }) => {
  const {
    header = {},
    body = {},
    footer = {},
  } = content?.display_content || {};

  return (
    <>
      {!content.display_content && (
        <div
          style={{
            width: "100%",
            minHeight: "150px",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spinner />
        </div>
      )}
      {content.display_content && (
        <div className="chat-template-container">
          {header.text && (
            <div>
              <p className="chat-template-header-text">{header.text}</p>
            </div>
          )}
          {body.text && (
            <div>
              <p className="chat-template-body-text">{body.text}</p>
            </div>
          )}
          {footer.text && (
            <div>
              <p className="chat-template-footer-text">{footer.text}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TemplateChat;
