import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PageLoader from '../../../page loader/PageLoader';

export const fetchChannels = createAsyncThunk('channels/fetchChannels', () => {
    
    return fetch('/server/setup/business')
        .then(res => res.json())
        .then(response => {
            
            if (response.code === "NOT_FOUND") {
                return fetch('/server/setup/business', { method: "POST" })
                    .then(res => res.json())
                    .then(data => {
                        if (data.code === "NOT_ALLOWED") {
                            throw new Error(data.message);
                        }
                        if(data.channels===null)
                        {
                            data.channels=[]
                        }
                        return { waba_id:data.waba_id || null,channels:data.channels};
                    });
            } else 
            {
                if(!response.channels)
                {
                    response.channels=[]
                }
                return { waba_id:response.waba_id || null,channels:response.channels};
            }
        })
        .catch(error => {
            throw error;
        });
});




export const postChannel = createAsyncThunk('channels/postChannel', (channelBody) => 
{
    PageLoader.start()
    return fetch('/server/setup/channels', 
        { 
            method: "POST",
            headers: 
            { 
                "Content-Type": "application/json" 
            },
            body: JSON.stringify(channelBody)
        })
        .then(res => 
        {
            PageLoader.stop()
            if (!res.ok) 
            {
                throw new Error(res.statusText);
            }
            return res.json();
        })
        .then(response => 
        {
            return response;
        })
        .catch(error => 
        {
            PageLoader.stop()
            throw error;
        });
});

export const updateChannel = createAsyncThunk('channels/updateChannel', ({ id, body, action }) => {
    const requestData = {
        method: "PUT"
    };

    if (body !== null) {
        requestData.headers = {
            "Content-Type": "application/json"
        };
        requestData.body = JSON.stringify(body);
    }

    PageLoader.start()
    return fetch(`/server/setup/channels/${id}?action=${action}`, requestData)
        .then(res => {
            PageLoader.stop()
            if (!res.ok) {
                return res.json().then(errorData => {
                    throw new Error(errorData.message || 'Failed to update channel');
                });
            }
            return res.json();
        })
        .then(() => {
            return { status: action === "activate" ? "active" : "inactive" };
        })
        .catch(error => {
            PageLoader.stop()
            throw error.message || 'An unexpected error occurred';
        });
});
const channelSlice=createSlice({
    name:"channel",
    initialState:
    {
        channels:[],
        waba_id:null,
        channelsLoading:true,
        updateLoading:false,
        error:null
    },
    reducers:{},
    extraReducers:builder=>{
        builder
        .addCase(fetchChannels.pending,(state)=>
        {
            state.channelsLoading=true
        })
        .addCase(fetchChannels.fulfilled,(state,action)=>
        {
            state.channelsLoading=false;
            state.channels=action.payload.channels
            state.waba_id=action.payload.waba_id
        })
        .addCase(fetchChannels.rejected,(state,action)=>
        {
            state.channelsLoading=false;
            state.error=action.error.message
        })
        .addCase(postChannel.fulfilled,(state,action)=>
        {
            state.channels.push(action.payload)
            state.waba_id=action.payload.waba_id
        })
        .addCase(postChannel.rejected,(state,action)=>
        {
            state.error=action.error.message
        })
        .addCase(updateChannel.pending,(state)=>
        {
            state.updateLoading=true
        })
        .addCase(updateChannel.fulfilled, (state, action) => {
            const { id } = action.meta.arg;
            const channels = state.channels;
            state.updateLoading=false
            if (channels.length !== 0) {
                const channel = channels.find(channel => channel.id === id);
                if (channel) {
                    channel.status = action.payload.status;
                }
            }
        })        
        .addCase(updateChannel.rejected,(state,action)=>
        {
            state.updateLoading=false
            state.error=action.error.message
        })
    }
})

export default channelSlice.reducer;