import React, { useState, useRef, useEffect } from "react";
import download from "../../../assets/chat assets/download.svg";
import audioIcon from "../../../assets/chat assets/audio.svg";
import play from "../../../assets/chat assets/play.svg";
import pause from "../../../assets/chat assets/pause.svg";
import WaveSurfer from "wavesurfer.js";
import Spinner from "../../../page loader/Spinner";

const AudioChat = ({ content, downLoadAudio, blob, loading }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [shouldAutoPlay, setShouldAutoPlay] = useState(false);
  const waveContainerRef = useRef(null);
  const waveSurferRef = useRef(null);

  useEffect(() => {
    if (!waveSurferRef.current && blob && waveContainerRef.current) {
      const ws = WaveSurfer.create({
        container: waveContainerRef.current,
        waveColor: "grey",
        progressColor: "#652DC1",
        cursorColor: "transparent",
        height: 40,
        barWidth: 3,
      });
      ws.loadBlob(blob);
      waveSurferRef.current = ws;

      ws.on("finish", () => {
        setIsPlaying(false);
        setShouldAutoPlay(false);
        ws.seekTo(0);
      });

      return () => {
        if (waveSurferRef.current) {
          waveSurferRef.current.destroy();
          waveSurferRef.current = null;
        }
      };
    }
  }, [blob]);

  const playAudio = () => {
    if (waveSurferRef.current) {
      if (isPlaying) {
        waveSurferRef.current.pause();
      } else {
        waveSurferRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleDownload = () => {
    setShouldAutoPlay(true);
    downLoadAudio(true);
  };

  useEffect(() => {
    if (!loading && blob && shouldAutoPlay && waveSurferRef.current) {
      playAudio();
    }
  }, [loading, blob, shouldAutoPlay]);

  return (
    <div className="audio-chat-container">
      <div className="audio-icon-box">
        <img src={audioIcon} alt="Audio Icon" className="audio-icon" />
      </div>

      <div className=" audio-content-box">
        {blob === null && (
          <div className="audio-content-details">
            <p className="audio-content-title">audio.mp3</p>
            {content.audio.size && (
              <p className="audio-content-size">
                {Math.floor(content.audio.size / 1024)}KB
              </p>
            )}
          </div>
        )}

        {!loading && blob === null && (
          <div
            className="audio-content-symbol"
            onClick={() => handleDownload()}
          >
            <img
              src={download}
              alt="Download Icon"
              className="audio-download-icon"
            />
          </div>
        )}

        {!loading && blob !== null && (
          <div className="audio-content-symbol" onClick={() => playAudio()}>
            <img
              src={isPlaying ? pause : play}
              alt="Play/Pause Icon"
              className="audio-play-pause-icon"
            />
          </div>
        )}

        {loading && (
          <div className=" audio-content-symbol">
            <Spinner size="20px" />
          </div>
        )}

        {blob && <div ref={waveContainerRef} style={{ width: "100%" }} />}
      </div>
    </div>
  );
};

export default AudioChat;
