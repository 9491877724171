import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { blobToBase64 } from '../../../cache/cacheUtils';
import PageLoader from '../../../page loader/PageLoader'

export const fetchTemplates=createAsyncThunk('templates/fetchTemplates',(_,{getState})=>
{
    const template=getState().template
    const templates=template.templates
    if(templates && templates.length!==0)
    {
        return Promise.resolve(templates)
    }
    else
    {
        return fetch('/server/templates')
        .then(res=>
        {
            if(!res.ok)
            {
                throw Error(res.statusText)
            }
            return res.json()
        })
        .then(response=>
        {
            return response.templates
        })
        .catch(error=>
        {
            throw error
        })
    }
})

export const postTemplate = createAsyncThunk('templates/postTemplate', (template) => 
{
    if (template.headerType === "text") 
    {
        PageLoader.start()
        return fetch('/server/templates', 
        { 
            method: "POST",
            headers: 
            { 
                "Content-Type": "application/json" 
            },
            body: JSON.stringify(template.reqBody)
        })
        .then(res => 
        {
            PageLoader.stop()
            if (!res.ok) 
            {
                throw new Error(res.statusText);
            }
            return res.json();
        })
        .then(response => 
        {
            return response;
        })
        .catch(error => 
        {
            PageLoader.stop()
            throw error;
        });
    } 
    else 
    {
        PageLoader.start()
        return fetch('/server/templates/media', 
        { 
            method: "POST",
            body: template.formData 
        })
        .then(response => 
        {
            
            if (!response.ok) 
            {
                throw new Error("Error inserting file: " + response.statusText);
            }
            return response.json();
        })
        .then(response => 
        {
            template.reqBody.content.header.media = response;
            return fetch('/server/templates', 
            { 
                method: "POST",
                headers: 
                { 
                    "Content-Type": "application/json" 
                },
                body: JSON.stringify(template.reqBody)
            });
        })
        .then(res => 
        {
            PageLoader.stop()
            if (!res.ok) 
            {
                throw new Error(res.statusText);
            }
            return res.json();
        })
        .then(response => 
        {
            return response;
        })
        .catch(error => 
        {
            PageLoader.stop()
            throw error;
        });
    }
});


export const editTemplate = createAsyncThunk('templates/editTemplate', ({id,template}) => 
{
    if (template.headerType === "text") 
    {
        PageLoader.start()
        return fetch(`/server/templates/${id}`, 
        { 
            method: "PUT",
            headers: 
            { 
                "Content-Type": "application/json" 
            },
            body: JSON.stringify(template.reqBody)
        })
        .then(res => 
        {
            PageLoader.stop()
            if (!res.ok) 
            {
                throw new Error(res.statusText);
            }
            return res.json();
        })
        .then(response => 
        {
            return {id:id,template:template.reqBody.content};
        })
        .catch(error => 
        {
            PageLoader.stop()
            throw error;
        });
    } 
    else 
    {
        PageLoader.start()
        return fetch('/server/templates/media', 
        { 
            method: "POST",
            body: template.formData 
        })
        .then(response => 
        {
            if (!response.ok) 
            {
                throw new Error("Error inserting file: " + response.statusText);
            }
            return response.json();
        })
        .then(response => 
        {
            template.reqBody.content.header.media = response;
            return fetch(`/server/templates/${id}`, 
            { 
                method: "POST",
                headers: 
                { 
                    "Content-Type": "application/json" 
                },
                body: JSON.stringify(template.reqBody)
            });
        })
        .then(res => 
        {
            PageLoader.stop()
            if (!res.ok) 
            {
                throw new Error(res.statusText);
            }
            return res.json();
        })
        .then(response => 
        {
            return {id:id,template:template.reqBody.content};
        })
        .catch(error => 
        {
            PageLoader.stop()
            throw error;
        });
    }
});


export const fetchTemplateMedia=createAsyncThunk('templates/fetchTemplateMedia',({id},{getState})=>
{
    const { template } = getState();
    const base64 = template.templateMedia[id];
    if (base64) 
    {
        return Promise.resolve({ id: id, data: base64 });
    }
    return fetch(`/server/templates/${id}/media`)
        .then(response => 
        {
            if (!response.ok) 
            {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.blob();
        })
        .then(blob => blobToBase64(blob))
        .then(base64 => ({ id: id,data: base64 }))
        .catch(error => 
        {
            throw error;
        });
})

export const publishTemplate=createAsyncThunk('templates/publishTemplate',({id},{getState})=>
{
    PageLoader.start()
    return fetch(`/server/templates/${id}?action=submit`,{ method : "PUT" })
        .then(response => 
        {
            return response.json();
        })
        .then(data=>
        {
            if(data.status==="error")
            {
                throw data
            }
            return fetch(`/server/templates/${id}`)
        })
        .then(response => 
        {
            PageLoader.stop()
            return response.json();
        })
        .then(template=>
        {
            if(template.status==="error")
            {
                throw template
            }
                return {id,template}
        })
        .catch(error => 
        {
            PageLoader.stop()
            throw error;
        });
})


const templateSlice = createSlice({
    name: "template",
    initialState: {
        templates: [],
        templateMedia:{},
        templatesLoading: true,
        createTemplateLoading:false,
        editTemplateLoading:false,
        templateMediaLoading:false,
        publishTemplateLoading:false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTemplates.pending, (state) => {
                state.templatesLoading = true;
            })
            .addCase(fetchTemplates.fulfilled, (state, action) => {
                state.templates = action.payload;
                state.templatesLoading = false;
            })
            .addCase(fetchTemplates.rejected, (state, action) => {
                state.error = action.error.message;
                state.templatesLoading = false;
            })
            .addCase(postTemplate.pending, (state) => {
                state.createTemplateLoading = true;
            })
            .addCase(postTemplate.fulfilled, (state, action) => {
                state.createTemplateLoading = false;
                state.templates.push(action.payload);
            })
            .addCase(postTemplate.rejected, (state, action) => {
                state.createTemplateLoading = false;
                state.error = action.error.message;
            })
            .addCase(editTemplate.pending, (state) => {
                state.editTemplateLoading = true;
            })
            .addCase(editTemplate.fulfilled, (state, action) => {
                state.editTemplateLoading = false;
            
                const { id, template } = action.payload;
                const templateIndex = state.templates.findIndex(item => item.id === id);
                
                if (templateIndex !== -1) {
                    state.templates[templateIndex] = 
                    {
                        ...state.templates[templateIndex],
                        content: template
                    };
                }
            })
            .addCase(editTemplate.rejected, (state, action) => {
                state.editTemplateLoading = false;
                state.error = action.error.message;
            })
            .addCase(fetchTemplateMedia.pending, (state) => {
                state.templateMediaLoading = true;
            })
            .addCase(fetchTemplateMedia.fulfilled, (state, action) => {
                const {id,data}=action.payload
                state.templateMediaLoading = false;
                state.templateMedia[id]=data
            })
            .addCase(fetchTemplateMedia.rejected, (state, action) => {
                state.templateMediaLoading = false;
                state.error = action.error.message;
            })
            .addCase(publishTemplate.pending, (state) => {
                state.publishTemplateLoading = true;
            })
            .addCase(publishTemplate.fulfilled, (state, action) => {
                state.publishTemplateLoading = false;
            
                const { id, template } = action.payload;
                const templateIndex = state.templates.findIndex(item => item.id === id);
                
                if (templateIndex !== -1) {
                    state.templates[templateIndex] = template
                }
            })
            .addCase(publishTemplate.rejected, (state, action) => {
                state.publishTemplateLoading = false;
                state.error = action.error.message.message || action.error.message;
            })
    }
});

export default templateSlice.reducer;