import React from 'react'

const Spinner = ({color,speed,size}) => 
{
    const loaderStyle = {
        border: `4px solid rgba(0, 0, 0, 0.1)`,
        borderTop: `4px solid ${color || '#3498db'}`, 
        borderRadius: '50%',
        width: size || '40px', 
        height: size || '40px',
        animation: `spin ${speed || '1s'} linear infinite`, 
      };
    
      return (
        <div className="loader-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <div className="loader" style={loaderStyle}></div>
        </div>
      );
};
  
export default Spinner;
