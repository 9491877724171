import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPreview } from "../../../store/feature/chat/chatSlice";
import Spinner from "../../../page loader/Spinner";

const LocationChat = ({ chat }) => {
  const [location, setLocation] = useState(null);
  const { address, latitude, longitude, name } = chat.content.location;
  const url = `https://www.google.com/maps/?q=${latitude},${longitude}`;
  const previews = useSelector((state) => state.chat.previews);
  const currentChat = useSelector((state) => state.chat.currentChat);
  const dispatch = useDispatch();

  const fetchLocationPreview = useCallback(async () => {
    try {
      const preview = previews[chat.id];
      if (preview) {
        setLocation(preview.data.image);
      } else {
        const response = await dispatch(
          fetchPreview({ chatId: chat.chat.id, messageId: chat.id, url })
        ).unwrap();
        setLocation(response.data.image);
      }
    } catch (error) {
      setLocation(null);
    }
  }, [dispatch, previews, chat.id, chat.chat.id, url]);

  useEffect(() => {
    if (currentChat?.id === chat.chat.id && !location) {
      fetchLocationPreview();
    }
  }, [currentChat, chat.chat.id, fetchLocationPreview]);

  return (
    <>
      <div className="chat-location-container">
        {location === null && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Spinner />
          </div>
        )}
        {location && (
          <>
            <div className="map-preview">
              <a href={url} target="_blank" rel="noopener noreferrer">
                <img
                  src={location}
                  alt="Location Preview"
                  className="map-preview-img"
                />
              </a>
            </div>
          </>
        )}
      </div>
      {address && (
        <div className="chat-location-caption">
          <p className="chat-location-caption-address"> {address}</p>
          {name && <p className="chat-location-caption-sub-address">{name}</p>}
        </div>
      )}
    </>
  );
};

export default LocationChat;
