import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContacts, deleteContact } from '../../store/feature/contacts/contactSlice';
import Spinner from '../../page loader/Spinner';
import './contactPage.css';
import ContactForm from './ContactForm';
import ContactPopup from './ContactPopup';

import editBtn from '../../assets/contacts assets/edit.svg'
import deleteBtn from '../../assets/contacts assets/delete.svg'
import ContactTableRow from './ContactTableRow';
import { deleteChat } from '../../store/feature/chat/chatSlice';
import LoadingScreen from '../../page loader/LoadingScreen';
import { useNavigate } from 'react-router-dom';

const Contacts = () => 
{
  const navigate=useNavigate()
  const hasChannel = useSelector((state) => state.channel.channels.length > 0);
  const channelStatus = useSelector((state) => state.channel.channels[0]?.status);
  const channelLoading = useSelector((state) => state.channel.channelsLoading);
  
  const { contacts, contactsLoading, createContactLoading, deleteContactLoading, updateContactLoading,enableChatLoading } = useSelector(state => state.contact);
  const [isCreateForm, setIsCreateForm] = useState(false);
  const [editContact, setEditContact] = useState({ isEdit: false, id: null });
  const [popup, setPopup] = useState(null);
  const [deleteContactId, setDeleteContactId] = useState(null); // State for storing the contact to delete
  const dispatch = useDispatch();

  useEffect(() => 
  {
    if(channelLoading)
    {
      return 
    }
    
    if(!channelLoading && hasChannel && channelStatus)
    {
      dispatch(fetchContacts());
    }
    else
    {
      navigate("/")
    }
  }, [dispatch,channelStatus,hasChannel,channelLoading]);

  const handleCreate = () => {
    setIsCreateForm(true);
  };

  const handleEdit = (contact, e) => {
    e.stopPropagation(); // Prevent event bubbling
    setEditContact(prev => ({ ...prev, isEdit: true, id: contact.id }));
  };

  const handleDelete = (contactId, e) => {
    e.stopPropagation(); // Prevent event bubbling
    setDeleteContactId(contactId);
  };

  const confirmDelete = () => {
    dispatch(deleteContact({ id: deleteContactId }))
    .unwrap()
    .then(response=>
    {
      dispatch(deleteChat(deleteContactId))
      setDeleteContactId(null);
    })
    .catch(err=>{
      console.log(err);
      setDeleteContactId(null)
      
    })
    
  };

  const cancelDelete = () => {
    setDeleteContactId(null);
  };

  const reset = () => {
    setIsCreateForm(false);
    setEditContact(prev => ({ ...prev, isEdit: false, id: null }));
    setPopup(null);
  };

  const handlePopup = (id) => {
    setPopup(id);
  };

  const closePopup=()=>
  {
    setPopup(null)
  }

  

  return (
    <>
      {(createContactLoading || updateContactLoading || deleteContactLoading || contactsLoading || enableChatLoading) && <LoadingScreen position={"fixed"}/>}
      {!isCreateForm && !editContact.isEdit && (
        <div className="contacts-page-container">
          <div className="contact-page-header">
          <button
          className={`create-button ${createContactLoading || updateContactLoading || deleteContactLoading || contactsLoading || enableChatLoading ? 'disabled' : ''}`}
          disabled={createContactLoading || updateContactLoading || deleteContactLoading || contactsLoading || enableChatLoading}
          onClick={handleCreate}
        >
          New Contact
        </button>

          </div>

          <div className="contact-content2-box">
            <table className="table" id="table">
              <thead>
                <tr>
                  <th className="table-thead-checkbox">
                    &nbsp;
                  </th>
                  <th className="contact_table_name_row">
                    Name
                  </th>

                  <th>
                    <div className="contact_table_company_name_row">
                      Mobile
                    </div>
                  </th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody id="tbody">
                {contactsLoading &&  <tr>
                    <td colSpan="5" className="centered-spinner">
                      <Spinner />
                    </td>
                  </tr>}
                {!contactsLoading && contacts?.length===0 &&
                  <tr>
                    <td colSpan="5" className="centered-spinner">
                      No Contacts
                    </td>
                  </tr>
                }
                {!contactsLoading && contacts?.map((data) => (
                  <ContactTableRow
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    popupHandle={handlePopup}
                    key={data.id}
                    data={data}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {(isCreateForm || editContact.isEdit) && (
        <ContactForm reset={reset} isEdit={editContact.isEdit} id={editContact.id} />
      )}

      {/* Confirmation Modal */}
      {deleteContactId !== null && (
        <div className="confirmation-modal">
          <div className="modal-content">
            <p>Are you sure you want to delete this contact?</p>
            <div className="modal-actions">
              <button className="confirm-button" onClick={confirmDelete}>
                Yes
              </button>
              <button className="cancel-button" onClick={cancelDelete}>
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {popup !== null && <ContactPopup id={popup} onClose={closePopup} handleDelete={handleDelete} handleEdit={handleEdit}/>}
    </>
  );
};

export default Contacts;
